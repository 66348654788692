import React from 'react';
import { pdfjs } from 'react-pdf';
import PdfViewer from './PdfViewer';

pdfjs.GlobalWorkerOptions.workerSrc = '/worker.js';

const Viewer = ({ zoom, url, docFileId, docId, editortype, docFiles, timerOnDoc }) => {
    return (
        <>
            <PdfViewer
                pdfFile={url}
                zoom={zoom}
                docFileId={docFileId}
                docId={docId}
                editortype={editortype}
                docFiles={docFiles}
                timerOnDoc={timerOnDoc}
            />
        </>
    );
};

export default Viewer;