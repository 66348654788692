import React from "react";
import Breadcrumb from "../../../CommonComponents/BreadCrumb/BreadCrumb";
import UsersListing from "./UsersListing";
import DataPerPage from "../../../CommonComponents/Pagination/DataPerPage";
import SearchComponent from "../../../CommonComponents/SearchComponent/SearchComponent";

const Users = () => {
    return (
        <>
            <Breadcrumb string={'Admin/Users'} />
            <div className="container">
                <div className="show-select1 d-flex mb-2">
                    <div className="mb-2">
                        <DataPerPage />
                        <SearchComponent />
                    </div>
                    <div className="custom-select-container me-2 ms-4 ali">
                        <select id="custom-select" className="select-action">
                            <option value="option1"> Select Action </option>
                            <option value="option2"> Delete Seleted</option>
                            <option value="option3"> Resend Signup Verification</option>
                        </select>
                    </div>
                </div>
                <div className="top-parent">
                    <UsersListing />
                </div>
                <div className="showing-main d-flex justify-content-between mt-3">
                    <div className="showing-para">
                        <p>Showing 1 to 10 of 3,837 entries</p>
                    </div>
                    <div>
                        <button className="previous-btn me-2">Previous</button>
                        <span className="count-btn me-2">1</span>
                        <span className="count-btn me-2">2</span>
                        <span className="count-btn me-2">3</span>
                        <span className="count-btn me-2">4</span>
                        <span className="count-btn me-2">5</span>
                        <span className="count-btn me-2">...</span>
                        <span className="count-btn me-2">384</span>
                        <button className="previous-btn">Next</button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Users;
