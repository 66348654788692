import React from "react"
import { getLanguageCode } from "../../../../../../../hooks/utils"

const DisplayLanguagesSection = ({ languages }) => {
    return (
        <div className="animation-select">
            <p>
                Display this image on the following language editions of this KDF:
            </p>
            <div>
                {
                    languages?.map((lang, index) => {
                        return (
                            <div className="d-flex gap-3 align-items-center" key={index}>
                                <input type="checkbox" className="fs-5" />
                                <img
                                    height='18px'
                                    width='25px'
                                    src={`/countries/${getLanguageCode(lang?.language?.locale)}.png`}
                                    alt={lang?.language?.name} />
                                <p className="fs-6">{lang?.language?.name ?? 'English (US)'}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default DisplayLanguagesSection