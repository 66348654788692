import React, { useState } from "react";
import UsersListing from "../../Pages/Admin/Users/UsersListing";
import ElementModal from "../../Pages/Kdf/EditKdf/elementModal/ElementModal";

const OrgBannedUserModal = ({ openModal, closeModal, companyId, title, type }) => {
  const BannedUserBody = () => {
    return (
      <div className="p-2">
        <UsersListing showCheckbox={false} companyId={companyId} type={type} />
      </div>
    );
  };
  
  return (
    <>
      <ElementModal
        openModal={openModal}
        heading={`User Management ${title}`}
        handleCloseModal={(status) => closeModal()}
        modalWidth={'90vw'}
      >
        <BannedUserBody />
      </ElementModal>
    </>
  );
};
export default OrgBannedUserModal;
