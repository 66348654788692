import React, { useContext, useEffect, useRef, useState } from "react";
import ElementIcons from "./ElementIcons";
import AnimatedElements from "./AnimtedElement";
import ResizableComponent from "./ResizableComponent";
import DraggableElement from "../../../../CommonComponents/Draggable/DraggableElement";
import Element from "./Element";
import { calculateIntersection, getObjectById } from "../../../../../hooks/utils";
import ViewLightBox from "../elementModal/elementSections/lightBoxSection/ViewLightBox";
import ExtendBeyondModal from "../../../../Modals/EditorModals/ExtendBeyondModal";
import { EditorInfoContext } from "../../../../../utis/userInfoContext";

const PdfElement = ({
  canvasRef,
  element,
  zoom,
  dimensions,
  setPageNumber,
  editortype,
  setResizeDimensions,
  resizeDimensions,
  timerOnDoc
}) => {
  const resizableDimensions = {
    top: resizeDimensions[element.id]?.top || dimensions?.top,
    left: resizeDimensions[element.id]?.left || dimensions?.left,
    height: resizeDimensions[element.id]?.height || dimensions?.height,
    width: resizeDimensions[element.id]?.width || dimensions?.width,
  };

  const { editElement } = useContext(EditorInfoContext);

  const previousDimensions = useRef(dimensions);
  const [lgtObj, setLgtObj] = useState(null)
  const [showExtendModal, setShowExtendModal] = useState(false)

  const bounds = {
    top: -resizableDimensions?.top,
    left: -resizableDimensions?.left,
    right:
      canvasRef?.width -
      (resizableDimensions?.left + resizableDimensions?.width),
    bottom:
      canvasRef?.height -
      (resizableDimensions?.top + resizableDimensions?.height),
  };

  const handleClick = (element) => {
    if (element?.value?.page_link) {
      document.getElementById('pageNumber').value = element?.value?.page_link
      setPageNumber(Number(element?.value?.page_link));
    } else if (element?.value?.url) {
      const inputValue = element?.value?.url;
      if (inputValue && inputValue.includes("@")) {
        window.location.href = `mailto:${inputValue}`;
      } else {
        window.open(inputValue, "_blank");
      }
    } else if (element?.value?.light_box) {
      setLgtObj(getObjectById(element?.value?.light_box)?.elements)
    }
  };

  const maskedDimensions = calculateIntersection(canvasRef, resizableDimensions, element?.id);

  useEffect(() => {
    previousDimensions.current = dimensions;
  }, [...Object.values(dimensions)]);

  useEffect(() => {
    if (!Object.keys(editElement || {}).length) {
      setResizeDimensions(dimensions);
    }
  }, [editElement]);

  return (
    <>
      {
        showExtendModal &&
        element?.id == maskedDimensions?.id &&
        element?.geometry?.keep_within_page !== false &&
        resizableDimensions.width !== maskedDimensions?.intersectingWidth &&
        <ExtendBeyondModal
          openModal={showExtendModal}
          closeModal={setShowExtendModal}
          id={element?.id}
          resizableDimensions={resizableDimensions}
          previousDimensions={previousDimensions}
          setResizeDimensions={setResizeDimensions}
        />
      }
      {editortype !== "editor" && <ViewLightBox setShowLightBox={setLgtObj} showLightBox={lgtObj} />}
      <DraggableElement
        position={null}
        dimensions={dimensions}
        bounds={bounds}
        element={element}
        canvasRef={canvasRef}
        resizableDimensions={resizableDimensions}
        setResizeDimensions={setResizeDimensions}
        disabled={editortype !== "editor"}
      >
        <div
          className="handle resizable"
          onClick={() => handleClick(element)}
        >
          <>
            {editortype === "editor" ? (
              <ResizableComponent
                element={element}
                dimensions={dimensions}
                resizableDimensions={resizableDimensions}
                resizeDimensions={resizeDimensions}
                setResizeDimensions={setResizeDimensions}
                keepProportions={element?.geometry?.keep_proportions ?? true}
                setShowExtendModal={setShowExtendModal}
              >
                {editortype === "editor" && (
                  <ElementIcons
                    element={element}
                    resizableDimensions={resizableDimensions}
                    setResizeDimensions={setResizeDimensions}
                    previousDimensions={previousDimensions}
                    canvasRef={canvasRef}
                  />
                )}
                {element?.value?.animation || element?.sec_to_delay !== 0 || element?.disappear_after != 0 ? (
                  <AnimatedElements element={element} timerOnDoc={timerOnDoc}>
                    <Element
                      element={element}
                      dimensions={dimensions}
                      resizeDimensions={resizeDimensions}
                      zoom={zoom}
                      maskedDimensions={maskedDimensions}
                    />
                  </AnimatedElements>
                ) : (
                  <Element
                    element={element}
                    dimensions={dimensions}
                    resizeDimensions={resizeDimensions}
                    zoom={zoom}
                    maskedDimensions={maskedDimensions}
                  />
                )}
              </ResizableComponent>
            ) : (
              <div
                style={{
                  width: `${dimensions?.width}px`,
                  height: `${dimensions?.height}px`,
                  position: "relative",
                }}
              >
                {element?.value?.animation ? (
                  <AnimatedElements element={element} timerOnDoc={timerOnDoc}>
                    <Element
                      element={element}
                      dimensions={dimensions}
                      resizeDimensions={resizeDimensions}
                      zoom={zoom}
                      maskedDimensions={maskedDimensions}
                    />
                  </AnimatedElements>
                ) : (
                  <Element
                    element={element}
                    dimensions={dimensions}
                    resizeDimensions={resizeDimensions}
                    zoom={zoom}
                    maskedDimensions={maskedDimensions}
                  />
                )}
              </div>
            )}
          </>
        </div>
      </DraggableElement>
    </>
  );
};
export default React.memo(PdfElement);
