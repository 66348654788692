import React, { useEffect, useRef, useState } from "react";

export const useAnimate = (element, timerOnDoc) => {
  const [animateClass, setAnimateClass] = useState({
    animationName: element?.value?.animation || "",
    infinite: "infinite",
    opacity: 10,
  });
  const outerTimeout = useRef(null);
  const innerTimeout = useRef(null);

  const handleAnimation = (loop, value) => {
    clearTimeout(outerTimeout.current);
    clearTimeout(innerTimeout.current);
    if (loop === "loop5Seconds") {
      outerTimeout.current = setTimeout(() => {
        setAnimateClass({
          ...animateClass,
          animationName: value?.animation,
          infinite: "",
        });
      }, 5000);
    } else if (loop === "loop10Seconds") {
      outerTimeout.current = setTimeout(() => {
        setAnimateClass({
          ...animateClass,
          animationName: value?.animation,
          infinite: "",
        });
      }, 10000);
    } else if (loop === "loopAnimation") {
      return;
    } else if (loop !== 0 && loop !== undefined) {
      outerTimeout.current = setTimeout(() => {
        setAnimateClass({
          ...animateClass,
          animationName: value?.disappear,
          infinite: "",
        });
        clearTimeout(outerTimeout.current);
      }, Number(loop) * 1000);
      innerTimeout.current = setTimeout(() => {
        setAnimateClass((prevState) => ({
          ...prevState,
          opacity: 0.1,
        }));
        clearTimeout(innerTimeout.current);
      }, Number(loop) * 1000 + 1000);
    }
  };

  useEffect(() => {
    if (element?.value?.animation) {
      if (timerOnDoc) {
        setAnimateClass({
          animationName: "",
          infinite: "",
          opacity: 10,
        })
      } else {
        handleAnimation(
          element?.disappear_after !== 0
            ? Number(element?.disappear_after) + Number(element?.sec_to_delay)
            : element?.value?.frequency,
          element.value
        );
      }
    }
  }, []);

  return { animateClass };
};
