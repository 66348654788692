import React, { useState } from "react";
import ElementModal from "../../Pages/Kdf/EditKdf/elementModal/ElementModal";
import UsersListing from "../../Pages/Admin/Users/UsersListing";
import { Tab, Tabs } from "react-bootstrap";
import AddUserForm from "../../Pages/Admin/Users/AddUserForm";

const OrgUsersModal = ({ openModal, closeModal, companyId, title, type }) => {

  const OrgUserBody = () => {
    const [show, setShow] = useState('list');
    const [selectedUser, setSelectedUser] = useState({})

    const handleSelect = (selectedKey) => {
      setShow(selectedKey);
    };

    const setData = (data) => {
      setSelectedUser(data)
      setShow('form')
    }

    return (
      <>
        <Tabs
          activeKey={show}
          onSelect={handleSelect}
          className="mt-3 select-file-pop"
        >
          <Tab
            eventKey="list"
            title={'Users List'}
          >
            <div className="p-2">
              <UsersListing showCheckbox={false} setData={setData} companyId={companyId} type={type} />
            </div>
          </Tab>
          <Tab
            eventKey="form"
            title={'Manage Users'}
          >
            <AddUserForm setShow={setShow} companyId={companyId} selectedUser={selectedUser} />
          </Tab>
        </Tabs>
      </>
    );
  };

  return (
    <ElementModal
      openModal={openModal}
      heading={`User Management ${title}`}
      handleCloseModal={(status) => closeModal()}
      modalWidth={'90vw'}
    >
      <OrgUserBody />
    </ElementModal>
  );
};
export default OrgUsersModal;
