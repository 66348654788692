import React, { useContext, useEffect, useState } from 'react';
import ImageToolbox from './toolBox/ImageToolbox';
import VideoToolbox from './toolBox/VideoToolbox';
import TextToolbox from './toolBox/TextToolbox';
import EmbedToolbox from './toolBox/EmbedToolbox';
import HotspotToolbox from './toolBox/HotspotToolbox';
import LightboxesToolbox from './toolBox/LightboxesToolbox';
import './EditorToolbox.css';
import { EditorInfoContext } from '../../../../../utis/userInfoContext';
import { networkService } from '../../../../config/axios';
import { csrfToken } from '../../../../../utis/csrfTokenUtils';
import SupportModal from '../../../../Modals/SupportModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faFlag } from '@fortawesome/free-solid-svg-icons';
import ToolBoxFooter from './toolBox/ToolBoxFooter';

const EditorToolbox = ({ doc, pdfFile, otherLanguageAvailable, setOpenCloneModal }) => {
    const [openSupport, setOpenSupport] = useState(false)
    const { setEditEnabled, setToolType, toolType, setOpenLightBox, setShowToast } = useContext(EditorInfoContext)
    const [showRepublish, setShowRepublish] = useState(false)

    const handleToolboxSelectEvent = (status) => {
        if (status !== 'LightboxesToolbox') {
            setEditEnabled(true)
        } else {
            setOpenLightBox(true)
        }
        setToolType(status)
    }

    const handlePublishKdf = async () => {
        const payload = {
            authenticity_token: csrfToken(),
            doc_id: doc?.id,
            docs_file_id: pdfFile?.id
        }
        const publishRes = await networkService(`editor/push-to-live.json`, "POST", payload)
        if (publishRes?.data?.message === 'Success') {
            setShowToast({
                showToast: true,
                toastType: 'success',
                toastMessage: "Kdf Published"
            })
            setShowRepublish(true)
        } else {
            setShowToast({
                showToast: true,
                toastType: 'danger',
                toastMessage: "Something went wrong"
            })
        }
    }
    useEffect(() => {
        setShowRepublish(window.objInit && window.objInit.live_files_count &&
            window.objInit.live_files_count[pdfFile?.language] > 0)
    }, [])

    return (
        <>
            {openSupport && <SupportModal openSupport={openSupport} setOpenSupport={setOpenSupport} />}
            <div className="editorToolbox__popup">
                <div className="editorToolbox__content">
                    {
                        toolType !== 'LightboxesToolbox' && toolType.length > 0 &&
                        <div className='shadow-sm p-3 mb-3 bg-white rounded fs-6'>
                            Drag a rectangle on the page to define the area within which you'd like to display your element.
                        </div>}
                    <div className="editorToolbox__gridContainer">
                        <div className="editorToolbox__gridItem" onClick={() => handleToolboxSelectEvent('ImageToolbox')}>
                            <ImageToolbox />
                        </div>
                        <div className="editorToolbox__gridItem" onClick={() => handleToolboxSelectEvent('VideoToolbox')}>
                            <VideoToolbox />
                        </div>
                        <div className="editorToolbox__gridItem" onClick={() => handleToolboxSelectEvent('TextToolbox')}>
                            <TextToolbox />
                        </div>
                        <div className="editorToolbox__gridItem" onClick={() => handleToolboxSelectEvent('EmbedToolbox')}>
                            <EmbedToolbox />
                        </div>
                        <div className="editorToolbox__gridItem" onClick={() => handleToolboxSelectEvent('HotspotToolbox')}>
                            <HotspotToolbox />
                        </div>
                        <div className="editorToolbox__gridItem" onClick={() => handleToolboxSelectEvent('LightboxesToolbox')}>
                            <LightboxesToolbox />
                        </div>
                    </div>
                    {otherLanguageAvailable ?
                        <div className='editor-flag-pop' onClick={() => {
                            setOpenCloneModal(true)
                        }}>
                            <div className='d-flex flags-arrow'>
                                <FontAwesomeIcon icon={faFlag} />
                                <FontAwesomeIcon icon={faArrowRightLong} />
                                <FontAwesomeIcon icon={faFlag} />
                            </div>
                            <div>
                                <p>Copy elements to other languages</p>
                            </div>

                        </div> : <></>
                    }
                    <div className="tolbox-btns">
                        <div className="btn-grp1 d-flex">
                            <button
                                className="editorToolbox__button editorToolbox__button--secondary me-3"
                                onClick={() => window.open(`/viewer/${doc?.group_id}/${pdfFile?.language}/dev`)}
                            >
                                Preview Changes
                            </button>
                            <button
                                className="editorToolbox__button editorToolbox__button--primary"
                                onClick={() => handlePublishKdf()}
                            >{showRepublish ?
                                'Republish' : 'Publish'}
                            </button>
                        </div>
                        <button
                            className="editorToolbox__button editorToolbox__button--secondary1"
                            onClick={() => window.open(`/viewer/${doc?.group_id}/${pdfFile?.language}`)}
                        >
                            Preview Published Version
                        </button>
                    </div>
                    <div className="d-flex lower-toolbox-btn">
                        <button
                            className="editorToolbox__button editorToolbox__button--tertiary me-2"
                            onClick={() => window.location.href = `/kdfs/${doc?.id}`}
                        >
                            kdf Settings
                        </button>
                        <button
                            className="editorToolbox__button editorToolbox__button--tertiary"
                            onClick={() => setOpenSupport(true)}
                        >
                            Support
                        </button>
                    </div>
                    <ToolBoxFooter doc={doc} />
                </div>
            </div>
        </>
    )
}
export default EditorToolbox;
