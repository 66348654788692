import React, { useEffect, useState } from 'react';
import './GifSections.css'
import apiGETRequest from '../../../../../../../../utis/apiRequest';
import CustomTooltip from '../../../../../../../Elements/Tooltip';

const TenorSection = ({ tool, handleSelectGifs }) => {
    const [tenorData, setTenorData] = useState([])
    const [search, setSearch] = useState("")
    const [loading, setLoading] = useState(false)
    const [gifDimensions, setGifDimensions] = useState({});

    useEffect(() => {
        const fetchGifsTenor = async () => {
            try {
                setLoading(true)
                const gifList = await apiGETRequest(`editor/get-gifs-tenor/${search !== '' ? search : 'excited'}`);
                setTenorData(gifList?.data)
            } catch (error) {
                console.error('Error fetching tenors:', error);
            }
            setLoading(false)
        };
        fetchGifsTenor();
    }, [search])

    useEffect(() => {
        // Fetch the dimensions of each GIF
        const fetchGifDimensions = async () => {
            const dimensions = {};
            const promises = tenorData.map((gif) => {
                return new Promise((resolve) => {
                    const img = new Image();
                    img.onload = () => {
                        dimensions[gif] = { width: img.naturalWidth, height: img.naturalHeight };
                        resolve();
                    };
                    img.onerror = () => resolve(); // Skip on error
                    img.src = gif;
                });
            });

            await Promise.all(promises);
            setGifDimensions(dimensions);
        };

        if (tenorData.length) fetchGifDimensions();
    }, [tenorData]);

    return (
        <div className='GIFs-main'>
            <div className="Gif-inp mb-2">
                <input
                    placeholder="Search GIFs via Tenor"
                    onChange={(e) => setSearch(e?.target?.value)}
                    value={search}
                />
            </div>
            {
                (!tool?.id || tool?.gif_type !== 'tenor' || tool?.id && search.length > 0) && tenorData?.length > 0 ? <div className='gifs-items'>
                    <div className='row flex-wrap gy-3'>
                        {loading ? <div className='p-3'>Loading......</div> : <>
                            {
                                tenorData?.map((gif, index) => {
                                    const dimensions = gifDimensions[gif];
                                    return (
                                        <CustomTooltip
                                            title={
                                                dimensions ? (
                                                    <>
                                                        <span className="d-block" >Image Dimensions:</span>
                                                        <span className="d-block">
                                                            Height: {dimensions.height}px, Width:{" "}
                                                            {dimensions.width}px
                                                        </span>
                                                    </>
                                                ) : (
                                                    "Loading dimensions..."
                                                )
                                            }
                                            key={index}
                                            placement={"top"}
                                            light={true}
                                        >
                                            <div
                                                className='col'
                                                onClick={() => {
                                                    handleSelectGifs('tenor', gif)
                                                    setTenorData([])
                                                }}>
                                                <img style={{ height: "160px", width: "160px" }} src={gif} alt='gif link' />
                                            </div>
                                        </CustomTooltip>
                                    )
                                })
                            }
                        </>
                        }
                    </div>
                </div> : <></>
            }
            {tool?.gif_type && tool?.gif_type === 'tenor' && tool?.value?.gif_link &&
                <img
                    src={tool?.value?.gif_link}
                    alt='gif link'
                    style={{ height: "160px", width: "160px" }}
                />}
        </div>
    );
};

export default TenorSection;