import React, { useEffect, useState } from "react";
import { Col, Container, Row, Dropdown, DropdownButton } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareFromSquare, faCopy, faTrashCan, faGear, faEnvelope, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import CustomTooltip from "../../Elements/Tooltip";
import styles from './linkCard.module.css';
import CustomTags from "../../Elements/CustomTags";
import utilsUploadFormData from "../../../utis/upoadFormData";
const LinkCard = ({data, trigerCopyLaserLinkEvent, triggerSettingsLaserLinkEvent, triggerDeleteLaserLinkEvent, updateLinkData}) => {
    const [kdfsTagsEditStatus, setKdfsTagsEditStatus] = useState(false);
    const [getCopyTextStatus, setCopyTextStatus] = useState(false);

    useEffect(() => {
        // console.log('useEffect LinkCard');
        setCopyTextStatus(false)
    },[])
    const handeAddKDFTagsEvent = async (value) => {
        // utilsUploadFormData(`kdfs/${docs.id}.json`,dataToUpdate, 'PATCH');
        if(value.length > data?.tags.length){
            let updateTagsToDoc = await utilsUploadFormData(`tags.json`, {'name':value[value.length -1]}, 'POST');
            if(updateTagsToDoc?.data != undefined){
                let vaueToUpdate = [...data?.tags?.map(item => item.id),updateTagsToDoc.data.id ];
                console.log('vaueToUpdate ', vaueToUpdate, data?.tags, updateTagsToDoc);
                
                updateLinkData(data,vaueToUpdate);
            }
        }else {
            updateLinkData(data ,data?.tags.filter(item => value.includes(item.name))?.map(item => item.id));
        }
        // updateDocUploadData('refresh');
    }


    const handleCopyTextEvent = async (value) => {
        await navigator.clipboard.writeText(value);
        let timer = setTimeout(() => {
            setCopyTextStatus(false);
            timer = null;
        },3000)
        setCopyTextStatus(true);
    }
    
    return(
        // <Container>
            <Row className={styles.linkWrapper}>
                <Col md={9}>
                    <Row className={styles.linkHeader}>
                        <Col md={10}>
                            <p className={styles.docTitle}>
                                {/* {JSON.stringify(data?.doc?.language_flag?.locale)}  */}
                                <CustomTooltip title={`${data?.template ? 'Template' : 'Individual'} LaserLINK`}>
                                    {data?.template ? 
                                    <FontAwesomeIcon icon={faLayerGroup}  /> 
                                    :
                                    <img  
                                        src={`${assetsBasePath}/${(data?.doc?.language_flag?.locale?.includes('-') ? data?.doc?.language_flag?.locale?.toLowerCase()?.split('-')[1] : data?.doc?.language_flag?.locale)?? 'en'}.png`} 
                                        alt={data?.doc?.language_flag?.name} 
                                        width='22px' 
                                        className="mr-1 cursor-pointer"
                                    /> }
                                </CustomTooltip>
                            <strong>{data?.name}</strong></p>
                        </Col>
                        <Col md={2} className={`d-flex justify-content-between ${styles.actionWrapper}`}>
                            <CustomTooltip title={'Clone this LaserLINK'}>
                                <FontAwesomeIcon icon={faCopy} onClick={() => trigerCopyLaserLinkEvent(data.id)} className="cursor-pointer" />
                            </CustomTooltip>
                            <CustomTooltip title={'Settings'} >
                                <FontAwesomeIcon icon={faGear} className="cursor-pointer"  onClick={() => triggerSettingsLaserLinkEvent(data)}/>
                            </CustomTooltip>
                            <CustomTooltip title={'Remove LaserLINK'} >
                                <FontAwesomeIcon icon={faTrashCan} className="cursor-pointer" onClick={() => triggerDeleteLaserLinkEvent(data.id)} />
                            </CustomTooltip>
                        </Col>
                    </Row>
                    <Row id="link-list-top" className={styles.linkBody}>
                        <Col md={10}>
                            <div className={`${styles.docContent} my-1`}>
                                <CustomTooltip title={'Display all LasetLINK for this KDF'}>
                                    <span className="text-link-success">KDF: </span>
                                </CustomTooltip>
                                <CustomTooltip title={'KDF Settings'}>
                                    <strong className="text-link-success"> {data?.doc?.title}</strong>
                                </CustomTooltip>
                            </div>
                            <div className={`${styles.docContent} my-1`}>
                                <CustomTooltip title={'Engagement Threshold'}>
                                    <span className="">ET: {data?.doc?.page_viewed_sec ?? 0} secs.</span>
                                </CustomTooltip>
                                <CustomTooltip title={'Your chosen domain aims to ehance recipient trust and engagement.'}>
                                    <strong className="text-link-primary"> {data?.doc?.domain}</strong>
                                </CustomTooltip>
                            </div>
                            <div className={`${styles.docContent} my-1`}>
                                <span className="">Created by: </span>
                                <CustomTooltip title={data?.date_created ?? '-'}>
                                    <strong> {data?.username}</strong>
                                </CustomTooltip>
                            </div>
                            <div className="custom-tag-wrapper d-flex" onClick={() => !kdfsTagsEditStatus && setKdfsTagsEditStatus(true)}>
                                Tags: {
                                    kdfsTagsEditStatus ? 
                                    <CustomTags 
                                        updateValue={handeAddKDFTagsEvent}
                                        tags={data?.tags}
                                        handleCancelEvent={(status) => setKdfsTagsEditStatus(!status)}
                                        dataPlaceholder="Add a new tag..."
                                        addOnClass="w-100"
                                    />
                                    :
                                    (
                                        data?.tags?.length > 0 ?
                                        data?.tags?.map((tag, tagInd) => <span key={tagInd} className="tag-primary text-white mx-1 rounded">{tag.name}</span>)
                                        :
                                        <span className="d-block custom-tag-element">Add KDF Tags here</span>
                                    )
                                }
                            </div>
                        </Col>
                        <Col md={2} className="laserlink-img-list">
                            <img src={data?.thumb} alt={data?.name} width="100%" />
                        </Col>
                    </Row>
                </Col>  
                <Col md={2} className="text-center">
                    {!data?.template 
                    ? 
                    <>
                        <a href={`/tl-stats/${data.token}#/views`} className={styles.statsButton}>
                            <strong className="d-block">{data?.stats?.viewers}</strong> 
                            <span>Visits</span>
                        </a>
                        <CustomTooltip title={data?.link_total_sec_viewed}>
                            <a href={`/tl-stats/${data.token}`} className={styles.statsButton}>
                                <strong className="d-block">{data?.stats?.percent_viewed}</strong> 
                                <span>Viewed</span>
                            </a>
                        </CustomTooltip>
                        {data?.last_viewed && <a href={`/tl-stats/${data.token}`} className={styles.statsButton}>
                            <strong className="d-block">{data?.last_viewed}</strong> 
                            <span>Last Viewed</span>
                        </a>}
                    </>
                    : ''
                        }
                </Col>
                <Col md={1}>
                    {
                        !data?.template &&
                    <CustomTooltip title={'Share'}>
                        {/* <FontAwesomeIcon icon={faShareFromSquare} className="active" /> */}
                        <DropdownButton
                            id={`dropdown-button-drop-${'up'}`}
                            drop={'up'}
                            variant="none"
                            title={<FontAwesomeIcon icon={faShareFromSquare} className="active" />}
                            className="shareButtonIcon"
                        >
                            <Dropdown.Item 
                                    href={`https://www.facebook.com/sharer/sharer.php?u=${data?.tracking_link}`}
                                    target="_blank"
                                >
                                {/* <FontAwesomeIcon icon={faFacebookF} />  */}
                                Facebook
                            </Dropdown.Item>
                            <Dropdown.Item 
                                    href={`https://twitter.com/intent/tweet?text=${data?.tracking_link}`}
                                    target="_blank"
                                >
                                {/* <FontAwesomeIcon icon={faTwitter} />  */}
                                Twitter
                            </Dropdown.Item>
                            <Dropdown.Item 
                                    href={`mailto:?subject=Check%20this%20out&body=${data?.tracking_link}`}
                                    target="_blank"
                                >
                                {/* <FontAwesomeIcon icon={faEnvelope} />  */}
                                Email
                            </Dropdown.Item>
                            <Dropdown.Item
                                    href={`https://www.linkedin.com/shareArticle?mini=true&url=https://powered-by.kinetizine.com/MTczMTkwODAwOA/en-US&source=LinkedIn`}
                                    target="_blank"
                                >
                                {/* <FontAwesomeIcon icon={faLinkedin} /> */}
                                 Linkdin
                            </Dropdown.Item>
                            <Dropdown.Item 
                                onClick={ () => 
                                    handleCopyTextEvent(data?.tracking_link ?? '')
                                }
                            >
                                {getCopyTextStatus ? 'Link Copied!' : 'Copy Link'}
                            </Dropdown.Item>
                        </DropdownButton>
                    </CustomTooltip>}
                </Col>
            </Row>
        // </Container>
    );
}
export default LinkCard;