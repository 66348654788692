import React from "react";
import Breadcrumb from "../../../CommonComponents/BreadCrumb/BreadCrumb";
import Button from "../../../Elements/Button";
import CustomTable from "../../../CommonComponents/Table/CustomTable";
const AdminCustomisationLogPage = (props) => {
    const { customizations_log } = props
    const columns = React.useMemo(() => {
        return [
            {
                Header: 'User Name',
                accessor: 'user.name',
            },
            {
                Header: 'Message',
                accessor: 'message',
            },
            {
                Header: 'Approved',
                accessor: 'approved',
                Cell: ({ row }) => (
                    <>
                        {row?.original?.approved ?
                            <Button
                                classList={'success'}
                                text={'Approved'}
                            /> :
                            <Button
                                classList={'alert'}
                                text={'Rejected'}
                            />
                        }
                    </>
                )
            },
        ];
    }, [customizations_log]);

    return (
        <>
            <Breadcrumb string={'Admin/Customization Logs'} />
            <div className="container">
                <CustomTable data={customizations_log} columnData={columns} showFilters={false} />
                {/* <table className="table-main mb-5">
                    <thead>
                        <tr>
                            <th>User Name</th>
                            <th>Message</th>
                            <th>Approved</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            customizations_log?.map((customization, idx) => (
                                <tr key={idx}>
                                    <td>{customization?.user?.name} Ted Box</td>
                                    <td>{customization?.message} I'd like you to accept the use of ALL COOKIES please.</td>
                                    <td>
                                        {customization?.approved ?
                                            <Button
                                                classList={'success'}
                                                text={'Approved'}
                                            /> :
                                            <Button
                                                classList={'alert'}
                                                text={'Rejected'}
                                            />
                                        }
                                    </td>
                                </tr>
                            ))
                        }
                        <tr>
                            <td>Shivam2 Kumar</td>
                            <td>I'd like to replace the default consent pop-up with one or more of the following: our own text, our own link to our cookie policy, and our image.</td>
                            <td>
                                <Button
                                    classList={'success'}
                                    text={'Approved'}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table> */}
            </div>
        </>
    )
}
export default AdminCustomisationLogPage;
