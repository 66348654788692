import React, { useContext, useEffect, useState } from "react";
import CommonModal from "../CommonModal";
import AddPaymentSection from "../../Pages/Admin/Organisation/AddPaymentSection";
import { Tab, Tabs } from "react-bootstrap";
import CustomTable from "../../CommonComponents/Table/CustomTable";
import { networkService } from "../../config/axios";
import DeleteOrgModal from "./DeleteOrgModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import UserInfoContext from "../../../utis/userInfoContext";

const ManagePayment = ({ openModal, closeModal, companyId }) => {
  const AddPaymentBody = () => {
    const { setShowNotifications } = useContext(UserInfoContext)
    const [show, setShow] = useState('list');
    const [paymentList, setPaymentList] = useState([])
    const [openDelete, setOpenDelete] = useState(false)
    const [paymentData, setPaymentData] = useState({})

    const handleSelect = (selectedKey) => {
      setShow(selectedKey);
      setPaymentData({})
    };

    useEffect(() => {
      const fetchDomains = async () => {
        const response = await networkService(`admin/company-payments/${companyId}.json`, "GET");
        setPaymentList([...response.data]);
      };
      fetchDomains();
    }, [show, openDelete]);

    const handleDeletePayment = () => {
      const url = `admin/company_payments/${paymentData?.id}.json`;
      networkService(url, "DELETE")
        .then((response) => {
          if (response.status = 204) {
            setOpenDelete(false)
            setShowNotifications({
              showToast: true,
              toastType: "success",
              toastMessage: "Payment deleted",
            })
          }
        })
        .catch((error) => {
          setShowNotifications({
            showToast: true,
            toastType: 'danger',
            toastMessage: "Something went wrong"
          })
        });
    }

    const columns = React.useMemo(() => {
      return [
        {
          Header: 'Amount',
          accessor: 'amount',
        },
        {
          Header: 'Currency',
          accessor: 'currency',
        },
        {
          Header: 'Start Date',
          accessor: 'start_date',
        },
        {
          Header: 'End Date',
          accessor: 'end_date',
        },
        {
          Header: 'Created By',
          accessor: 'creator',
        },
        {
          Header: 'Modified By',
          accessor: 'modifier',
        },
        {
          Header: 'Actions',
          accessor: 'action',
          Cell: ({ row }) => (
            <>
              <FontAwesomeIcon
                className="me-2 cursor-pointer success"
                icon={faPen}
                onClick={() => {
                  setShow('form');
                  setPaymentData(row?.original)
                }}
              />
              <FontAwesomeIcon
                onClick={() => {
                  setPaymentData(row?.original)
                  setOpenDelete(true);
                }}
                icon={faTrashCan}
                className="cursor-pointer cancel"
              /></>
          ),

        },

      ];
    }, [paymentList]);

    return (
      <>
        {openDelete &&
          <DeleteOrgModal
            openModal={openDelete}
            closeModal={setOpenDelete}
            name={'Payment'}
            handleDelete={handleDeletePayment}
          />
        }
        <Tabs
          activeKey={show}
          onSelect={handleSelect}
          className="mt-3 select-file-pop"
        >
          <Tab
            eventKey="list"
            title={'Payment List'}
          >
            <CustomTable data={paymentList} columnData={columns} showFilters={false} />
          </Tab>
          <Tab
            eventKey="form"
            title={'Manage Payment'}
          >
            <AddPaymentSection setShow={setShow} companyId={companyId} paymentData={paymentData} />
          </Tab>
        </Tabs>
      </>
    );
  };
  return (
    <CommonModal
      title={"Manage Payments"}
      modalStatus={openModal}
      changeActiveStatus={(status) => closeModal()}
      centered
    >
      <AddPaymentBody />
    </CommonModal>
  );
};
export default ManagePayment;
