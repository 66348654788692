import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const ColumnChart = () => {
    const columnOptions = {
        chart: {
            type: 'column',
        },
        title: {
            text: 'Column Chart Example',
        },
        xAxis: {
            categories: ['January', 'February', 'March', 'April', 'May', 'June'],
        },
        yAxis: {
            title: {
                text: 'Value',
            },
        },
        series: [
            {
                name: 'Series 1',
                data: [5, 3, 4, 7, 2, 6],
            },
        ],
    };

    return (
        <>
            <HighchartsReact highcharts={Highcharts} options={columnOptions} />
        </>
    )
}
export default ColumnChart