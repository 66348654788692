import React, { useContext, useState, useEffect } from "react";
import { EditorInfoContext } from "../../../../../utis/userInfoContext";
import { csrfToken } from "../../../../../utis/csrfTokenUtils";
import ImageSection from "../elementModal/elementSections/imageSection/ImageSection";
import VideoSection from "../elementModal/elementSections/videoSection/VideoSection";
import TextEditorSection from "../elementModal/elementSections/textEditorSection/TextEditorSection";
import EmbedSection from "../elementModal/elementSections/embedSection/EmbedSection";
import HotspotSection from "../elementModal/elementSections/hotspotSection/HotspotSection";
import { networkService } from "../../../../config/axios";
import { calculateIntersection } from "../../../../../hooks/utils";

const useEditorHook = (closeModal, docData) => {
    const {
        setEditEnabled,
        geo,
        toolType,
        elements,
        editElement,
        imageElement,
        setEditElement,
        setElements,
        setToolType,
        setImageElement,
        setOpenLightBox,
        openLightBox,
        setShowToast,
        pageDimensions
    } = useContext(EditorInfoContext)

    // initial state for the element mpdal fields
    const [tool, setTool] = useState({
        // properties going to be hadled dynamically
        authenticity_token: csrfToken(),
        doc_id: docData?.doc_id,
        docs_file_id: docData?.id,
        value: editElement?.value ?? {},
        page: editElement?.page ?? geo.page,
        geometry: {
            ...(toolType !== 'LightboxesToolbox' && {
                left: editElement?.geometry?.left ?? geo?.left,
                top: editElement?.geometry?.top ?? geo?.top,
                width: editElement?.geometry?.width ?? geo?.width,
                height: editElement?.geometry?.height ?? geo?.height,
            }),
            keep_proportions: editElement?.geometry?.keep_proportions ?? true,
            keep_within_page: editElement?.geometry?.keep_within_page ?? true,
            hide_beyond_page_edges: editElement?.geometry?.hide_beyond_page_edges ?? false
        },
        sec_to_delay: editElement?.sec_to_delay ?? 0,
        disappear_after: 0,
        priority: editElement?.priority ?? false,
        jump_to_page: '',
        action: editElement?.action ?? 'none',
        disappear_after: editElement?.disappear_after ?? 0,
        ...(editElement?.gif_type && { gif_type: editElement?.gif_type })
    })

    // error state 
    const [errorState, setErrorState] = useState({
        videoErr: '',
        embedErr: '',
        textErr: '',
        imgErr: '',
        tagErr: '',
        dimensionError: ''
    })

    // element modal fields handle change
    const handleChange = (e) => {
        const { name, type, value, checked } = e.target;

        switch (true) {
            case name === 'bg_color': {
                const r = parseInt(value.slice(1, 3), 16);
                const g = parseInt(value.slice(3, 5), 16);
                const b = parseInt(value.slice(5, 7), 16);
                setTool(prevVal => ({
                    ...prevVal,
                    value: {
                        ...prevVal.value,
                        [name]: `rgb(${r}, ${g}, ${b})`
                    },
                }));
                break;
            }
            case name == 'transparency': {
                setTool(prevVal => ({
                    ...prevVal,
                    [name]: Number(value) / 100
                }));
                break;
            }
            case name.startsWith('geometry.'): {
                localStorage.setItem('is_changed_model', 'true');
                const geometryKey = name.split('.')[1];

                if (geometryKey == 'height' && pageDimensions.height < Number(value) + Number(tool?.geometry?.top)
                    || tool?.geometry?.keep_within_page != false && geometryKey == 'width' && pageDimensions.width < Number(value) + Number(tool?.geometry?.left)) {
                    console.log(value, tool?.geometry);
                    setErrorState({
                        ...errorState,
                        dimensionError: 'Dimensions exceed PDF page.'
                    })
                } else if (geometryKey == 'height' && pageDimensions.height > Number(value) + Number(tool?.geometry?.top)
                    || geometryKey == 'width' && pageDimensions.width > Number(value) + Number(tool?.geometry?.left)) {
                    if (errorState.dimensionError !== '') {
                        setErrorState({
                            ...errorState,
                            dimensionError: ''
                        });
                    }
                }
                setTool((prevValues) => ({
                    ...prevValues,
                    geometry: {
                        ...prevValues.geometry,
                        ...((geometryKey === 'keep_within_page' || geometryKey === 'hide_beyond_page_edges') && {
                            keep_within_page: prevValues?.geometry?.keep_within_page ?
                                prevValues?.geometry?.hide_beyond_page_edges :
                                false,
                            hide_beyond_page_edges: prevValues?.geometry?.hide_beyond_page_edges ? prevValues?.geometry?.keep_within_page :
                                false,
                        }),
                        [geometryKey]: type === 'checkbox' ? checked : value !== '' ? Number(value) : value,
                    },
                }));
                break;
            }
            case name === 'animation' || name === 'frequency' || name === 'disappear' || name === "timerField":
                setTool((prevValues) => ({
                    ...prevValues,
                    value: {
                        ...prevValues.value,
                        [name]: value,
                    },
                }));
                break
            case name === 'desiredaction' || name === 'thisElement' || name === 'frequency_animation' || name === "timerField":
                setTool((prevValues) => ({
                    ...prevValues,
                    action: 'desiredaction',
                    dropdown_data: {
                        ...prevValues.dropdown_data,
                        ...(name === 'frequency_animation' ? { frequency: value } : { [name]: value }),
                    },
                }));
                break
            case name === 'jump_to_page' || name === 'page' || name === 'href' || name === 'kdf' || name === 'light_box':
                setTool(prev => ({
                    ...prev,
                    actual_page: geo.page,
                    ...(name !== 'light_box' && { [name]: value }),
                    action: name === 'jump_to_page' ? 'page' : name,
                    value: {
                        ...prev.value,
                        ...(name === 'jump_to_page' ? { page_link: value, url: undefined } :
                            name === 'href' ? { url: value, page_link: undefined } : { [name]: value }),
                        timerField: "ViewPort"
                    },
                }))
            default: {
                setTool((prev) => ({
                    ...prev,
                    [name]: type === 'checkbox' ? checked : value // Update based on type
                }));
                break;
            }
        }
    }

    //handle text editor change 
    const handleTextChange = (data) => {
        setTool((prevValues) => ({
            ...prevValues,
            element_type: 7,
            value: {
                ...prevValues.value,
                html_text: data,
                timerField: "ViewPort"
            },
        }));
        setErrorState({
            ...errorState,
            textErr: ''
        })
    }

    // handle the select the gifs and tenor
    const handleSelectGifs = (type, url) => {
        setErrorState({ ...errorState, imgErr: '' })
        setTool({
            ...tool,
            gif_type: type,
            element_type: '4',
            rotate: 0,
            flip: 1,
            is_tenor: type == 'tenor',
            recurrence: "OncePerVisit",
            value: { ...tool?.value, timerField: "ViewPort", gif_link: url }
        })
    }


    // validation image
    const isImageFile = (file) => {
        if (!file.type.startsWith('image/')) {
            return false;
        }
        return true;
    };

    // image upload handle change function
    const handleImageUpload = (event, action) => {
        setEditEnabled(false)
        if (action === 'cancel') {
            setTool({ ...tool, file: '' })
        } else if (action === 'upload') {
            const file = event.target.files[0];
            setErrorState({ ...errorState, imgErr: '' })
            if (!isImageFile(file)) {
                setErrorState({
                    ...errorState, imgErr: 'Please upload a valid image file.'
                })
                event.target.value = null;
                return;
            }
            const { file_url, file_details, ...toolWithoutFileUrl } = tool;
            setTool({
                ...toolWithoutFileUrl,
                file_cache: '',
                left: toolWithoutFileUrl?.geometry.left,
                top: toolWithoutFileUrl?.geometry?.top,
                proportion: "on",
                width: toolWithoutFileUrl?.geometry?.width,
                height: toolWithoutFileUrl?.geometry?.height,
                transparency: toolWithoutFileUrl?.transparency ?? 1,
                element_type: '4',
                file: file,
                value: { ...toolWithoutFileUrl?.value, timerField: "ViewPort" },
            })
        }
    };

    // video and embed handle change function
    const handleElementUpload = (event, type) => {
        if (event.target?.name !== 'no_scroll') {
            let embedCode = event?.target?.value;
            let source = ""
            let videoLink = ''

            if (embedCode.includes('<iframe')) {
                let srcStartIndex = embedCode.indexOf('src="') + 5;
                let srcEndIndex = embedCode.indexOf('"', srcStartIndex);
                videoLink = embedCode.substring(srcStartIndex, srcEndIndex);
            } else {
                videoLink = event.target.value
            }
            if (-1 !== event?.target?.value?.indexOf('youtube')) {
                source = 'youtube'
            } else if (-1 !== event?.target?.value.indexOf('vimeo')) {
                source = 'vimeo'
            } else if (-1 !== event?.target?.value.indexOf('soundcloud')) {
                source = 'soundcloud'
            }
            setTool({
                ...tool,
                element_type: type === 'embed' ? '6' : type === 'video' ? '5' : '8',
                other_files: [],
                tags: [],
                sec_to_delay: 0,
                disappear_after: 0,
                value: {
                    timerField: "ViewPort",
                    video_link: videoLink,
                    ...(source && { source }),
                    ...(type === 'embed' && { no_scroll: false, no_js: true })
                }
            })
            setErrorState({ ...errorState, videoErr: '', embedErr: '' })
        } else {
            setTool({
                ...tool,
                value: {
                    ...tool?.value,
                    ...(type === 'embed' && { no_scroll: event?.target?.checked, no_js: true })
                }
            })
        }
    }

    // element upload function

    const handleSubmitElement = (e, elemId, elementName) => {
        e.preventDefault();
        uploadElement(elemId, elementName);
    };

    const uploadElement = async (elementId, elementType) => {
        if (handleValidations(tool)) {
            const formData = new FormData();
            let headers = {}
            for (const [key, value] of Object.entries(tool)) {
                formData.append(key, key !== 'file' && typeof value === "object" ? JSON.stringify(value) : value);
            }
            if (!tool?.file) {
                headers['content-type'] = 'application/json'
            }

            // to handle the api url for edit and add element 
            let url = !editElement?.id && !elementId
                ? `/docs/elements${!tool?.file ? '.json' : ''}`
                : `/docs/elements/${editElement?.id ?? elementId}.json`
            try {
                const response = await fetch(url, {
                    method: !editElement?.id && !elementId ? 'POST' : 'PATCH',
                    body: !tool?.file ?
                        JSON.stringify({
                            ...tool,
                            ...(toolType === 'Clone' && { duplicate: true })
                        }) :
                        formData,
                    cookie: {
                        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                    },
                    ...(!tool?.file && { headers })
                });
                if (response?.ok) {
                    setShowToast({
                        showToast: true,
                        toastType: 'success',
                        toastMessage: editElement?.id ? 'Changes Saved' : 'Element Created'
                    })
                    let jsonResponse = await response.json()
                    setElements({ ...elements, [jsonResponse.id]: jsonResponse })
                    if (elementType === 'image') {
                        toolType === 'LightboxesToolbox' && setImageElement({ ...imageElement, [jsonResponse.id]: jsonResponse })
                    } else {
                        openLightBox && setOpenLightBox(false)
                    }
                    handleCloseModal(false)
                }
            } catch (error) {
                setShowToast({
                    showToast: true,
                    toastType: 'danger',
                    toastMessage: 'Something Went Wrong'
                })
                console.error('Fetch error:', error);
            }
        }
    }

    //clear the div / canvas 
    const removeDiv = () => {
        const divToRemove = document.getElementById("createdDivId");
        if (divToRemove) {
            divToRemove.remove(); // Remove the div from the DOM
        }
        if (editElement?.id) {
            const editDiv = document.getElementById(`div${editElement?.id}`)
            if (editDiv) {
                editDiv.style.width = `${editElement?.geometry?.width}px`
                editDiv.style.height = `${editElement?.geometry?.height}px`;
                editDiv.style.transform = `rotate(${editElement?.rotate ?? 0}deg) scaleX(${editElement?.flip ?? 1})`
            }
        }
    };

    // handle element modal close
    const handleCloseModal = (status) => {
        setTool({})
        setEditElement({})
        removeDiv()
        setToolType('')
        closeModal(status)
        setEditEnabled(status)
        localStorage.removeItem('is_changed_model');
        // setOpenLightBox(false)
    }

    // dynamically render element section with heading and component
    const ToolSection = () => {
        let result = {
            header: "",
            component: null,
        };
        switch (true) {
            case toolType === 'ImageToolbox' || editElement?.element_type === 4:
                result.header = toolType === 'Clone' ? 'Clone Element' : Object.values(editElement).length > 0 ? 'Image settings' : "Add Image";
                result.component = (
                    <ImageSection
                        tool={tool}
                        handleImageUpload={handleImageUpload}
                        uploadElement={handleSubmitElement}
                        editElement={editElement}
                        handleSelectGifs={handleSelectGifs}
                        errorState={errorState}
                    />
                );
                break;
            case toolType === 'VideoToolbox' || editElement?.element_type === 5:
                result.header = toolType === 'Clone' ? 'Clone Element' : Object.values(editElement).length > 0 ? 'Video settings' : "Add Video";
                result.component = (
                    <VideoSection
                        handleVideoUrlUpload={handleElementUpload}
                        videoLink={tool?.value?.video_link}
                        errorState={errorState}
                    />
                );
                break;
            case toolType === 'TextToolbox' || editElement?.element_type === 7:
                result.header = toolType === 'Clone' ? 'Clone Element' : Object.values(editElement).length > 0 ? 'Text settings' : "Add Text";
                result.component = (
                    <TextEditorSection
                        handleTextChange={handleTextChange}
                        html_text={tool?.value?.html_text}
                        errorState={errorState}
                    />
                );
                break;
            case toolType === 'EmbedToolbox' || editElement?.element_type === 6:
                result.header = toolType === 'Clone' ? 'Clone Element' : Object.values(editElement).length > 0 ? 'Embed settings' : "Add Embed";
                result.component = (
                    <EmbedSection
                        handleEmbedUrlUpload={handleElementUpload}
                        siteUrl={tool?.value?.video_link}
                        preventScroll={tool?.value?.no_scroll}
                        errorState={errorState}
                    />
                );
                break;
            case toolType === 'HotspotToolbox' || editElement?.element_type === 9:
                result.header = toolType === 'Clone' ? 'Clone Element' : Object.values(editElement).length > 0 ? 'Hotspot settings' : "Add Hotspot";
                result.component = (
                    <HotspotSection
                        tool={tool}
                        setTool={setTool}
                        handleHotspotUpload={handleElementUpload}
                        uploadElement={handleSubmitElement}
                    />
                );
                break;
            default:
                result.header = toolType === 'Clone' ? 'Clone Element' : Object.values(editElement).length > 0 ? 'Image settings' : "Add Image";
                result.component = (
                    <ImageSection
                        tool={tool}
                        handleImageUpload={handleImageUpload}
                        uploadElement={handleSubmitElement}
                        editElement={editElement}
                        handleSelectGifs={handleSelectGifs}
                        errorState={errorState}
                    />
                );
        }
        return result;
    }

    // api call to send the lightbox id when images are added in the slider.
    const editImageInSlider = async (editId, data) => {
        const response = await networkService(`/docs/elements/${editId}.json`, 'PATCH', data)
        if (response?.status === 200) {
            setOpenLightBox(false)
        }
    }

    //handle validations
    const handleValidations = (tool) => {
        if (!tool?.value?.video_link &&
            (toolType === "VideoToolbox" ||
                toolType === "EmbedToolbox" ||
                editElement?.element_type === 6 ||
                editElement?.element_type === 5)
        ) {
            setErrorState({
                ...errorState,
                videoErr: 'Please add the embed code provided by youtube or vimeo. Link must contain http or https.',
                embedErr: 'Please add the URL. Link must contain http or https.'
            })
            return false
        } else if (!tool?.value?.html_text && (toolType === "TextToolbox" ||
            editElement?.element_type === 7)) {
            setErrorState({
                ...errorState,
                textErr: 'Please add the text'
            })
            return false
        } else if (
            (toolType === 'ImageToolbox' || editElement?.element_type === 4) &&
            (!tool?.file && !tool?.file_url && !tool?.value?.gif_link)
        ) {
            setErrorState({
                ...errorState,
                imgErr: 'Please select the Image Element'
            })
            return false
        } else {
            return true
        }
    }

    useEffect(() => {
        if (editElement.id) {
            const { docs_element_tags, ...rest } = editElement;

            const updatedElement = {
                ...rest,
                tags: docs_element_tags || []
            };
            setTool(updatedElement);
        }
    }, [editElement])

    useEffect(() => {
        const divCreated = document.getElementById("createdDivId");
        if (tool?.id) {
            const editDiv = document.getElementById(`div${tool?.id}`)
            if (editDiv) {
                editDiv.style.transform = `rotate(${tool?.rotate ?? 0}deg) scaleX(${tool?.flip ?? 1})`
            }
        } else if (divCreated) {
            const dim = calculateIntersection(pageDimensions, tool?.geometry)
            if (errorState?.dimensionError == '' && tool?.geometry?.keep_within_page != false &&
                tool?.geometry.width !== dim?.intersectingWidth) {
                setErrorState({
                    ...errorState,
                    dimensionError: 'Dimensions exceed PDF page.'
                })
            }
            divCreated.style.width = `${tool?.geometry?.width}px`;
            divCreated.style.height = `${tool?.geometry?.height}px`;
            divCreated.style.top = `${tool?.geometry?.top}px`;
            divCreated.style.left = `${tool?.geometry?.left}px`;
        }
    }, [tool])

    return {
        tool,
        ToolSection,
        handleCloseModal,
        handleSubmitElement,
        uploadElement,
        handleChange,
        toolType,
        editElement,
        handleElementUpload,
        setTool,
        editImageInSlider,
        handleTextChange,
        errorState,
    };
};

export default useEditorHook;
