import React, { useState } from "react";
import { Card, Row, Col, Tabs, Tab } from "react-bootstrap";
import Button from "../../Elements/Button";
import ColumnChart from "./Charts/ColumnChart";
import PieChart from "./Charts/PieChart";
import DonutChart from "./Charts/DonutChart";
const AtAGlance = () => {
    const [activeVisitPageTab, setActiveVisitPageTab] = useState('time');
    return (
        <>
            <Row>
                <Col>
                    <div className="d-flex">
                        <Button
                            className="initial-btn-access"
                            text={"Overview"}
                            classList={`success me-2`}
                        />
                    </div>
                    <Card className="p-3">
                        <Card.Body>
                            <Row>
                                <Col>
                                    <h5>Performance</h5>
                                    <p><strong>1</strong> <br /> Visits</p>
                                    <p><strong>--:01:18</strong> <br /> Total Time</p>
                                    <p><strong>50%</strong> <br /> Viewed</p>
                                    <p><strong>2</strong> <br /> Most Visited Page</p>
                                </Col>
                                <Col>
                                    <h5>Behavioral metrics</h5>
                                    <p><strong>0</strong> <br /> Returning Viewers</p>
                                    <p><strong>0</strong> <br /> Opt-ins</p>
                                    <p><strong>0/0</strong> <br /> Viewed Video 100%</p>
                                    <p><strong>0%</strong> <br /> Score</p>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Tabs
                        defaultActiveKey={"mostVisitedPage"}
                        id="uncontrolled-tab-example"
                        className="mt-3 px-2 select-file-pop"
                    >
                        <Tab
                            eventKey="mostVisitedPage"
                            title={'Most Visited Pages'}
                        >
                            <ColumnChart />
                        </Tab>
                        <Tab
                            eventKey="timePerPage"
                            title={'Time per Page'}
                        >
                            <ColumnChart />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Tabs
                        defaultActiveKey={"browser"}
                        id="uncontrolled-tab-example"
                        className="mt-3 px-2 select-file-pop"
                    >
                        <Tab
                            eventKey="browser"
                            title={'Browser'}
                        >
                            <PieChart />
                        </Tab>
                        <Tab
                            eventKey="os"
                            title={'OS'}
                        >
                            <DonutChart />
                        </Tab>
                        <Tab
                            eventKey="device"
                            title={'Device'}
                        >
                            <PieChart />
                        </Tab>
                    </Tabs>
                </Col>
                <Col>
                    <Tabs
                        defaultActiveKey={"city"}
                        id="uncontrolled-tab-example"
                        className="mt-3 px-2 select-file-pop cursor-pointer"
                    >
                        <Tab
                            eventKey="city"
                            title={'City'}
                        >
                            <PieChart />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </>
    );
}
export default AtAGlance;