import React from "react";
import Breadcrumb from "../../../CommonComponents/BreadCrumb/BreadCrumb";
import Button from "../../../Elements/Button";
import CustomTable from "../../../CommonComponents/Table/CustomTable";
import { UserTypes } from "../../../../hooks/utils";
import DataPerPage from "../../../CommonComponents/Pagination/DataPerPage";
import SearchComponent from "../../../CommonComponents/SearchComponent/SearchComponent";

const AdminDomainManagePage = (props) => {
    const { users } = props
    const columns = React.useMemo(() => {
        return [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'User Type',
                accessor: 'user_type',
                Cell: ({ row }) => (<>
                    {UserTypes[row?.original?.user_type]}
                </>)
            },
            {
                Header: 'Last Login',
                accessor: 'current_sign_in_at',
            },
            {
                Header: 'Company Name',
                accessor: 'company.name',
            },
            {
                Header: 'Default Domain',
                accessor: 'modifier.name',
            },
            {
                Header: 'Domains',
                accessor: 'action',
                Cell: ({ row: { original } }) => (
                    <Button
                        classList={'success'}
                        text={'Domains'}
                        onClick={() => window.location.href = `/admin/get_domains/${original?.id}`}
                    />
                ),

            },

        ];
    }, [users]);

    return (
        <>
            <Breadcrumb string={'Admin/Domain Manager'} />
            <div className="container">
                <div className="show-select1 d-flex mb-2">
                    <div className="mb-2">
                        <DataPerPage />
                        <SearchComponent />
                    </div>
                </div>
                <div className="container">
                    <CustomTable data={users} columnData={columns} />
                </div>
            </div>
        </>
    )
}
export default AdminDomainManagePage;