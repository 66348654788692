import React, { useState, useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import CommonModal from '../../../../../Modals/CommonModal';
import Input from '../../../../../Elements/Input';
import Button from '../../../../../Elements/Button';
import { networkService } from '../../../../../config/axios';
import { utilsGetLocalStorage, utilsUpdateLocalStorage } from '../../../../../../utis/utilsUpdateLocalStorage';
import ToastNotification, { ToasterNotification } from '../../../../../CommonComponents/Toasts/ToastNotification';
const SettingsEnableOPTIN = ({ pageNumber, tlb, sess_id }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [step, setStep] = useState(1); 
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [telephoneNumber, setTelephoneNumber] = useState('');
  const [alertNotification,setAlertNotification] = useState(null);
  
  const [isApiCalled, setIsApiCalled] = useState(() => {
    const saved = utilsGetLocalStorage('receiver')
    return saved === 'true'; 
  });
  
  
  const [emailError, setEmailError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [telephoneNumberError, setTelephoneNumberError] = useState('');
  
  
  useEffect(() => {
    if (isApiCalled) return;
    if (tlb?.has_optin == true && pageNumber === (tlb?.optin_2nd_page_viewed == true ? 2 : -1)) {
      setModalOpen(true);
    }else if(tlb?.tracking_link_option?.show_on_page <= pageNumber){
      // console.log('setPopTimer inside else if before new timeout ', setPopTimer.current);
      // if(!setPopTimer.current){
        // console.log('setPopTimer inside if for new timeout ', setPopTimer.current);
        
        setPopTimer.current = setTimeout(
          () => {
            setModalOpen(true);
            // console.log('setPopTimer inside callback function of timeout ')
          }, (tlb?.tracking_link_option?.show_delay ?? 0) * 1000
        )
      // }
    }else {
      // console.log('setPopTimer inside else ');      
      if(setPopTimer.current){
        // console.log('setPopTimer inside else then if when setPopTimer is present ');        
        // console.log('tlb?.tracking_link_option?.show_on_page > pageNumber ', tlb?.tracking_link_option?.show_on_page > pageNumber, ' show_on_page ', tlb?.tracking_link_option?.show_on_page, ' pageNumber ', pageNumber);
        clearTimeout(setPopTimer.current);
        setPopTimer.current = null;
      }
    }
    return () => {
      if (setPopTimer.current) {
        clearTimeout(setPopTimer.current);
        setPopTimer.current = null;
      }
    };
  }, [pageNumber, tlb?.has_optin, isApiCalled]);

  const setPopTimer = useRef(null);

  

  const handleNext = async () => {
    let valid = true;    
    setEmailError('');
    setFirstNameError('');
    setLastNameError('');
    setTelephoneNumberError('')

    
    if (step === 1) {
      if (!email) {
        setEmailError('Email is required');
        valid = false;
      }
    } else if (step === 2) {
      if(tlb?.opt_in?.ask_name){
        if (!firstName) {
          setFirstNameError('First Name is required');
          valid = false;
        }
        if (!lastName) {
          setLastNameError('Last Name is required');
          valid = false;
        }
      }
      if(tlb?.opt_in?.ask_telephone_number){
        if(!telephoneNumber){
          setTelephoneNumberError('Please Provide a valid phone number');
          valid = false;
        }
      }
    }

    if (!valid) {
      return; 
    }
    try{
        if (step === 1) {
          setStep(2);
          let {data, status} = await networkService('check_receiver/demo', 'POST', { company_id: null, 'email': email });
        } else if (step === 2) {
          let {data, status} = await networkService('receivers/data/add.json', 'POST', { 'email': email, 'first_name': firstName, 'last_name': lastName, 'session_id': sess_id, 'tracking_link_id': tlb?.opt_in?.tracking_link_id, 'telephone_number': telephoneNumber });
            setIsApiCalled(true);
            // sessionStorage.setItem('isApiCalled', 'true');
            utilsUpdateLocalStorage('receiver', true);
            setAlertNotification({message: (firstName != '' ? `Thanks ${firstName}, we emailed you a link to this doc for future access. We also unlocked the doc so that you can continue viewing now.` : `Thanks for confirming ${email}`), variant: 'success'});
            setModalOpen(false); 
        }

    }catch(error){
      setAlertNotification({message: 'Email has already been taken', variant: 'danger'});
    }
  };

  return (
    <>
      {alertNotification != null && 
        <ToasterNotification 
          message={alertNotification?.message}
          variant={alertNotification?.variant}
        />
      }
      <CommonModal
        title="Your permission is required to continue"
        modalStatus={isModalOpen}
        changeActiveStatus={() => {}}
        centered
        classList="hideCross optSettingsModal"
        
        footer={
          <div className={`d-flex ${step === 1 ? ((tlb?.opt_in?.ask_name || tlb?.opt_in?.ask_telephone_number) ? 'text-end' : (tlb?.opt_in?.custom_btn_text ?? 'text-start')) : (tlb?.opt_in?.custom_btn_text ?? 'text-start')}`}>
            <Button
              text={step === 1 ? ((tlb?.opt_in?.ask_name || tlb?.opt_in?.ask_telephone_number) ? 'Next' : (tlb?.opt_in?.custom_btn_text ?? 'OK')) : (tlb?.opt_in?.custom_btn_text ?? 'OK')}
              classList="success"
              addonClass="my-2 mx-2"
              onClick={handleNext}
            />
          </div>
        }
      >
        <div className="">
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: tlb?.opt_in?.custom_text ?? 'To continue reading, please provide your:'
              }}
            />
          </div>
          {step === 1 && (
            <>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
              {emailError && <div className="error-message">{emailError}</div>}
            </>
          )}

          {((tlb?.opt_in?.ask_name || tlb?.opt_in?.ask_telephone_number) && step === 2) && (
            <>
              {
                tlb?.opt_in?.ask_name &&
                <div className='form-group d-flex'>
                  <Input
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="First Name"
                    required
                  />
                  {firstNameError && <div className="error-message">{firstNameError}</div>}

                  <Input
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Last Name"
                    required
                  />
                  {lastNameError && <div className="error-message">{lastNameError}</div>}
                </div>
              }
              {
                tlb?.opt_in?.ask_telephone_number &&
                <>
                  <Input
                    type={'number'}
                    classList={'full-width'}
                    value={telephoneNumber}
                    onChange={(e) => setTelephoneNumber(e.target.value)}
                    placeholder="Tel nr."
                    required
                  />
                  {telephoneNumberError && <div className="error-message">{telephoneNumberError}</div>}
                </>
              }
            </>
          )}

          <div className="permission-paragraph mt-2 mb-2">
            <p>
              You consent to us processing your personal data. Unsubscribe anytime. See our{" "}
              <span className="privacy">
                <a href="/privacy-policy">Privacy Notice</a>
              </span>
            </p>
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default SettingsEnableOPTIN;
