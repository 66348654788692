import React, { useState } from "react";
import Input from "../../Elements/Input";
import Button from "../../Elements/Button";
import { Col, Container, Row } from "react-bootstrap";
import { csrfToken } from "../../../utis/csrfTokenUtils";
import { networkService } from "../../config/axios";

const CreateNewKdf = ({ props }) => {
  const [formDocTitle, setFormDocTitle] = useState("");
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = { doc: {} };
    for (const [key, value] of formData.entries()) {
      data.doc[key] = value;
    }
    data.authenticity_token = csrfToken();
    const response = await networkService("/kdfs.json", "POST", data);
    if (response.status === 201) {
      window.location.href = `/kdfs/${response.data.id}`;
    } else {
      setErrors({ ...response.data });
    }
  };

  return (
    <>
      <Container>
        <Row>
          <Col md={3}>
            <form className="new-kdf" onSubmit={handleSubmit}>
              <input type="hidden" name="user_id" value={props?.user_id} />
              <Input
                classList=""
                type="text"
                placeholder="Title..."
                name="title"
                required={true}
                label="Enter a title for your new KDF:"
                onChange={(e) => setFormDocTitle(e.target.value)}
                value={formDocTitle}
              />
              {Object.keys(errors || {}).map((key) => {
                const message = errors[key][0];
                return (
                    <p className="back-alert p-2 my-3 text-white">{message}</p>
                );
              })}
              <div className="text-end">
                <Button
                  classList={"alert"}
                  addonClass="d-inline-flex mx-2"
                  text={"Cancel"}
                />
                <Button
                  classList={formDocTitle == "" ? "disabled" : "success"}
                  disabled={formDocTitle == ""}
                  addonClass="d-inline-flex mx-2"
                  text={`Continue`}
                  type="submit"
                />
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateNewKdf;
