import React, { useContext } from "react"
import { Carousel } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { EditorInfoContext } from "../../../../../../../utis/userInfoContext";

const ImageSlider = ({ arrayOfImageElements, showIcons }) => {
    const { setEditElement, setOpenRemoveModal, setOpenAddElement, setToolType } = useContext(EditorInfoContext)
    return (
        <div className="custom-carousel">
            <Carousel
                nextIcon={<span className="carousel-control-next-icon custom-icon" />}
                prevIcon={<span className="carousel-control-prev-icon custom-icon" />}
            >
                {arrayOfImageElements?.map((image, index) => (
                    image?.element_type_name === 'Image' && (image?.file_url !== undefined || image?.value?.gif_link !== undefined) &&
                    <Carousel.Item key={index} className="carousel-item-container">
                        {showIcons ? <div className="image-label">{index + 1}</div> : <></>}
                        <img className="d-block"
                            src={image?.file_url ?? image?.value?.gif_link}
                            alt={`Slide ${index + 1}`}
                            style={{
                                width: 200,
                                height: 200,
                                objectFit: 'cover'
                            }}
                        />
                        {showIcons ? <div className="slider-action-icons">
                            <FontAwesomeIcon
                                className="me-2 cancel cursor-pointer fs-5"
                                icon={faTrashCan}
                                onClick={(e) => {
                                    setEditElement(image);
                                    setOpenRemoveModal(true);
                                }}
                            />
                            <FontAwesomeIcon
                                className="success-green fs-5 cursor-pointer"
                                icon={faPencil}
                                onClick={(e) => {
                                    setToolType('LightboxesToolbox')
                                    setEditElement(image);
                                    setOpenAddElement(true);
                                }}
                            />
                        </div> : <></>
                        }
                    </Carousel.Item>
                ))}
            </Carousel>
            {showIcons ? <div className="total-images">Total images: {arrayOfImageElements?.length}</div> : <></>}

        </div>
    )
}
export default ImageSlider