import React, { useEffect, useState } from "react";
import StatsFilters from "./StatsFilters";
import { networkService } from "../../config/axios";
import { faClipboard, faPen, faTrash, faUserXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomTable from "../../CommonComponents/Table/CustomTable";
import StatsTableView from "./StatsTableView";
import CustomTooltip from "../../Elements/Tooltip";
import CommonModal from "../../Modals/CommonModal";
import UpdateRecipientModal from "./UpdateRecipientModal";
import LoadingScreen from "../../Elements/LoadingScreen/LoadingScreen";

const sessionsDummyData = [
    {
        "cnt": 5,
        "id": 25515,
        "ip": "223.178.210.162",
        "continent": "Asia",
        "country": "India",
        "city": "Chandigarh",
        "subdivision": "Chandigarh",
        "used_password": null,
        "browser": "Chrome",
        "terms_time": null,
        "policy_time": null,
        "os": "Linux",
        "os_family": "linux",
        "device": "Desktop",
        "tracking_stats_link": "/tl-stats/MTczMzMxMDY0OA#/session/25515,session",
        "tracking_link_password": null,
        "tracking_link_tooltip": "fggtfh",
        "tracking_link": {
            "id": 3208,
            "name": "fggtfh",
            "token": "MTczMzMxMDY0OA",
            "user_id": 4310,
            "modifier_id": 6244,
            "company_id": 108,
            "doc_id": 25052,
            "campaign_id": null,
            "docs_files_id": 25094,
            "template": false,
            "language": "de",
            "landing_page": 1,
            "zoom_lvl": "page-height",
            "show_toolbar": true,
            "send_engagement_notif": true,
            "notify_after": 22,
            "notify_for_page": null,
            "notify_page_after": null,
            "privacy_setting": "do_not_show",
            "expiry": null,
            "expire_time": null,
            "expire_time_zone": "Kolkata",
            "has_optin": true,
            "optin_2nd_page_viewed": false,
            "password": null,
            "created_at": "2024-12-04T16:40:48.000+05:30",
            "updated_at": "2024-12-09T13:13:31.000+05:30",
            "notify_send": null,
            "kdf_action_link": false,
            "short_link_123": null,
            "privacy_policy_consent": true,
            "disable_scroll": true,
            "disable_settings": {
                "disable_on": "seconds",
                "disable_for": 3,
                "disable_message": "This document will be scrollable in"
            },
            "show_thumbnail": false,
            "exit_pop": false,
            "exit_pop_settings": {},
            "enter_pop": false,
            "enter_pop_settings": {},
            "flash_pop": false,
            "flash_pop_settings": {},
            "default_enter_pop": false,
            "default_flash_pop": false,
            "default_exit_pop": false,
            "enable_download": false,
            "domain": "powered-by.kinetizine.com",
            "seo_description": "I'm sharing this with you because...",
            "enable_transparent_bg": true,
            "enable_shadow_box": true
        },
        "date": "2024-12-10 @ 10:45:34 IST",
        "is_pp_concent_accept": false,
        "pp_accept_date": "-",
        "heading_date": "2024-12-10 @ 10:45:52 IST",
        "date_wrt_time_zone": "2024-12-10 10:45 IST",
        "session_pages_spend_time": "5 secs on page 2</br>3 secs on page 1</br>2 secs on page 3</br>1 sec on page 4</br>",
        "number_of_viewed_pages": "4 pages",
        "ses_landing_page": 1,
        "browser_info": {
            "browser": "Chrome 127.0.0.0",
            "browser_version": "127.0.0.0",
            "browser_icon": "chrome.png",
            "os_icon": "linux.png",
            "os_family": "Linux",
            "device": "Desktop",
            "device_icon": "desktop.png",
            "device_brand": "",
            "device_brand_icon": ""
        },
        "ip_details": {
            "continent": "Asia",
            "continent_code": "AS",
            "country": "India",
            "country_code": "IN",
            "city": "Chandigarh",
            "subdivision": "Chandigarh",
            "subdivision_code": "CH",
            "location": {
                "radius": 100,
                "long": 76.7889,
                "lat": 30.7339
            },
            "time_zone": "Asia/Kolkata",
            "postal_code": "140603",
            "names": {
                "continent": {
                    "ja": "アジア",
                    "pt-BR": "Ásia",
                    "ru": "Азия",
                    "zh-CN": "亚洲",
                    "de": "Asien",
                    "en": "Asia",
                    "es": "Asia",
                    "fr": "Asie"
                },
                "country": {
                    "ja": "インド",
                    "pt-BR": "Índia",
                    "ru": "Индия",
                    "zh-CN": "印度",
                    "de": "Indien",
                    "en": "India",
                    "es": "India",
                    "fr": "Inde"
                },
                "city": {
                    "es": "Chandigarh",
                    "fr": "Chandigarh",
                    "ja": "チャンディーガル",
                    "pt-BR": "Chandigarh",
                    "ru": "Чандигарх",
                    "zh-CN": "昌迪加尔",
                    "de": "Chandigarh",
                    "en": "Chandigarh"
                },
                "subdivision": {
                    "en": "Chandigarh",
                    "fr": "Chandigarh",
                    "ja": "チャンディーガル",
                    "ru": "Чандигарх",
                    "zh-CN": "昌迪加尔"
                }
            },
            "subdivisions": [
                {
                    "iso_code": "CH",
                    "geoname_id": 1274744,
                    "names": {
                        "en": "Chandigarh",
                        "fr": "Chandigarh",
                        "ja": "チャンディーガル",
                        "ru": "Чандигарх",
                        "zh-CN": "昌迪加尔"
                    }
                }
            ]
        },
        "engagement": "0%",
        "percent_viewed": "22%",
        "percent_viewed_tooltip": "22.22%",
        "min_viewed": "--:00:11",
        "sec_viewed": 11,
        "most_viewed_pages": {
            "pages": [
                2
            ],
            "views": 3,
            "page_views": [
                3
            ]
        },
        "pages_by_time_spent": {
            "pages": [
                2
            ],
            "time_spent": "--:00:05"
        },
        "popular_exit_pages": [
            {
                "tracking_link_id": 3208,
                "session_id": 25515,
                "page": 4
            }
        ],
        "page_tags": {
            "1": [],
            "2": [
                "happy",
                "page 2",
                "page 3"
            ],
            "3": [],
            "4": [],
            "5": [],
            "6": [],
            "7": [],
            "8": [],
            "9": []
        },
        "landing_page": {
            "cookie_status": "acceptAll",
            "cyoa_enabled": false,
            "page": 1,
            "language_editions": [],
            "seconds": 3,
            "optin_reject": false,
            "session_pause_time": false,
            "min_viewed": "--:00:03",
            "time_tooltip": "During this visit, Recipient viewed this page 1 time for 3 seconds total.",
            "date": "2024-12-10 @ 10:45:50 IST",
            "date_wrt_time_zone": "2024-12-10 10:45 IST",
            "viewed_at": "10:45 IST",
            "video_on_page": false,
            "element_tags": [],
            "is_landing_page": true,
            "landing_page": 1,
            "exit_page": false,
            "exit_page_time": null,
            "elements_imp_clicks": [],
            "other_clicks": []
        },
        "pages": [
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 3,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:03",
                "time_tooltip": "During this visit, Recipient viewed this page 1 time for 3 seconds total.",
                "date": "2024-12-10 @ 10:45:52 IST",
                "exit_page_time": "2024-12-10 @ 10:46:01 IST",
                "date_wrt_time_zone": "2024-12-10 10:45 IST",
                "optin_done": false,
                "viewed_at": "10:45 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, Recipient viewed this page 3 times for 5 seconds total.",
                "date": "2024-12-10 @ 10:45:55 IST",
                "exit_page_time": "2024-12-10 @ 10:46:01 IST",
                "date_wrt_time_zone": "2024-12-10 10:45 IST",
                "optin_done": false,
                "viewed_at": "10:45 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 2,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:02",
                "time_tooltip": "During this visit, Recipient viewed this page 3 times for 5 seconds total.",
                "date": "2024-12-10 @ 10:45:55 IST",
                "exit_page_time": "2024-12-10 @ 10:46:01 IST",
                "date_wrt_time_zone": "2024-12-10 10:45 IST",
                "optin_done": false,
                "viewed_at": "10:45 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 3,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "once_optin": true,
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, Recipient viewed this page 2 times for 2 seconds total.",
                "date": "2024-12-10 @ 10:45:55 IST",
                "exit_page_time": "2024-12-10 @ 10:46:01 IST",
                "date_wrt_time_zone": "2024-12-10 10:45 IST",
                "optin_done": false,
                "viewed_at": "10:45 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 2,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:02",
                "time_tooltip": "During this visit, Recipient viewed this page 3 times for 5 seconds total.",
                "date": "2024-12-10 @ 10:45:56 IST",
                "exit_page_time": "2024-12-10 @ 10:46:01 IST",
                "date_wrt_time_zone": "2024-12-10 10:45 IST",
                "optin_done": false,
                "viewed_at": "10:45 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 3,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, Recipient viewed this page 2 times for 2 seconds total.",
                "date": "2024-12-10 @ 10:45:58 IST",
                "exit_page_time": "2024-12-10 @ 10:46:01 IST",
                "date_wrt_time_zone": "2024-12-10 10:45 IST",
                "optin_done": false,
                "viewed_at": "10:45 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 4,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": true,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, Recipient viewed this page 1 time for 1 seconds total.",
                "date": "2024-12-10 @ 10:45:58 IST",
                "exit_page_time": "2024-12-10 @ 10:46:01 IST",
                "date_wrt_time_zone": "2024-12-10 10:45 IST",
                "optin_done": false,
                "viewed_at": "10:45 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            }
        ],
        "page_min_viewed": "--:00:03",
        "page_sec_viewed": 3.3333333333333335,
        "location_tooltip": "",
        "linkedInBot": false,
        "otherBot": false,
        "count": 0,
        "ipIsp": "Airtel",
        "time_per_page": [
            {
                "page": 2,
                "min_on_page": "--:00:05",
                "secs_on_page": 5,
                "total_min_on_page": "--:00:05",
                "total_secs_on_page": 5,
                "views": 1
            },
            {
                "page": 1,
                "min_on_page": "--:00:03",
                "secs_on_page": 3,
                "total_min_on_page": "--:00:03",
                "total_secs_on_page": 3,
                "views": 1
            },
            {
                "page": 3,
                "min_on_page": "--:00:02",
                "secs_on_page": 2,
                "total_min_on_page": "--:00:02",
                "total_secs_on_page": 2,
                "views": 1
            }
        ],
        "element_clicks": {},
        "video_element_clicked": [
            false,
            null
        ],
        "video_element_stats": ""
    },
    {
        "cnt": 5,
        "id": 25514,
        "ip": "223.178.210.162",
        "continent": "Asia",
        "country": "India",
        "city": "Chandigarh",
        "subdivision": "Chandigarh",
        "used_password": null,
        "browser": "Chrome",
        "terms_time": null,
        "policy_time": null,
        "os": "Linux",
        "os_family": "linux",
        "device": "Desktop",
        "tracking_stats_link": "/tl-stats/MTczMzMxMDY0OA#/session/25514,session",
        "tracking_link_password": null,
        "tracking_link_tooltip": "fggtfh",
        "tracking_link": {
            "id": 3208,
            "name": "fggtfh",
            "token": "MTczMzMxMDY0OA",
            "user_id": 4310,
            "modifier_id": 6244,
            "company_id": 108,
            "doc_id": 25052,
            "campaign_id": null,
            "docs_files_id": 25094,
            "template": false,
            "language": "de",
            "landing_page": 1,
            "zoom_lvl": "page-height",
            "show_toolbar": true,
            "send_engagement_notif": true,
            "notify_after": 22,
            "notify_for_page": null,
            "notify_page_after": null,
            "privacy_setting": "do_not_show",
            "expiry": null,
            "expire_time": null,
            "expire_time_zone": "Kolkata",
            "has_optin": true,
            "optin_2nd_page_viewed": false,
            "password": null,
            "created_at": "2024-12-04T16:40:48.000+05:30",
            "updated_at": "2024-12-09T13:13:31.000+05:30",
            "notify_send": null,
            "kdf_action_link": false,
            "short_link_123": null,
            "privacy_policy_consent": true,
            "disable_scroll": true,
            "disable_settings": {
                "disable_on": "seconds",
                "disable_for": 3,
                "disable_message": "This document will be scrollable in"
            },
            "show_thumbnail": false,
            "exit_pop": false,
            "exit_pop_settings": {},
            "enter_pop": false,
            "enter_pop_settings": {},
            "flash_pop": false,
            "flash_pop_settings": {},
            "default_enter_pop": false,
            "default_flash_pop": false,
            "default_exit_pop": false,
            "enable_download": false,
            "domain": "powered-by.kinetizine.com",
            "seo_description": "I'm sharing this with you because...",
            "enable_transparent_bg": true,
            "enable_shadow_box": true
        },
        "date": "2024-12-10 @ 10:33:09 IST",
        "is_pp_concent_accept": false,
        "pp_accept_date": "-",
        "heading_date": "2024-12-10 @ 10:33:13 IST",
        "date_wrt_time_zone": "2024-12-10 10:33 IST",
        "session_pages_spend_time": "8 mins & 7 secs on page 2</br>1 mins & 44 secs on page 1</br>12 secs on page 3</br>8 secs on page 7</br>5 secs on page 8</br>",
        "number_of_viewed_pages": "9 pages",
        "ses_landing_page": 1,
        "browser_info": {
            "browser": "Chrome 127.0.0.0",
            "browser_version": "127.0.0.0",
            "browser_icon": "chrome.png",
            "os_icon": "linux.png",
            "os_family": "Linux",
            "device": "Desktop",
            "device_icon": "desktop.png",
            "device_brand": "",
            "device_brand_icon": ""
        },
        "ip_details": {
            "continent": "Asia",
            "continent_code": "AS",
            "country": "India",
            "country_code": "IN",
            "city": "Chandigarh",
            "subdivision": "Chandigarh",
            "subdivision_code": "CH",
            "location": {
                "radius": 100,
                "long": 76.7889,
                "lat": 30.7339
            },
            "time_zone": "Asia/Kolkata",
            "postal_code": "140603",
            "names": {
                "continent": {
                    "es": "Asia",
                    "fr": "Asie",
                    "ja": "アジア",
                    "pt-BR": "Ásia",
                    "ru": "Азия",
                    "zh-CN": "亚洲",
                    "de": "Asien",
                    "en": "Asia"
                },
                "country": {
                    "zh-CN": "印度",
                    "de": "Indien",
                    "en": "India",
                    "es": "India",
                    "fr": "Inde",
                    "ja": "インド",
                    "pt-BR": "Índia",
                    "ru": "Индия"
                },
                "city": {
                    "de": "Chandigarh",
                    "en": "Chandigarh",
                    "es": "Chandigarh",
                    "fr": "Chandigarh",
                    "ja": "チャンディーガル",
                    "pt-BR": "Chandigarh",
                    "ru": "Чандигарх",
                    "zh-CN": "昌迪加尔"
                },
                "subdivision": {
                    "ru": "Чандигарх",
                    "zh-CN": "昌迪加尔",
                    "en": "Chandigarh",
                    "fr": "Chandigarh",
                    "ja": "チャンディーガル"
                }
            },
            "subdivisions": [
                {
                    "iso_code": "CH",
                    "geoname_id": 1274744,
                    "names": {
                        "ru": "Чандигарх",
                        "zh-CN": "昌迪加尔",
                        "en": "Chandigarh",
                        "fr": "Chandigarh",
                        "ja": "チャンディーガル"
                    }
                }
            ]
        },
        "engagement": "22%",
        "percent_viewed": "67%",
        "percent_viewed_tooltip": "66.67%",
        "min_viewed": "--:10:32",
        "sec_viewed": 632,
        "most_viewed_pages": {
            "pages": [
                2
            ],
            "views": 17,
            "page_views": [
                17
            ]
        },
        "pages_by_time_spent": {
            "pages": [
                2
            ],
            "time_spent": "--:08:07"
        },
        "popular_exit_pages": [
            {
                "tracking_link_id": 3208,
                "session_id": 25514,
                "page": 2
            }
        ],
        "page_tags": {
            "1": [],
            "2": [
                "happy",
                "page 2",
                "page 3"
            ],
            "3": [],
            "4": [],
            "5": [],
            "6": [],
            "7": [],
            "8": [],
            "9": []
        },
        "landing_page": {
            "cookie_status": null,
            "cyoa_enabled": false,
            "page": 1,
            "language_editions": [],
            "seconds": 5,
            "optin_reject": false,
            "session_pause_time": false,
            "min_viewed": "--:00:05",
            "time_tooltip": "During this visit, kajal gupta viewed this page 12 times for 1 minutes and 44 seconds total.",
            "date": "2024-12-10 @ 10:33:11 IST",
            "date_wrt_time_zone": "2024-12-10 10:33 IST",
            "viewed_at": "10:33 IST",
            "video_on_page": false,
            "element_tags": [],
            "is_landing_page": true,
            "landing_page": 1,
            "exit_page": false,
            "exit_page_time": null,
            "elements_imp_clicks": [],
            "other_clicks": []
        },
        "pages": [
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 5,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:05",
                "time_tooltip": "During this visit, kajal gupta viewed this page 12 times for 1 minutes and 44 seconds total.",
                "date": "2024-12-10 @ 10:33:13 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 17 times for 8 minutes and 7 seconds total.",
                "date": "2024-12-10 @ 10:33:15 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 3,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "once_optin": true,
                "seconds": 2,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:02",
                "time_tooltip": "During this visit, kajal gupta viewed this page 10 times for 12 seconds total.",
                "date": "2024-12-10 @ 10:33:15 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 17 times for 8 minutes and 7 seconds total.",
                "date": "2024-12-10 @ 10:33:16 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 12 times for 1 minutes and 44 seconds total.",
                "date": "2024-12-10 @ 10:33:16 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 17 times for 8 minutes and 7 seconds total.",
                "date": "2024-12-10 @ 10:33:17 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 12 times for 1 minutes and 44 seconds total.",
                "date": "2024-12-10 @ 10:33:17 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 17 times for 8 minutes and 7 seconds total.",
                "date": "2024-12-10 @ 10:33:17 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 12 times for 1 minutes and 44 seconds total.",
                "date": "2024-12-10 @ 10:33:18 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 3,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 10 times for 12 seconds total.",
                "date": "2024-12-10 @ 10:33:18 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 17 times for 8 minutes and 7 seconds total.",
                "date": "2024-12-10 @ 10:33:18 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 2,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:02",
                "time_tooltip": "During this visit, kajal gupta viewed this page 17 times for 8 minutes and 7 seconds total.",
                "date": "2024-12-10 @ 10:33:18 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 3,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 10 times for 12 seconds total.",
                "date": "2024-12-10 @ 10:33:18 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 17 times for 8 minutes and 7 seconds total.",
                "date": "2024-12-10 @ 10:33:18 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 12 times for 1 minutes and 44 seconds total.",
                "date": "2024-12-10 @ 10:33:18 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 17 times for 8 minutes and 7 seconds total.",
                "date": "2024-12-10 @ 10:33:19 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 3,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 10 times for 12 seconds total.",
                "date": "2024-12-10 @ 10:33:19 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 17 times for 8 minutes and 7 seconds total.",
                "date": "2024-12-10 @ 10:33:19 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 5,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:05",
                "time_tooltip": "During this visit, kajal gupta viewed this page 12 times for 1 minutes and 44 seconds total.",
                "date": "2024-12-10 @ 10:33:19 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 17 times for 8 minutes and 7 seconds total.",
                "date": "2024-12-10 @ 10:33:23 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 12 times for 1 minutes and 44 seconds total.",
                "date": "2024-12-10 @ 10:33:23 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 17 times for 8 minutes and 7 seconds total.",
                "date": "2024-12-10 @ 10:33:24 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 3,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 10 times for 12 seconds total.",
                "date": "2024-12-10 @ 10:33:24 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 3,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 2,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:02",
                "time_tooltip": "During this visit, kajal gupta viewed this page 10 times for 12 seconds total.",
                "date": "2024-12-10 @ 10:33:24 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 4,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 4 times for 4 seconds total.",
                "date": "2024-12-10 @ 10:33:24 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 5,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 4 times for 4 seconds total.",
                "date": "2024-12-10 @ 10:33:25 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 6,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 4 times for 4 seconds total.",
                "date": "2024-12-10 @ 10:33:25 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 7,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 6 times for 8 seconds total.",
                "date": "2024-12-10 @ 10:33:26 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 7,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 2,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:02",
                "time_tooltip": "During this visit, kajal gupta viewed this page 6 times for 8 seconds total.",
                "date": "2024-12-10 @ 10:33:26 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 8,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 4 times for 5 seconds total.",
                "date": "2024-12-10 @ 10:33:26 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 9,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 3 times for 4 seconds total.",
                "date": "2024-12-10 @ 10:33:26 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 8,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 2,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:02",
                "time_tooltip": "During this visit, kajal gupta viewed this page 4 times for 5 seconds total.",
                "date": "2024-12-10 @ 10:33:28 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 7,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 6 times for 8 seconds total.",
                "date": "2024-12-10 @ 10:33:28 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 6,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 4 times for 4 seconds total.",
                "date": "2024-12-10 @ 10:33:28 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 5,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 4 times for 4 seconds total.",
                "date": "2024-12-10 @ 10:33:28 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 4,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 4 times for 4 seconds total.",
                "date": "2024-12-10 @ 10:33:28 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 3,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 10 times for 12 seconds total.",
                "date": "2024-12-10 @ 10:33:29 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 17 times for 8 minutes and 7 seconds total.",
                "date": "2024-12-10 @ 10:33:29 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 2,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:02",
                "time_tooltip": "During this visit, kajal gupta viewed this page 12 times for 1 minutes and 44 seconds total.",
                "date": "2024-12-10 @ 10:33:29 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 17 times for 8 minutes and 7 seconds total.",
                "date": "2024-12-10 @ 10:33:30 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 3,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 10 times for 12 seconds total.",
                "date": "2024-12-10 @ 10:33:30 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 4,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 4 times for 4 seconds total.",
                "date": "2024-12-10 @ 10:33:31 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 5,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 4 times for 4 seconds total.",
                "date": "2024-12-10 @ 10:33:31 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 6,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 4 times for 4 seconds total.",
                "date": "2024-12-10 @ 10:33:31 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 7,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 6 times for 8 seconds total.",
                "date": "2024-12-10 @ 10:33:31 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 8,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 4 times for 5 seconds total.",
                "date": "2024-12-10 @ 10:33:32 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 9,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 3 times for 4 seconds total.",
                "date": "2024-12-10 @ 10:33:32 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 9,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 2,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:02",
                "time_tooltip": "During this visit, kajal gupta viewed this page 3 times for 4 seconds total.",
                "date": "2024-12-10 @ 10:33:32 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 8,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 4 times for 5 seconds total.",
                "date": "2024-12-10 @ 10:33:33 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 7,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 6 times for 8 seconds total.",
                "date": "2024-12-10 @ 10:33:34 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 7,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 2,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:02",
                "time_tooltip": "During this visit, kajal gupta viewed this page 6 times for 8 seconds total.",
                "date": "2024-12-10 @ 10:33:34 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 6,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 4 times for 4 seconds total.",
                "date": "2024-12-10 @ 10:33:34 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 5,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 4 times for 4 seconds total.",
                "date": "2024-12-10 @ 10:33:34 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 4,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 4 times for 4 seconds total.",
                "date": "2024-12-10 @ 10:33:34 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 3,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 10 times for 12 seconds total.",
                "date": "2024-12-10 @ 10:33:35 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 17 times for 8 minutes and 7 seconds total.",
                "date": "2024-12-10 @ 10:33:35 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 2,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:02",
                "time_tooltip": "During this visit, kajal gupta viewed this page 12 times for 1 minutes and 44 seconds total.",
                "date": "2024-12-10 @ 10:33:35 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 17 times for 8 minutes and 7 seconds total.",
                "date": "2024-12-10 @ 10:33:37 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 12 times for 1 minutes and 44 seconds total.",
                "date": "2024-12-10 @ 10:33:37 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 17 times for 8 minutes and 7 seconds total.",
                "date": "2024-12-10 @ 10:33:37 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 3,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 10 times for 12 seconds total.",
                "date": "2024-12-10 @ 10:33:37 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 83,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": true,
                "show_pause_time": true,
                "session_pause_time_tooltip": "At 10:33:43 IST recipient stopped viewing. They returned 1 min later.",
                "min_viewed": "--:01:23",
                "time_tooltip": "During this visit, kajal gupta viewed this page 12 times for 1 minutes and 44 seconds total.",
                "date": "2024-12-10 @ 10:33:38 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 12 times for 1 minutes and 44 seconds total.",
                "date": "2024-12-10 @ 10:33:38 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:33 IST",
                "optin_done": false,
                "viewed_at": "10:33 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 470,
                "exit_page": true,
                "optin_reject": false,
                "session_pause_time": true,
                "show_pause_time": true,
                "session_pause_time_tooltip": "At 10:42:25 IST recipient stopped viewing. They returned 30 mins later.",
                "min_viewed": "--:07:50",
                "time_tooltip": "During this visit, kajal gupta viewed this page 17 times for 8 minutes and 7 seconds total.",
                "date": "2024-12-10 @ 10:35:01 IST",
                "exit_page_time": "2024-12-10 @ 10:43:43 IST",
                "date_wrt_time_zone": "2024-12-10 10:35 IST",
                "optin_done": false,
                "viewed_at": "10:35 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            }
        ],
        "page_min_viewed": "--:01:10",
        "page_sec_viewed": 70.22222222222223,
        "location_tooltip": "",
        "linkedInBot": false,
        "otherBot": false,
        "count": 0,
        "ipIsp": "Airtel",
        "time_per_page": [
            {
                "page": 2,
                "min_on_page": "--:08:07",
                "secs_on_page": 487,
                "total_min_on_page": "--:08:07",
                "total_secs_on_page": 487,
                "views": 1
            },
            {
                "page": 1,
                "min_on_page": "--:01:44",
                "secs_on_page": 104,
                "total_min_on_page": "--:01:44",
                "total_secs_on_page": 104,
                "views": 1
            },
            {
                "page": 3,
                "min_on_page": "--:00:12",
                "secs_on_page": 12,
                "total_min_on_page": "--:00:12",
                "total_secs_on_page": 12,
                "views": 1
            },
            {
                "page": 7,
                "min_on_page": "--:00:08",
                "secs_on_page": 8,
                "total_min_on_page": "--:00:08",
                "total_secs_on_page": 8,
                "views": 1
            },
            {
                "page": 8,
                "min_on_page": "--:00:05",
                "secs_on_page": 5,
                "total_min_on_page": "--:00:05",
                "total_secs_on_page": 5,
                "views": 1
            },
            {
                "page": 9,
                "min_on_page": "--:00:04",
                "secs_on_page": 4,
                "total_min_on_page": "--:00:04",
                "total_secs_on_page": 4,
                "views": 1
            },
            {
                "page": 6,
                "min_on_page": "--:00:04",
                "secs_on_page": 4,
                "total_min_on_page": "--:00:04",
                "total_secs_on_page": 4,
                "views": 1
            },
            {
                "page": 5,
                "min_on_page": "--:00:04",
                "secs_on_page": 4,
                "total_min_on_page": "--:00:04",
                "total_secs_on_page": 4,
                "views": 1
            },
            {
                "page": 4,
                "min_on_page": "--:00:04",
                "secs_on_page": 4,
                "total_min_on_page": "--:00:04",
                "total_secs_on_page": 4,
                "views": 1
            }
        ],
        "element_clicks": {},
        "video_element_clicked": [
            false,
            null
        ],
        "video_element_stats": ""
    },
    {
        "cnt": 5,
        "id": 25513,
        "ip": "223.178.210.162",
        "continent": "Asia",
        "country": "India",
        "city": "Chandigarh",
        "subdivision": "Chandigarh",
        "used_password": null,
        "browser": "Chrome",
        "terms_time": null,
        "policy_time": null,
        "os": "Linux",
        "os_family": "linux",
        "device": "Desktop",
        "tracking_stats_link": "/tl-stats/MTczMzMxMDY0OA#/session/25513,session",
        "tracking_link_password": null,
        "tracking_link_tooltip": "fggtfh",
        "tracking_link": {
            "id": 3208,
            "name": "fggtfh",
            "token": "MTczMzMxMDY0OA",
            "user_id": 4310,
            "modifier_id": 6244,
            "company_id": 108,
            "doc_id": 25052,
            "campaign_id": null,
            "docs_files_id": 25094,
            "template": false,
            "language": "de",
            "landing_page": 1,
            "zoom_lvl": "page-height",
            "show_toolbar": true,
            "send_engagement_notif": true,
            "notify_after": 22,
            "notify_for_page": null,
            "notify_page_after": null,
            "privacy_setting": "do_not_show",
            "expiry": null,
            "expire_time": null,
            "expire_time_zone": "Kolkata",
            "has_optin": true,
            "optin_2nd_page_viewed": false,
            "password": null,
            "created_at": "2024-12-04T16:40:48.000+05:30",
            "updated_at": "2024-12-09T13:13:31.000+05:30",
            "notify_send": null,
            "kdf_action_link": false,
            "short_link_123": null,
            "privacy_policy_consent": true,
            "disable_scroll": true,
            "disable_settings": {
                "disable_on": "seconds",
                "disable_for": 3,
                "disable_message": "This document will be scrollable in"
            },
            "show_thumbnail": false,
            "exit_pop": false,
            "exit_pop_settings": {},
            "enter_pop": false,
            "enter_pop_settings": {},
            "flash_pop": false,
            "flash_pop_settings": {},
            "default_enter_pop": false,
            "default_flash_pop": false,
            "default_exit_pop": false,
            "enable_download": false,
            "domain": "powered-by.kinetizine.com",
            "seo_description": "I'm sharing this with you because...",
            "enable_transparent_bg": true,
            "enable_shadow_box": true
        },
        "date": "2024-12-10 @ 10:31:48 IST",
        "is_pp_concent_accept": false,
        "pp_accept_date": "-",
        "heading_date": "2024-12-10 @ 10:31:51 IST",
        "date_wrt_time_zone": "2024-12-10 10:32 IST",
        "session_pages_spend_time": "11 mins & 2 secs on page 1</br>",
        "number_of_viewed_pages": "1 page",
        "ses_landing_page": 1,
        "browser_info": {
            "browser": "Chrome 127.0.0.0",
            "browser_version": "127.0.0.0",
            "browser_icon": "chrome.png",
            "os_icon": "linux.png",
            "os_family": "Linux",
            "device": "Desktop",
            "device_icon": "desktop.png",
            "device_brand": "",
            "device_brand_icon": ""
        },
        "ip_details": {
            "continent": "Asia",
            "continent_code": "AS",
            "country": "India",
            "country_code": "IN",
            "city": "Chandigarh",
            "subdivision": "Chandigarh",
            "subdivision_code": "CH",
            "location": {
                "radius": 100,
                "long": 76.7889,
                "lat": 30.7339
            },
            "time_zone": "Asia/Kolkata",
            "postal_code": "140603",
            "names": {
                "continent": {
                    "fr": "Asie",
                    "ja": "アジア",
                    "pt-BR": "Ásia",
                    "ru": "Азия",
                    "zh-CN": "亚洲",
                    "de": "Asien",
                    "en": "Asia",
                    "es": "Asia"
                },
                "country": {
                    "zh-CN": "印度",
                    "de": "Indien",
                    "en": "India",
                    "es": "India",
                    "fr": "Inde",
                    "ja": "インド",
                    "pt-BR": "Índia",
                    "ru": "Индия"
                },
                "city": {
                    "de": "Chandigarh",
                    "en": "Chandigarh",
                    "es": "Chandigarh",
                    "fr": "Chandigarh",
                    "ja": "チャンディーガル",
                    "pt-BR": "Chandigarh",
                    "ru": "Чандигарх",
                    "zh-CN": "昌迪加尔"
                },
                "subdivision": {
                    "fr": "Chandigarh",
                    "ja": "チャンディーガル",
                    "ru": "Чандигарх",
                    "zh-CN": "昌迪加尔",
                    "en": "Chandigarh"
                }
            },
            "subdivisions": [
                {
                    "iso_code": "CH",
                    "geoname_id": 1274744,
                    "names": {
                        "fr": "Chandigarh",
                        "ja": "チャンディーガル",
                        "ru": "Чандигарх",
                        "zh-CN": "昌迪加尔",
                        "en": "Chandigarh"
                    }
                }
            ]
        },
        "engagement": "11%",
        "percent_viewed": "11%",
        "percent_viewed_tooltip": "11.11%",
        "min_viewed": "--:11:02",
        "sec_viewed": 662,
        "most_viewed_pages": {
            "pages": [
                1
            ],
            "views": 1,
            "page_views": [
                1
            ]
        },
        "pages_by_time_spent": {
            "pages": [
                1
            ],
            "time_spent": "--:11:02"
        },
        "popular_exit_pages": [
            {
                "tracking_link_id": 3208,
                "session_id": 25513,
                "page": 1
            }
        ],
        "page_tags": {
            "1": [],
            "2": [
                "happy",
                "page 2",
                "page 3"
            ],
            "3": [],
            "4": [],
            "5": [],
            "6": [],
            "7": [],
            "8": [],
            "9": []
        },
        "landing_page": {
            "cookie_status": null,
            "cyoa_enabled": false,
            "page": 1,
            "language_editions": [],
            "seconds": 662,
            "optin_reject": false,
            "session_pause_time": true,
            "session_pause_time_tooltip": "At 10:42:26 IST recipient stopped viewing. They returned 30 mins later.",
            "min_viewed": "--:11:02",
            "time_tooltip": "During this visit, kajal gupta viewed this page 1 time for 11 minutes and 2 seconds total.",
            "date": "2024-12-10 @ 10:31:49 IST",
            "date_wrt_time_zone": "2024-12-10 10:31 IST",
            "viewed_at": "10:31 IST",
            "video_on_page": false,
            "element_tags": [],
            "is_landing_page": true,
            "landing_page": 1,
            "exit_page": true,
            "exit_page_time": "2024-12-10 @ 10:42:51 IST",
            "elements_imp_clicks": [],
            "other_clicks": []
        },
        "pages": [
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 662,
                "exit_page": true,
                "optin_reject": false,
                "session_pause_time": true,
                "show_pause_time": true,
                "session_pause_time_tooltip": "At 10:42:26 IST recipient stopped viewing. They returned 30 mins later.",
                "min_viewed": "--:11:02",
                "time_tooltip": "During this visit, kajal gupta viewed this page 1 time for 11 minutes and 2 seconds total.",
                "date": "2024-12-10 @ 10:31:51 IST",
                "exit_page_time": "2024-12-10 @ 10:42:51 IST",
                "date_wrt_time_zone": "2024-12-10 10:31 IST",
                "optin_done": false,
                "viewed_at": "10:31 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            }
        ],
        "page_min_viewed": "--:11:02",
        "page_sec_viewed": 662,
        "location_tooltip": "",
        "linkedInBot": false,
        "otherBot": false,
        "count": 0,
        "ipIsp": "Airtel",
        "time_per_page": [
            {
                "page": 1,
                "min_on_page": "--:11:02",
                "secs_on_page": 662,
                "total_min_on_page": "--:11:02",
                "total_secs_on_page": 662,
                "views": 1
            }
        ],
        "element_clicks": {},
        "video_element_clicked": [
            false,
            null
        ],
        "video_element_stats": ""
    },
    {
        "cnt": 5,
        "id": 25512,
        "ip": "223.178.210.162",
        "continent": "Asia",
        "country": "India",
        "city": "Chandigarh",
        "subdivision": "Chandigarh",
        "used_password": null,
        "browser": "Chrome",
        "terms_time": null,
        "policy_time": null,
        "os": "Linux",
        "os_family": "linux",
        "device": "Desktop",
        "tracking_stats_link": "/tl-stats/MTczMzMxMDY0OA#/viewer/515",
        "tracking_link_password": null,
        "tracking_link_tooltip": "fggtfh",
        "tracking_link": {
            "id": 3208,
            "name": "fggtfh",
            "token": "MTczMzMxMDY0OA",
            "user_id": 4310,
            "modifier_id": 6244,
            "company_id": 108,
            "doc_id": 25052,
            "campaign_id": null,
            "docs_files_id": 25094,
            "template": false,
            "language": "de",
            "landing_page": 1,
            "zoom_lvl": "page-height",
            "show_toolbar": true,
            "send_engagement_notif": true,
            "notify_after": 22,
            "notify_for_page": null,
            "notify_page_after": null,
            "privacy_setting": "do_not_show",
            "expiry": null,
            "expire_time": null,
            "expire_time_zone": "Kolkata",
            "has_optin": true,
            "optin_2nd_page_viewed": false,
            "password": null,
            "created_at": "2024-12-04T16:40:48.000+05:30",
            "updated_at": "2024-12-09T13:13:31.000+05:30",
            "notify_send": null,
            "kdf_action_link": false,
            "short_link_123": null,
            "privacy_policy_consent": true,
            "disable_scroll": true,
            "disable_settings": {
                "disable_on": "seconds",
                "disable_for": 3,
                "disable_message": "This document will be scrollable in"
            },
            "show_thumbnail": false,
            "exit_pop": false,
            "exit_pop_settings": {},
            "enter_pop": false,
            "enter_pop_settings": {},
            "flash_pop": false,
            "flash_pop_settings": {},
            "default_enter_pop": false,
            "default_flash_pop": false,
            "default_exit_pop": false,
            "enable_download": false,
            "domain": "powered-by.kinetizine.com",
            "seo_description": "I'm sharing this with you because...",
            "enable_transparent_bg": true,
            "enable_shadow_box": true
        },
        "date": "2024-12-10 @ 10:19:00 IST",
        "is_pp_concent_accept": false,
        "pp_accept_date": "-",
        "heading_date": "2024-12-10 @ 10:19:06 IST",
        "date_wrt_time_zone": "2024-12-10 10:20 IST",
        "session_pages_spend_time": "25 secs on page 1</br>12 secs on page 4</br>11 secs on page 3</br>10 secs on page 2</br>8 secs on page 6</br>",
        "number_of_viewed_pages": "9 pages",
        "ses_landing_page": 1,
        "browser_info": {
            "browser": "Chrome 127.0.0.0",
            "browser_version": "127.0.0.0",
            "browser_icon": "chrome.png",
            "os_icon": "linux.png",
            "os_family": "Linux",
            "device": "Desktop",
            "device_icon": "desktop.png",
            "device_brand": "",
            "device_brand_icon": ""
        },
        "ip_details": {
            "continent": "Asia",
            "continent_code": "AS",
            "country": "India",
            "country_code": "IN",
            "city": "Chandigarh",
            "subdivision": "Chandigarh",
            "subdivision_code": "CH",
            "location": {
                "radius": 100,
                "long": 76.7889,
                "lat": 30.7339
            },
            "time_zone": "Asia/Kolkata",
            "postal_code": "140603",
            "names": {
                "continent": {
                    "de": "Asien",
                    "en": "Asia",
                    "es": "Asia",
                    "fr": "Asie",
                    "ja": "アジア",
                    "pt-BR": "Ásia",
                    "ru": "Азия",
                    "zh-CN": "亚洲"
                },
                "country": {
                    "fr": "Inde",
                    "ja": "インド",
                    "pt-BR": "Índia",
                    "ru": "Индия",
                    "zh-CN": "印度",
                    "de": "Indien",
                    "en": "India",
                    "es": "India"
                },
                "city": {
                    "ru": "Чандигарх",
                    "zh-CN": "昌迪加尔",
                    "de": "Chandigarh",
                    "en": "Chandigarh",
                    "es": "Chandigarh",
                    "fr": "Chandigarh",
                    "ja": "チャンディーガル",
                    "pt-BR": "Chandigarh"
                },
                "subdivision": {
                    "en": "Chandigarh",
                    "fr": "Chandigarh",
                    "ja": "チャンディーガル",
                    "ru": "Чандигарх",
                    "zh-CN": "昌迪加尔"
                }
            },
            "subdivisions": [
                {
                    "iso_code": "CH",
                    "geoname_id": 1274744,
                    "names": {
                        "en": "Chandigarh",
                        "fr": "Chandigarh",
                        "ja": "チャンディーガル",
                        "ru": "Чандигарх",
                        "zh-CN": "昌迪加尔"
                    }
                }
            ]
        },
        "engagement": "0%",
        "percent_viewed": "56%",
        "percent_viewed_tooltip": "55.56%",
        "min_viewed": "--:01:21",
        "sec_viewed": 81,
        "most_viewed_pages": {
            "pages": [
                1
            ],
            "views": 11,
            "page_views": [
                11
            ]
        },
        "pages_by_time_spent": {
            "pages": [
                1
            ],
            "time_spent": "--:00:25"
        },
        "popular_exit_pages": [
            {
                "tracking_link_id": 3208,
                "session_id": 25512,
                "page": 1
            }
        ],
        "page_tags": {
            "1": [],
            "2": [
                "happy",
                "page 2",
                "page 3"
            ],
            "3": [],
            "4": [],
            "5": [],
            "6": [],
            "7": [],
            "8": [],
            "9": []
        },
        "landing_page": {
            "cookie_status": "acceptAll",
            "cyoa_enabled": false,
            "page": 1,
            "language_editions": [],
            "seconds": 5,
            "optin_reject": false,
            "session_pause_time": false,
            "min_viewed": "--:00:05",
            "time_tooltip": "During this visit, Recipient viewed this page 11 times for 25 seconds total.",
            "date": "2024-12-10 @ 10:19:04 IST",
            "date_wrt_time_zone": "2024-12-10 10:19 IST",
            "viewed_at": "10:19 IST",
            "video_on_page": false,
            "element_tags": [],
            "is_landing_page": true,
            "landing_page": 1,
            "exit_page": false,
            "exit_page_time": null,
            "elements_imp_clicks": [],
            "other_clicks": []
        },
        "pages": [
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 5,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:05",
                "time_tooltip": "During this visit, Recipient viewed this page 11 times for 25 seconds total.",
                "date": "2024-12-10 @ 10:19:06 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:19 IST",
                "optin_done": false,
                "viewed_at": "10:19 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, Recipient viewed this page 10 times for 10 seconds total.",
                "date": "2024-12-10 @ 10:19:11 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:19 IST",
                "optin_done": false,
                "viewed_at": "10:19 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 3,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "once_optin": true,
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, Recipient viewed this page 8 times for 11 seconds total.",
                "date": "2024-12-10 @ 10:19:11 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:19 IST",
                "optin_done": false,
                "viewed_at": "10:19 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 4,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, Recipient viewed this page 7 times for 12 seconds total.",
                "date": "2024-12-10 @ 10:19:11 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:19 IST",
                "optin_done": false,
                "viewed_at": "10:19 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, Recipient viewed this page 11 times for 25 seconds total.",
                "date": "2024-12-10 @ 10:20:14 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:20 IST",
                "optin_done": false,
                "viewed_at": "10:20 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 11 times for 25 seconds total.",
                "date": "2024-12-10 @ 10:20:14 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:20 IST",
                "optin_done": false,
                "viewed_at": "10:20 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 4,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 7 times for 12 seconds total.",
                "date": "2024-12-10 @ 10:20:15 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:20 IST",
                "optin_done": false,
                "viewed_at": "10:20 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 4,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 7 times for 12 seconds total.",
                "date": "2024-12-10 @ 10:20:15 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:20 IST",
                "optin_done": false,
                "viewed_at": "10:20 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 4,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 6,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:06",
                "time_tooltip": "During this visit, kajal gupta viewed this page 7 times for 12 seconds total.",
                "date": "2024-12-10 @ 10:20:16 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:20 IST",
                "optin_done": false,
                "viewed_at": "10:20 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 5,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 3 times for 3 seconds total.",
                "date": "2024-12-10 @ 10:20:20 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:20 IST",
                "optin_done": false,
                "viewed_at": "10:20 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 6,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 6 times for 8 seconds total.",
                "date": "2024-12-10 @ 10:20:20 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:20 IST",
                "optin_done": false,
                "viewed_at": "10:20 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 8,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 2,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:02",
                "time_tooltip": "During this visit, kajal gupta viewed this page 5 times for 7 seconds total.",
                "date": "2024-12-10 @ 10:20:21 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:20 IST",
                "optin_done": false,
                "viewed_at": "10:20 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 9,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 2 times for 2 seconds total.",
                "date": "2024-12-10 @ 10:20:22 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:20 IST",
                "optin_done": false,
                "viewed_at": "10:20 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 8,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 5 times for 7 seconds total.",
                "date": "2024-12-10 @ 10:20:22 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:20 IST",
                "optin_done": false,
                "viewed_at": "10:20 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 7,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 3 times for 3 seconds total.",
                "date": "2024-12-10 @ 10:20:23 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:20 IST",
                "optin_done": false,
                "viewed_at": "10:20 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 6,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 6 times for 8 seconds total.",
                "date": "2024-12-10 @ 10:20:23 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:20 IST",
                "optin_done": false,
                "viewed_at": "10:20 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 6,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 2,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:02",
                "time_tooltip": "During this visit, kajal gupta viewed this page 6 times for 8 seconds total.",
                "date": "2024-12-10 @ 10:20:23 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:20 IST",
                "optin_done": false,
                "viewed_at": "10:20 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 5,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 3 times for 3 seconds total.",
                "date": "2024-12-10 @ 10:20:23 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:20 IST",
                "optin_done": false,
                "viewed_at": "10:20 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 3,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 8 times for 11 seconds total.",
                "date": "2024-12-10 @ 10:20:24 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:20 IST",
                "optin_done": false,
                "viewed_at": "10:20 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 7,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": true,
                "show_pause_time": true,
                "session_pause_time_tooltip": "At 10:20:30 IST recipient stopped viewing. They returned 8 mins later.",
                "min_viewed": "--:00:07",
                "time_tooltip": "During this visit, kajal gupta viewed this page 11 times for 25 seconds total.",
                "date": "2024-12-10 @ 10:20:25 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:20 IST",
                "optin_done": false,
                "viewed_at": "10:20 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 10 times for 10 seconds total.",
                "date": "2024-12-10 @ 10:28:26 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:28 IST",
                "optin_done": false,
                "viewed_at": "10:28 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 11 times for 25 seconds total.",
                "date": "2024-12-10 @ 10:28:27 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:28 IST",
                "optin_done": false,
                "viewed_at": "10:28 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 2,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:02",
                "time_tooltip": "During this visit, kajal gupta viewed this page 11 times for 25 seconds total.",
                "date": "2024-12-10 @ 10:28:27 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:28 IST",
                "optin_done": false,
                "viewed_at": "10:28 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 10 times for 10 seconds total.",
                "date": "2024-12-10 @ 10:28:28 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:28 IST",
                "optin_done": false,
                "viewed_at": "10:28 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 3,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 8 times for 11 seconds total.",
                "date": "2024-12-10 @ 10:28:28 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:28 IST",
                "optin_done": false,
                "viewed_at": "10:28 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 10 times for 10 seconds total.",
                "date": "2024-12-10 @ 10:28:28 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:28 IST",
                "optin_done": false,
                "viewed_at": "10:28 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 11 times for 25 seconds total.",
                "date": "2024-12-10 @ 10:28:28 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:28 IST",
                "optin_done": false,
                "viewed_at": "10:28 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 10 times for 10 seconds total.",
                "date": "2024-12-10 @ 10:28:28 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:28 IST",
                "optin_done": false,
                "viewed_at": "10:28 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 3,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 8 times for 11 seconds total.",
                "date": "2024-12-10 @ 10:28:29 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:28 IST",
                "optin_done": false,
                "viewed_at": "10:28 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 4,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 7 times for 12 seconds total.",
                "date": "2024-12-10 @ 10:28:29 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:28 IST",
                "optin_done": false,
                "viewed_at": "10:28 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 6,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 2,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:02",
                "time_tooltip": "During this visit, kajal gupta viewed this page 6 times for 8 seconds total.",
                "date": "2024-12-10 @ 10:28:29 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:28 IST",
                "optin_done": false,
                "viewed_at": "10:28 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 6,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 6 times for 8 seconds total.",
                "date": "2024-12-10 @ 10:28:29 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:28 IST",
                "optin_done": false,
                "viewed_at": "10:28 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 7,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 3 times for 3 seconds total.",
                "date": "2024-12-10 @ 10:28:29 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:28 IST",
                "optin_done": false,
                "viewed_at": "10:28 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 8,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 5 times for 7 seconds total.",
                "date": "2024-12-10 @ 10:28:30 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:28 IST",
                "optin_done": false,
                "viewed_at": "10:28 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 9,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 2 times for 2 seconds total.",
                "date": "2024-12-10 @ 10:28:30 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:28 IST",
                "optin_done": false,
                "viewed_at": "10:28 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 8,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 5 times for 7 seconds total.",
                "date": "2024-12-10 @ 10:28:31 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:28 IST",
                "optin_done": false,
                "viewed_at": "10:28 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 8,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 2,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:02",
                "time_tooltip": "During this visit, kajal gupta viewed this page 5 times for 7 seconds total.",
                "date": "2024-12-10 @ 10:28:31 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:28 IST",
                "optin_done": false,
                "viewed_at": "10:28 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 7,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 3 times for 3 seconds total.",
                "date": "2024-12-10 @ 10:28:31 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:28 IST",
                "optin_done": false,
                "viewed_at": "10:28 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 6,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 6 times for 8 seconds total.",
                "date": "2024-12-10 @ 10:28:31 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:28 IST",
                "optin_done": false,
                "viewed_at": "10:28 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 5,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 3 times for 3 seconds total.",
                "date": "2024-12-10 @ 10:28:32 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:28 IST",
                "optin_done": false,
                "viewed_at": "10:28 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 4,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 7 times for 12 seconds total.",
                "date": "2024-12-10 @ 10:28:32 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:28 IST",
                "optin_done": false,
                "viewed_at": "10:28 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 3,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 4,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": true,
                "session_pause_time_tooltip": "At 10:28:38 IST recipient stopped viewing. They returned 2 mins later.",
                "min_viewed": "--:00:04",
                "time_tooltip": "During this visit, kajal gupta viewed this page 8 times for 11 seconds total.",
                "date": "2024-12-10 @ 10:28:32 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:28 IST",
                "optin_done": false,
                "viewed_at": "10:28 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 10 times for 10 seconds total.",
                "date": "2024-12-10 @ 10:31:03 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:31 IST",
                "optin_done": false,
                "viewed_at": "10:31 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 11 times for 25 seconds total.",
                "date": "2024-12-10 @ 10:31:03 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:31 IST",
                "optin_done": false,
                "viewed_at": "10:31 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 4,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "session_pause_time_tooltip": "At 10:31:07 IST recipient stopped viewing. They returned < 1 min later.",
                "min_viewed": "--:00:04",
                "time_tooltip": "During this visit, kajal gupta viewed this page 11 times for 25 seconds total.",
                "date": "2024-12-10 @ 10:31:03 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:31 IST",
                "optin_done": false,
                "viewed_at": "10:31 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 10 times for 10 seconds total.",
                "date": "2024-12-10 @ 10:31:12 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:31 IST",
                "optin_done": false,
                "viewed_at": "10:31 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 3,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "session_pause_time_tooltip": "At 10:31:13 IST recipient stopped viewing. They returned < 1 min later.",
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 8 times for 11 seconds total.",
                "date": "2024-12-10 @ 10:31:13 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:31 IST",
                "optin_done": false,
                "viewed_at": "10:31 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 10 times for 10 seconds total.",
                "date": "2024-12-10 @ 10:31:14 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:31 IST",
                "optin_done": false,
                "viewed_at": "10:31 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 11 times for 25 seconds total.",
                "date": "2024-12-10 @ 10:31:14 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:31 IST",
                "optin_done": false,
                "viewed_at": "10:31 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 10 times for 10 seconds total.",
                "date": "2024-12-10 @ 10:31:14 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:31 IST",
                "optin_done": false,
                "viewed_at": "10:31 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 3,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 8 times for 11 seconds total.",
                "date": "2024-12-10 @ 10:31:14 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:31 IST",
                "optin_done": false,
                "viewed_at": "10:31 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 4,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 7 times for 12 seconds total.",
                "date": "2024-12-10 @ 10:31:14 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:31 IST",
                "optin_done": false,
                "viewed_at": "10:31 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 3,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 8 times for 11 seconds total.",
                "date": "2024-12-10 @ 10:31:15 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:31 IST",
                "optin_done": false,
                "viewed_at": "10:31 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 2,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": false,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 10 times for 10 seconds total.",
                "date": "2024-12-10 @ 10:31:15 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:31 IST",
                "optin_done": false,
                "viewed_at": "10:31 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            },
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 1,
                "exit_page": true,
                "optin_reject": false,
                "session_pause_time": false,
                "show_pause_time": false,
                "session_pause_time_tooltip": "At 10:31:15 IST recipient stopped viewing. They returned < 1 min later.",
                "min_viewed": "--:00:01",
                "time_tooltip": "During this visit, kajal gupta viewed this page 11 times for 25 seconds total.",
                "date": "2024-12-10 @ 10:31:15 IST",
                "exit_page_time": "2024-12-10 @ 10:20:25 IST",
                "date_wrt_time_zone": "2024-12-10 10:31 IST",
                "optin_done": false,
                "viewed_at": "10:31 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            }
        ],
        "page_min_viewed": "--:00:09",
        "page_sec_viewed": 9,
        "location_tooltip": "",
        "linkedInBot": false,
        "otherBot": false,
        "count": 0,
        "ipIsp": "Airtel",
        "time_per_page": [
            {
                "page": 1,
                "min_on_page": "--:00:25",
                "secs_on_page": 25,
                "total_min_on_page": "--:00:25",
                "total_secs_on_page": 25,
                "views": 1
            },
            {
                "page": 4,
                "min_on_page": "--:00:12",
                "secs_on_page": 12,
                "total_min_on_page": "--:00:12",
                "total_secs_on_page": 12,
                "views": 1
            },
            {
                "page": 3,
                "min_on_page": "--:00:11",
                "secs_on_page": 11,
                "total_min_on_page": "--:00:11",
                "total_secs_on_page": 11,
                "views": 1
            },
            {
                "page": 2,
                "min_on_page": "--:00:10",
                "secs_on_page": 10,
                "total_min_on_page": "--:00:10",
                "total_secs_on_page": 10,
                "views": 1
            },
            {
                "page": 6,
                "min_on_page": "--:00:08",
                "secs_on_page": 8,
                "total_min_on_page": "--:00:08",
                "total_secs_on_page": 8,
                "views": 1
            },
            {
                "page": 8,
                "min_on_page": "--:00:07",
                "secs_on_page": 7,
                "total_min_on_page": "--:00:07",
                "total_secs_on_page": 7,
                "views": 1
            },
            {
                "page": 7,
                "min_on_page": "--:00:03",
                "secs_on_page": 3,
                "total_min_on_page": "--:00:03",
                "total_secs_on_page": 3,
                "views": 1
            },
            {
                "page": 5,
                "min_on_page": "--:00:03",
                "secs_on_page": 3,
                "total_min_on_page": "--:00:03",
                "total_secs_on_page": 3,
                "views": 1
            },
            {
                "page": 9,
                "min_on_page": "--:00:02",
                "secs_on_page": 2,
                "total_min_on_page": "--:00:02",
                "total_secs_on_page": 2,
                "views": 1
            }
        ],
        "element_clicks": {},
        "video_element_clicked": [
            false,
            null
        ],
        "video_element_stats": "",
        "receiver": {
            "id": 515,
            "name": "kajal gupta",
            "first_name": "kajal",
            "last_name": "gupta",
            "email": "kajal.gupta@niraginfotech.com",
            "telephone_number": "9988658296"
        }
    },
    {
        "cnt": 5,
        "id": 25333,
        "ip": "223.178.211.208",
        "continent": "Asia",
        "country": "India",
        "city": "Chandigarh",
        "subdivision": "Chandigarh",
        "used_password": null,
        "browser": "Chrome",
        "terms_time": null,
        "policy_time": null,
        "os": "Windows 10",
        "os_family": "windows",
        "device": "Desktop",
        "tracking_stats_link": "/tl-stats/MTczMzMxMDY0OA#/session/25333,session",
        "tracking_link_password": null,
        "tracking_link_tooltip": "fggtfh",
        "tracking_link": {
            "id": 3208,
            "name": "fggtfh",
            "token": "MTczMzMxMDY0OA",
            "user_id": 4310,
            "modifier_id": 6244,
            "company_id": 108,
            "doc_id": 25052,
            "campaign_id": null,
            "docs_files_id": 25094,
            "template": false,
            "language": "de",
            "landing_page": 1,
            "zoom_lvl": "page-height",
            "show_toolbar": true,
            "send_engagement_notif": true,
            "notify_after": 22,
            "notify_for_page": null,
            "notify_page_after": null,
            "privacy_setting": "do_not_show",
            "expiry": null,
            "expire_time": null,
            "expire_time_zone": "Kolkata",
            "has_optin": true,
            "optin_2nd_page_viewed": false,
            "password": null,
            "created_at": "2024-12-04T16:40:48.000+05:30",
            "updated_at": "2024-12-09T13:13:31.000+05:30",
            "notify_send": null,
            "kdf_action_link": false,
            "short_link_123": null,
            "privacy_policy_consent": true,
            "disable_scroll": true,
            "disable_settings": {
                "disable_on": "seconds",
                "disable_for": 3,
                "disable_message": "This document will be scrollable in"
            },
            "show_thumbnail": false,
            "exit_pop": false,
            "exit_pop_settings": {},
            "enter_pop": false,
            "enter_pop_settings": {},
            "flash_pop": false,
            "flash_pop_settings": {},
            "default_enter_pop": false,
            "default_flash_pop": false,
            "default_exit_pop": false,
            "enable_download": false,
            "domain": "powered-by.kinetizine.com",
            "seo_description": "I'm sharing this with you because...",
            "enable_transparent_bg": true,
            "enable_shadow_box": true
        },
        "date": "2024-12-04 @ 17:05:52 IST",
        "is_pp_concent_accept": false,
        "pp_accept_date": "-",
        "heading_date": "2024-12-04 @ 17:22:40 IST",
        "date_wrt_time_zone": "2024-12-04 17:06 IST",
        "session_pages_spend_time": "5 secs on page 1</br>",
        "number_of_viewed_pages": "1 page",
        "ses_landing_page": 1,
        "browser_info": {
            "browser": "Chrome 131.0.0.0",
            "browser_version": "131.0.0.0",
            "browser_icon": "chrome.png",
            "os_icon": "windows10.png",
            "os_family": "Windows",
            "device": "Desktop",
            "device_icon": "desktop.png",
            "device_brand": "",
            "device_brand_icon": ""
        },
        "ip_details": {
            "continent": "Asia",
            "continent_code": "AS",
            "country": "India",
            "country_code": "IN",
            "city": "Chandigarh",
            "subdivision": "Chandigarh",
            "subdivision_code": "CH",
            "location": {
                "radius": 100,
                "long": 76.7889,
                "lat": 30.7339
            },
            "time_zone": "Asia/Kolkata",
            "postal_code": "140603",
            "names": {
                "continent": {
                    "es": "Asia",
                    "fr": "Asie",
                    "ja": "アジア",
                    "pt-BR": "Ásia",
                    "ru": "Азия",
                    "zh-CN": "亚洲",
                    "de": "Asien",
                    "en": "Asia"
                },
                "country": {
                    "es": "India",
                    "fr": "Inde",
                    "ja": "インド",
                    "pt-BR": "Índia",
                    "ru": "Индия",
                    "zh-CN": "印度",
                    "de": "Indien",
                    "en": "India"
                },
                "city": {
                    "ja": "チャンディーガル",
                    "pt-BR": "Chandigarh",
                    "ru": "Чандигарх",
                    "zh-CN": "昌迪加尔",
                    "de": "Chandigarh",
                    "en": "Chandigarh",
                    "es": "Chandigarh",
                    "fr": "Chandigarh"
                },
                "subdivision": {
                    "en": "Chandigarh",
                    "fr": "Chandigarh",
                    "ja": "チャンディーガル",
                    "ru": "Чандигарх",
                    "zh-CN": "昌迪加尔"
                }
            },
            "subdivisions": [
                {
                    "iso_code": "CH",
                    "geoname_id": 1274744,
                    "names": {
                        "en": "Chandigarh",
                        "fr": "Chandigarh",
                        "ja": "チャンディーガル",
                        "ru": "Чандигарх",
                        "zh-CN": "昌迪加尔"
                    }
                }
            ]
        },
        "engagement": "0%",
        "percent_viewed": "11%",
        "percent_viewed_tooltip": "11.11%",
        "min_viewed": "--:00:05",
        "sec_viewed": 5,
        "most_viewed_pages": {
            "pages": [
                1
            ],
            "views": 1,
            "page_views": [
                1
            ]
        },
        "pages_by_time_spent": {
            "pages": [
                1
            ],
            "time_spent": "--:00:05"
        },
        "popular_exit_pages": [
            {
                "tracking_link_id": 3208,
                "session_id": 25333,
                "page": 1
            }
        ],
        "page_tags": {
            "1": [],
            "2": [
                "happy",
                "page 2",
                "page 3"
            ],
            "3": [],
            "4": [],
            "5": [],
            "6": [],
            "7": [],
            "8": [],
            "9": []
        },
        "landing_page": {
            "cookie_status": null,
            "cyoa_enabled": false,
            "page": 1,
            "language_editions": [],
            "seconds": 5,
            "optin_reject": false,
            "session_pause_time": true,
            "session_pause_time_tooltip": "At 17:22:42 IST recipient stopped viewing. They returned 33 mins later.",
            "min_viewed": "--:00:05",
            "time_tooltip": "During this visit, Recipient viewed this page 1 time for 5 seconds total.",
            "date": "2024-12-04 @ 17:22:38 IST",
            "date_wrt_time_zone": "2024-12-04 17:22 IST",
            "viewed_at": "17:22 IST",
            "video_on_page": false,
            "element_tags": [],
            "is_landing_page": true,
            "landing_page": 1,
            "exit_page": true,
            "exit_page_time": "2024-12-04 @ 17:22:43 IST",
            "elements_imp_clicks": [],
            "other_clicks": []
        },
        "pages": [
            {
                "cyoa_enabled": false,
                "page": 1,
                "download": null,
                "download_page": null,
                "language_editions": [],
                "seconds": 5,
                "exit_page": true,
                "optin_reject": false,
                "session_pause_time": true,
                "show_pause_time": true,
                "session_pause_time_tooltip": "At 17:22:42 IST recipient stopped viewing. They returned 33 mins later.",
                "min_viewed": "--:00:05",
                "time_tooltip": "During this visit, Recipient viewed this page 1 time for 5 seconds total.",
                "date": "2024-12-04 @ 17:22:40 IST",
                "exit_page_time": "2024-12-04 @ 17:22:43 IST",
                "date_wrt_time_zone": "2024-12-04 17:22 IST",
                "optin_done": false,
                "viewed_at": "17:22 IST",
                "video_on_page": false,
                "element_tags": [],
                "is_landing_page": false,
                "landing_page": 1,
                "elements_imp_clicks": [],
                "other_clicks": []
            }
        ],
        "page_min_viewed": "--:00:05",
        "page_sec_viewed": 5,
        "location_tooltip": "",
        "linkedInBot": false,
        "otherBot": false,
        "count": 0,
        "ipIsp": "Airtel",
        "time_per_page": [
            {
                "page": 1,
                "min_on_page": "--:00:05",
                "secs_on_page": 5,
                "total_min_on_page": "--:00:05",
                "total_secs_on_page": 5,
                "views": 1
            }
        ],
        "element_clicks": {},
        "video_element_clicked": [
            false,
            null
        ],
        "video_element_stats": ""
    }
];
const Views = () => {
    const { tl } = window?.objInit;
    const [filterPayload, setFilterPayload] = useState({'page':1,'per_page':10, 'type': 'tl' });
    const [viewDataList, setViewDataList] = useState(null);
    const [editRecipientModal, setEditRecipientModal] = useState(null);
    const handleApplyFilter = async (filterData, type = null) => {
        let payload = {...filterData, ...filterPayload, 'direction': type == null ? 'start_time_desc' : 'first_last_asc', 'name': filterData?.recipient, 'token':tl.token};
        const {data, status} = await networkService(`/statistics/sessions?${new URLSearchParams(payload).toString()}`, 'GET')
        setViewDataList(prev => ({...prev,...data,...filterPayload, 'sessions':sessionsDummyData, 'sessions_no':sessionsDummyData?.length}));      
    }

    const updateDataList = async () => {
        let payload = {...filterPayload, 'direction': 'start_time_desc', 'token':tl.token};
        const {data, status} = await networkService(`/statistics/sessions?${new URLSearchParams(payload).toString()}`, 'GET')
        setViewDataList(prev => ({...prev,...data,...filterPayload, 'sessions':sessionsDummyData, 'sessions_no':sessionsDummyData?.length})); 
    }

    const handleUpdateReceipient = async (formData) => {
        let payload = {};
        payload['receiver'] = {...formData, 'name': `${formData?.first_name} ${formData?.last_name}`};
        if(formData?.id == undefined){
           payload['company_id'] = editRecipientModal?.tracking_link?.company_id;
           payload['session_id'] = editRecipientModal?.id;
           payload['campaign_id'] = editRecipientModal?.tracking_link?.campaign_id;
           payload['tracking_link_id'] = editRecipientModal?.tracking_link?.id;
        }
        const {data, status} = await networkService(`/receivers/${formData?.id}/${formData?.id == undefined ? 'add_receiver_info' : 'update_receiver_info'}`, 'POST', payload)
        updateDataList();
    }

    useEffect(() => {
        updateDataList();
    },[])

    const columns = React.useMemo(() => {
        return [
          {
            label: 'Visits',
            key: 'date',
            render: (value, row) => 
                <CustomTooltip title={''}>
                    <a href={`/${row?.receiver?.id}${row?.receiver?.id != undefined ? '&' : ''}${row.id}${row?.receiver?.id == undefined ? ',session' : ''}`} className={``}>
                        {value}
                    </a>
                </CustomTooltip>
          },
          {
            label: 'Recipient',
            key: 'recipient',
            render: (value, row) => 
                <p className={`${value == undefined ? 'hide-icon' : ''}`} >   
                   { value ?
                    <CustomTooltip title={row?.receiver?.email || '-'}>
                        <span>
                            {value}
                        </span>
                    </CustomTooltip>
                    :
                    'Unknown'
                    } 
                    <CustomTooltip title={'Copy Email'}>
                        <FontAwesomeIcon icon={faClipboard} className={`icon bg-transparent`} onClick={async () => navigator.clipboard.writeText(row?.receiver?.email)} />
                    </CustomTooltip>
                    <CustomTooltip title={'Edit'}>
                        <FontAwesomeIcon icon={faPen} className={`${value == undefined ? '' : 'icon bg-transparent'} success-green`} onClick={() => setEditRecipientModal(row)} />
                    </CustomTooltip>
                </p>
          },
          {
            label: 'Location',
            key: 'location',
            render: (value, row) => 
                    <span>
                        <img  
                        src={`${assetsBasePath}/${(row?.country_code?.toLowerCase())}.png`} 
                        alt={row?.country} 
                        width='22px' 
                        className={`mr-1`}
                        />
                        {value} 
                    </span>
          },
        ];
      }, [viewDataList]);

      const renderActions = (row) => (
        <>
          <FontAwesomeIcon icon={faTrash} className="alert-icon" />
        </>
      );
    return(
        <>
        <UpdateRecipientModal
            editRecipientModal={editRecipientModal}
            setEditRecipientModal={setEditRecipientModal}
            submitRecipientForm={handleUpdateReceipient}
        />
        
            <StatsFilters
                threshold={tl?.page_viewed_sec ?? '-'}
                onFilterChange={handleApplyFilter}
            />
            {
               viewDataList == null ? <LoadingScreen />:
               (viewDataList?.sessions?.length > 0 ?
                <StatsTableView 
                    columns={columns} 
                        data={
                            viewDataList?.sessions?.map(
                                it => (
                                    {...it, 
                                        'recipient': it?.receiver?.name, 
                                        'location':`${it?.ip_details?.city} ${it?.ip_details?.subdivision_code}`,
                                        'country_code': it?.ip_details?.country_code,
                                        'country': it?.ip_details?.country,

                                    })
                                )
                            }
                    renderActions={renderActions}
                />
                :
                <div className="back-alert p-3 text-white mt-5">
                    <FontAwesomeIcon icon={faUserXmark} /> Sorry, no unique recipients were found. Either none of your recipients opted-in or the ET needs to be reduced to reveal identified recipients.
                </div>)
            }
        </>
    );
}
export default Views;