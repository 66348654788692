import React, { useState } from "react";
import Form from 'react-bootstrap/Form';

const CustomForm = ({ children,className }) => {

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
    };
    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit} className={className}>
                {children}
            </Form>
        </>
    )
}
export default CustomForm