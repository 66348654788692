import React, { useContext, useEffect, useState } from "react";
import CustomInput from "../../../CommonComponents/CustomForm/CustomInputField";
import CustomForm from "../../../CommonComponents/CustomForm/CustomForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { csrfToken } from "../../../../utis/csrfTokenUtils";
import { networkService } from "../../../config/axios";
import Button from "../../../Elements/Button";
import UserInfoContext from "../../../../utis/userInfoContext";

const ManageDomainName = ({ setShow, companyId, domain }) => {
  const { setShowNotifications } = useContext(UserInfoContext)
  const [error, setError] = useState("");
  const [companyDomain, setCompanyDomain] = useState("");
  const handleChange = ({ target: { value } }) => {
    setError("");
    setCompanyDomain(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!companyDomain) {
      setError("Please add Domain");
    } else {
      const url = domain?.id ? `admin/company_domains/${domain?.id}.json` : 'admin/company_domains.json'
      const data = {
        authenticity_token: csrfToken(),
        company_domain: {
          name: companyDomain,
          company_id: companyId,
        },
        ...(domain?.id && { id: domain?.id })
      };
      await networkService(
        url,
        domain?.id ? 'PATCH' : 'POST',
        data
      ).then((response) => {
        if (response.status == 200 || resposne?.status == 201) {
          setShow('list');
          setShowNotifications({
            showToast: true,
            toastType: "success",
            toastMessage: `Domains ${domain?.id ? 'updated' : 'added'}`,
          })
        }
      }).
        catch((error) => {
          setShowNotifications({
            showToast: true,
            toastType: 'danger',
            toastMessage: "Something went wrong"
          })
        });
    }
  };

  useEffect(() => {
    if (domain?.name) {
      setCompanyDomain(domain?.name)
    } else {
      setCompanyDomain('')
    }
  }, [domain])

  return (
    <CustomForm>
      <div className="d-flex domain-save mb-2">
        <div className="d-flex">
          <span className="adi">@</span>
        </div>
        <CustomInput
          placeholder={"Domain Name"}
          value={companyDomain}
          name={"name"}
          onChange={handleChange}
          required={true}
          type={"text"}
          error={error}
        />
      </div>
      <div className="d-flex justify-content-end">
        <Button
          classList="success"
          type="submit"
          text="Save"
          onClick={(e) => handleSubmit(e)}
        />
        <Button
          classList="alert"
          type="button"
          text="Cancel"
          addonClass='ms-2'
          onClick={() => setShow('list')}
        />
      </div>
    </CustomForm>
  );
};

export default ManageDomainName;