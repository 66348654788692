// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".passwordProtected-module__wrapper___1z-AQ {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: #404040;\n    height: 100vh;\n    width: 100%;\n}\n.passwordProtected-module__passwordInputGroup___10_xy {\n    min-height:unset !important;\n}\n.passwordProtected-module__formWrapper___-XdY7 {\n    width: 100%;\n    height: 100%;\n    max-width: 600px;\n    max-height: 180px;\n    margin: auto;\n    background-color: #fff;\n}", "",{"version":3,"sources":["passwordProtected.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;IACb,WAAW;AACf;AACA;IACI,2BAA2B;AAC/B;AACA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;IACZ,sBAAsB;AAC1B","file":"passwordProtected.module.css","sourcesContent":[".wrapper {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: #404040;\n    height: 100vh;\n    width: 100%;\n}\n.passwordInputGroup {\n    min-height:unset !important;\n}\n.formWrapper {\n    width: 100%;\n    height: 100%;\n    max-width: 600px;\n    max-height: 180px;\n    margin: auto;\n    background-color: #fff;\n}"]}]);
// Exports
exports.locals = {
	"wrapper": "passwordProtected-module__wrapper___1z-AQ",
	"passwordInputGroup": "passwordProtected-module__passwordInputGroup___10_xy",
	"formWrapper": "passwordProtected-module__formWrapper___-XdY7"
};
module.exports = exports;
