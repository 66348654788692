import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Form } from "react-bootstrap";
import { SubscriptionPeriodArr } from "../../../../hooks/utils";

const PieChart = () => {
    const pieOptions = {
        chart: {
            type: 'pie',
        },
        title: {
            text: 'Pie Chart Example',
        },
        series: [
            {
                name: 'Sales',
                data: [
                    { name: 'Product A', y: 30 },
                    { name: 'Product B', y: 40 },
                    { name: 'Product C', y: 30 },
                ],
            },
        ],
    };
    return (
        <>
            <div className="w-25">
                <Form.Select
                    name='type'
                //   value={subscription?.period}
                //   onChange={handleChange}
                >
                    {SubscriptionPeriodArr?.map((option, idx) => (
                        <option key={idx} value={option?.value}>{option?.name}</option>
                    ))}
                </Form.Select>
            </div>
            <HighchartsReact highcharts={Highcharts} options={pieOptions} />
        </>
    )
}
export default PieChart