import React, { useRef, useState, useEffect } from "react";
import resize_right from '../.././../../../../assets/images/resize_right.svg'

const ResizeableElement = ({ resizeDimensions, setResizeDimensions, children, width, height }) => {
    const [isResizing, setIsResizing] = useState(false);
    const resizableRef = useRef(null);
    const aspectRatio = width / height; // Calculate initial aspect ratio

    const handleMouseDown = (e) => {
        e.stopPropagation();
        setIsResizing(true);
    };

    const handleMouseMove = (e) => {
        if (!isResizing) return;

        const newWidth = e.clientX - resizableRef.current.getBoundingClientRect().left;

        // Calculate new height based on aspect ratio
        const newHeight = newWidth / aspectRatio;

        setResizeDimensions({
            width: newWidth > 100 ? newWidth : 100, // Minimum width
            height: newHeight > 100 ? newHeight : 100 // Minimum height
        });
    };

    const handleMouseUp = () => {
        setIsResizing(false);
    };

    useEffect(() => {
        if (isResizing) {
            document.addEventListener("mousemove", handleMouseMove);
            document.addEventListener("mouseup", handleMouseUp);
        } else {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        }

        // Cleanup on unmount
        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, [isResizing]); // Re-run effect when isResizing changes

    useEffect(() => {
        setResizeDimensions({ width, height });
    }, [width, height])

    return (
        <>
            <div
                ref={resizableRef}
                style={{
                    width: `${resizeDimensions.width}px`,
                    height: `${resizeDimensions.height}px`,
                    position: "relative",
                }}
            >
                {children}
                {/* Right edge resizer */}
                <div
                    onMouseDown={handleMouseDown}
                    style={{
                        width: '10px',
                        height: '100%',
                        background: 'transparent',
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        cursor: 'ew-resize',
                    }}
                />
                {/* Bottom edge resizer */}
                <div
                    onMouseDown={handleMouseDown}
                    style={{
                        width: '100%',
                        height: '10px',
                        background: 'transparent',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        cursor: 'ns-resize',
                    }}
                />
                {/* Bottom-right corner resizer */}
                <div
                    onMouseDown={handleMouseDown}
                    style={{
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        cursor: 'se-resize',
                    }}
                >
                    <img src={resize_right} alt='resize icon' style={{ width: 20, height: 20 }} />
                </div>
            </div>
        </>
    );
};

export default ResizeableElement;
