import React, { useState, useEffect } from "react";
import { networkService } from "../../config/axios";
import CustomTagsInput from "../../CommonComponents/Tags/CustomTagsInput";


const LinksPageFilter = ({triggerFiltersApplyEvent}) => {
    const [formData, setFormData] = useState({
        receiver: {name:'', value:[]},
        token: "",
        campaign: {name:'', value:[]},
        laserLinkTags: {name:'', value:[]},
        link: "",
        kdf: "",
    });

    // const [formListData, setFormListData] = useState({
    //     receiver: [],
    //     campaign: [],
    //     laserLinkTags: []
    // });

    const [apiResponses, setApiResponses] = useState({
        receiver: null,
        token: null,
        campaign: null,
        laserLinkTags: null,
        link: null,
        kdf: null,
    });

    const [debounceTimers, setDebounceTimers] = useState({
        receiver: null,
        token: null,
        campaign: null,
        laserLinkTags: null,
        link: null,
        kdf: null,
    });
    const handleInputChange = (data, type) => {  
        // console.log('handleInputChange data, type ', data, type);
          
        const { name, value } = data;
        
        setFormData((prevData) => {
            let valueToUpdate;
            if(type == 'tagsInput'){
                valueToUpdate = {...prevData[name], 'name':value};
            }else if(type == 'tagsInputRemove'){
                valueToUpdate = {...prevData[name], 'value':value, 'name':''};
            }else if(type == 'tagsValue'){
                valueToUpdate = {...prevData[name], 'value':[...prevData[name].value, value], 'name':''};
            }else if(name == 'kdf'){
                valueToUpdate = {...prevData[name], 'value': (type == 'input' ? value : value?.title), 'tl_id':(type == 'input' ? '' : value?.tracking_link?.id ?? '')}
            }else {
                valueToUpdate = value;
            }
            return {
                ...prevData,
                [name]: valueToUpdate,
            }
        });
        if(type == 'tagsInput'){
            debounceApiCall(name, value);
        }else if(type == 'input'){
            debounceApiCall(name, value);
        }
    };
    const debounceApiCall = (inputName, inputValue) => {
        if (debounceTimers[inputName]) {
            clearTimeout(debounceTimers[inputName]);
        }
        const newTimeout = setTimeout(() => {
            fetchApiResponse(inputName, inputValue);
        }, 1000);
        setDebounceTimers((prevTimers) => ({
            ...prevTimers,
            [inputName]: newTimeout,
        }));
    };
    const fetchApiResponse = async (inputName, inputValue) => {
        // console.log('inputValue ', inputName, inputValue);
        
        if (!inputValue) {
            setApiResponses((prev) => ({
                ...prev,
                [inputName]: null,
            }));
            return;
        }
        try {
            let payloadURL = '';
            if(inputName == 'campaign'){
                payloadURL = `campaigns.json?campaign_name=${encodeURIComponent(inputValue)}`;
            }else if(inputName == 'link'){
                payloadURL = `link.json?search=true&name=${encodeURIComponent(inputValue)}`;
            }else if(inputName == 'kdf'){
                payloadURL = `kdfs.json?title=${encodeURIComponent(inputValue)}&tl=yes`;
            }
            else if(inputName == 'laserLinkTags'){
                payloadURL = `tags.json?tag_name=${encodeURIComponent(inputValue)}&items=link_list&type=tl`;
            }else if(inputName == 'receiver'){
                payloadURL = `receivers.json?receiver=${encodeURIComponent(inputValue)}`;
            }
            // else {
            //     payloadURL = `${inputName}=${encodeURIComponent(inputValue)}`;
            // }
            const { data, status } = await networkService(payloadURL);
            // const { data, status } = await networkService(`${inputName?.includes('laserLinkTags') ? 'laserLinkTag' : inputName+'s'}.json?${payloadURL}`);            
            // console.log('laserLinkTags ', data, inputName);
            
            setApiResponses((prev) => ({
                ...prev,
                [inputName]: data,
            }));
            
        } catch (error) {
            // console.error("Error fetching data:", error);
            setApiResponses((prev) => ({
                ...prev,
                [inputName]: ["Error fetching results"],
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let formPayload = {};
        // console.log('formData ', formData);
        
        if(formData?.kdf != ''){
            formPayload['doc_name'] = formData?.kdf?.value;
            formPayload['tl_id'] = formData?.kdf?.tl_id;
        }
        if(formData?.link != ''){
            formPayload['name'] = formData?.link;
        }
        if(formData?.token != ''){
            formPayload['token'] = formData?.token;
        }
        if(formData?.campaign?.value?.length > 0){
            formPayload['campaigns'] = formData?.campaign?.value?.map(it => it.id);
        }
        if(formData?.laserLinkTags?.value?.length > 0){
            // console.log('formData?.laserLinkTags ', formData?.laserLinkTags);
            
            // formPayload['tl_ids'] = formData?.laserLinkTags?.value?.map(it => it.id);
            formPayload['tl_ids'] = formData?.laserLinkTags?.value?.map(it => it.tl_ids[0]);
        }
        if(formData?.receiver?.value?.length > 0){
            formPayload['tl_id'] = formData?.receiver?.value?.map(it => it.id);
        }
        triggerFiltersApplyEvent(formPayload);
    };

    const handleReset = () => {
        setFormData({
            receiver: {name:'', value:[]},
            token: "",
            campaign: {name:'', value:[]},
            laserLinkTags: {name:'', value:[]},
            link: "",
            kdf: "",
        });
        setApiResponses({
            receiver: null,
            token: null,
            campaign: null,
            laserLinkTags: null,
            link: null,
            kdf: null,
        });
        triggerFiltersApplyEvent()
    };

    return (
        <form onSubmit={handleSubmit} className="filterForm">
            {/* {JSON.stringify(formData)} */}
            <div className="d-flex gap-3">
                <div className="form-group position-relative">
                    {/* <input
                        className="kdf-Tags3 ms-0"
                        type="text"
                        placeholder="Recipients..."
                        name="receiver"
                        value={formData.receiver}
                        onChange={(value) => handleInputChange({name:'receiver',"value":value})}
                    /> */}
                    <CustomTagsInput
                        placeholderText={'Recipients...'}
                        tags={formData.receiver.value || []}
                        handleRemoveTags={(value) => handleInputChange({name:'receiver',"value":value}, 'tagsInputRemove')}
                        autoSubmit={true}
                        triggerKeyDownEvent={(value) => handleInputChange({name:'receiver',"value":value}, 'tagsInput')}
                    />
                    {apiResponses.receiver && (
                        <div className="api-response">
                            <ul className="kdf-title-drop-ul">
                                {apiResponses.receiver.map((result, index) => 
                                    formData?.receiver?.value?.filter(it => it.name_email == result?.name_email)?.length == 0 &&
                                    <li 
                                        key={index} 
                                        className='cursor-pointer kdf-title-drop-li'
                                        onClick={() => (handleInputChange({name:'receiver',"value":result}, 'tagsValue'), setApiResponses(prev => ({...prev, "receiver": null})))}
                                    >{result?.name_email}</li>
                                )}
                            </ul>
                        </div>
                    )}
                </div>
                <div className="d-flex form-group">
                    <input
                        className="kdf-Tags3"
                        type="text"
                        placeholder="LaserLINK URL"
                        name="token"
                        value={formData.token}
                        onChange={(value) => handleInputChange(value.target)}
                    />
                </div>
                <div className="d-flex form-group position-relative">
                    {/* <input
                        className="kdf-Tags3"
                        type="text"
                        placeholder="Campaigns"
                        name="campaign"
                        value={formData.campaign}
                        onChange={(value) => handleInputChange({name:'campaign',"value":value})}
                    /> */}
                    {/* {JSON.stringify(formData?.campaign?.value)} */}
                    <CustomTagsInput
                        placeholderText={'Campaigns'}
                        tags={formData?.campaign?.value || []}
                        handleRemoveTags={(value) => handleInputChange({name:'campaign',"value":value}, 'tagsInputRemove')}
                        autoSubmit={true}
                        triggerKeyDownEvent={(value) => handleInputChange({name:'campaign',"value":value}, 'tagsInput')}
                    />
                    {apiResponses?.campaign && (
                        <div className="api-response">
                            <ul className="kdf-title-drop-ul">
                                {apiResponses?.campaign.map((result, index) => 
                                    formData?.campaign?.value?.filter(it => it.name == result?.name)?.length == 0 &&
                                    <li
                                        key={index}
                                        className='cursor-pointer kdf-title-drop-li'
                                        onClick={() => (handleInputChange({name:'campaign',"value":result}, 'tagsValue'), setApiResponses(prev => ({...prev, "campaign": null})))}
                                    >{result?.name}</li>
                                )}
                            </ul>
                        </div>
                    )}
                </div>
                <div className="d-flex form-group position-relative laserlink-inpp">
                    {/* <input
                        className="kdf-Tags2"
                        type="text"
                        placeholder="LaserLINK Tags"
                        name="laserLinkTags"
                        value={formData.laserLinkTags}
                        onChange={(value) => handleInputChange({name:'laserLinkTags',"value":value})}
                    /> */}

                    <CustomTagsInput
                        placeholderText={'LaserLINK Tags'}
                        tags={formData.laserLinkTags.value || []}
                        handleRemoveTags={(value) => handleInputChange({name:'laserLinkTags',"value":value}, 'tagsInputRemove')}
                        autoSubmit={true}
                        triggerKeyDownEvent={(value) => handleInputChange({name:'laserLinkTags',"value":value}, 'tagsInput')}
                    />

                    {apiResponses?.laserLinkTags && (
                        <div className="api-response">
                            <ul className="kdf-title-drop-ul">
                                {apiResponses?.laserLinkTags.map((result, index) => 
                                    formData?.laserLinkTags?.value?.filter(it => it.name == result?.name)?.length == 0 &&
                                    <li 
                                        key={index}
                                        className='cursor-pointer kdf-title-drop-li'
                                        onClick={() => (handleInputChange({name:'laserLinkTags',"value":result}, 'tagsValue'), setApiResponses(prev => ({...prev, "laserLinkTags": null})))}
                                    >{result?.name}</li>
                                )}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
            <div className="d-flex mt-3">
                <div className="d-flex form-group position-relative">
                    <input
                        className="kdf-Tags2 ms-0"
                        type="text"
                        placeholder="LaserLINK Title"
                        name="link"
                        value={formData.link}
                        onChange={(value) => handleInputChange(value.target)}
                    />
                    {apiResponses.link && (
                        <div className="api-response">
                            <ul className="kdf-title-drop-ul">
                                {apiResponses.link.map((result, index) => (
                                    <li
                                        key={index}
                                        className='cursor-pointer kdf-title-drop-li'
                                        onClick={(value) => handleInputChange({name:'link',"value":value})}
                                    >{result}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
                <div className="d-flex form-group position-relative">
                    <input
                        className="kdf-Tags2"
                        type="text"
                        placeholder="KDF Title"
                        name="kdf"
                        value={formData?.kdf?.value}
                        onChange={(e) => handleInputChange(e.target, 'input')}
                    />
                    {/* {JSON.stringify(apiResponses?.kdf)} */}
                    {apiResponses?.kdf && (
                        <div className="api-response">
                            <ul className="kdf-title-drop-ul">
                                {apiResponses?.kdf.map((result, index) => (
                                    <li 
                                        key={index}
                                        className='cursor-pointer kdf-title-drop-li'
                                        onClick={() => (handleInputChange({'name':'kdf',"value":result}), setApiResponses(prev => ({...prev, "kdf": null})))}
                                    >
                                        {result?.title}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>

                <button type="submit" className="btn btn-info margin-left-15 reset">
                    Apply Filters
                </button>
                <button type="button" className="reset-btn" onClick={handleReset}>
                    Reset Filters
                </button>
            </div>
        </form>
    );
};

export default LinksPageFilter;
