import React from "react";
import {
  animations,
  disappear,
  entranceLoop,
} from "../../../../../../../hooks/utils";

const AnimationSection = ({ tool, handleChange }) => {
  return (
    <div className="animation-select">
      <div className="animation-card">
        <div className="animation-title">
          <p>TIMED ANIMATIONS FOR ELEMENT</p>
        </div>
      </div>
      <div className="animation-card">
        <div className="animation-title">
          <p>APPEAR AFTER:</p>
        </div>
        <input
          type="number"
          className="input-field"
          value={tool?.sec_to_delay}
          name="sec_to_delay"
          onChange={handleChange}
        />
        <div
          className={`color-picker-box animate__animated animate__${
            tool?.value?.disappear?.length > 0
              ? tool?.value?.disappear
              : tool?.value?.animation
          } animate__repeat-2 2`}
        ></div>
        <div className="animation-title">
          <p>DISAPPEAR AFTER:</p>
        </div>
        <input
          type="number"
          className="input-field"
          name="disappear_after"
          value={tool?.disappear_after}
          onChange={handleChange}
        />
      </div>

      <div className="animation-card">
        <select
          className="animation-selector"
          name="animation"
          value={tool?.value?.animation}
          onChange={handleChange}
        >
          <option value="animation-type">Entrance Animations</option>
          {Object.entries(animations()?.animations).map(
            ([category, animationArray]) => (
              <optgroup key={category} label={category.replace(/_/g, " ")}>
                {animationArray.map((animation) => (
                  <option key={animation}>{animation}</option>
                ))}
              </optgroup>
            )
          )}
        </select>

        {tool?.value?.animation && (
          <select
            className="animation-selector"
            name="frequency"
            onChange={handleChange}
            value={tool?.value?.frequency}
          >
            <option value="animation-type">Entrance Loops</option>
            {entranceLoop()?.entrance_loop?.map((animation) => (
              <option key={animation}>{animation}</option>
            ))}
          </select>
        )}

        {tool?.disappear_after > 0 && (
          <select
            className="animation-selector"
            name="disappear"
            onChange={handleChange}
            value={tool?.value?.disappear}
          >
            <option value="animation-type">Disappear After</option>
            {Object.entries(disappear()?.disappear).map(
              ([category, animationArray]) => (
                <optgroup key={category} label={category.replace(/_/g, " ")}>
                  {animationArray.map((animation) => (
                    <option key={animation} value={animation}>
                      {animation}
                    </option>
                  ))}
                </optgroup>
              )
            )}
          </select>
        )}
      </div>

      <div className="animation-card viewport-main">
        <div className="d-flex align-items-center cursor-pointer">
          <input
            type="radio"
            className="toggle-button"
            name="timerField"
            onChange={handleChange}
            value="ViewPort"
            checked={tool?.value?.timerField === "ViewPort"}
          />
          <label htmlFor="ViewPort">Whenever in viewport</label>
        </div>
        <div className="d-flex align-items-center cursor-pointer">
          <input
            type="radio"
            className="toggle-button"
            onChange={handleChange}
            name="timerField"
            value="OncePerVisit"
            checked={tool?.value?.timerField === "OncePerVisit"}
          />
          <label htmlFor="OncePerVisit">Once Per visit</label>
        </div>
      </div>
    </div>
  );
};
export default AnimationSection;
