import React, { useState } from "react";
import Breadcrumb from "../../../CommonComponents/BreadCrumb/BreadCrumb";
import Button from "../../../Elements/Button";
import CustomTable from "../../../CommonComponents/Table/CustomTable";
import { UserTypes } from "../../../../hooks/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faL, faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import AddDomainModal from "../../../Modals/Admin/AddDomainModal";
import DataPerPage from "../../../CommonComponents/Pagination/DataPerPage";
import SearchComponent from "../../../CommonComponents/SearchComponent/SearchComponent";

const DomainManagement = (props) => {
    const { user, domains, available_domains } = props
    const getDomainName = (id) => {
        return available_domains?.find((elem) => elem?.id == id)?.name
    }
    const [domainData, setDomainData] = useState({})
    const [openModal, setOpenModal] = useState(false)

    const columns = React.useMemo(() => {
        return [
            {
                Header: 'Domain',
                accessor: 'domain',
                Cell: ({ row: { original } }) => (<>{getDomainName(original?.available_domain_id) ?? 'Domain name'}</>),
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ row: { original } }) => (<>{original?.status ? 'Active' : 'Inactive'}</>),
            },
            {
                Header: 'Default',
                accessor: 'default',
                Cell: ({ row: { original } }) => (<>{original?.default ? 'Yes' : 'No'}</>),
            },
            {
                Header: 'Actions',
                accessor: 'action',
                Cell: ({ row: { original } }) => (
                    <>
                        <FontAwesomeIcon
                            className="me-2 cursor-pointer success"
                            icon={faPen}
                            onClick={() => {
                                setDomainData(original)
                                setOpenModal(true)
                            }}
                        />
                    </>
                ),
            },
        ];
    }, [domains]);

    return (
        <>
            {openModal &&
                <AddDomainModal
                    openModal={openModal}
                    closeModal={setOpenModal}
                    domainData={domainData}
                    availableDomains={available_domains}
                />
            }
            <Breadcrumb string={'Admin/Domain Manager'} />
            <div className="container">
                <div className="show-select1 d-flex mb-2">
                    <div className="mb-2">
                        <DataPerPage />
                        <SearchComponent />
                    </div>
                    <Button
                        classList='success'
                        text={<><FontAwesomeIcon className="me-2" icon={faPlus} /> Add Domains</>}
                        onClick={() => {
                            setOpenModal(true);
                        }}
                        type='button'
                    />
                </div>
                <div className="d-flex justify-content-between">
                    <div>User Name : {user?.name}</div>
                    <div>User Email : {user?.email}</div>
                </div>
                <div className="container">
                    <CustomTable data={domains} columnData={columns} showFilters={false} />
                </div>
            </div>
        </>
    )
}
export default DomainManagement;