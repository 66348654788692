import React from "react";
import Image from "../../../../CommonComponents/Image";
import IFrame from "../../../../CommonComponents/IFrame";
import HtmlText from "../../../../CommonComponents/HtmlText";

const Element = ({ element, dimensions, resizeDimensions, zoom, maskedDimensions }) => {
  switch (element?.element_type_name) {
    case "Image":
      return (
        element?.geometry?.hide_beyond_page_edges ?
          <div style={{
            width: `${maskedDimensions?.intersectingWidth}px`,
            height: `${maskedDimensions?.intersectingHeight}px`,
            overflow: 'hidden',
            ...((resizeDimensions[element.id]?.left ? resizeDimensions[element.id]?.left < 0 : dimensions?.left < 0) && { float: 'right' })
          }}>
            <Image
              src={element?.file_url ?? element?.value?.gif_link}
              alt="Uploaded image"
              onDragStart={(e) => e.preventDefault()}
              style={{
                height: `${resizeDimensions[element.id]?.height - 2 || dimensions?.height - 2}px`,
                width: `${resizeDimensions[element.id]?.width - 2 || dimensions?.width - 2}px`,
                objectFit: "cover",
                transform: `scale(${parseFloat(zoom?.value)})`,
                opacity: `${element?.transparency ?? 10}`,
                ...((resizeDimensions[element.id]?.left ? resizeDimensions[element.id]?.left < 0 : dimensions?.left < 0) && { float: 'right' }),
                transform: `scaleX(${element?.flip ?? 1})`,
              }}
            />
          </div>
          :
          <Image
            src={element?.file_url ?? element?.value?.gif_link}
            alt="Uploaded image"
            onDragStart={(e) => e.preventDefault()}
            style={{
              height: `${resizeDimensions[element.id]?.height - 2 || dimensions?.height - 2}px`,
              width: `${resizeDimensions[element.id]?.width - 2 || dimensions?.width - 2}px`,
              objectFit: "cover",
              transform: `scale(${parseFloat(zoom?.value)})`,
              opacity: `${element?.transparency ?? 10}`,
              transform: `scaleX(${element?.flip ?? 1})`
            }}
          />
      );
    case "Video" || "Web URL":
      return (
        <IFrame
          style={{
            height: `${resizeDimensions[element.id]?.height}px`,
            width: `${resizeDimensions[element.id]?.width}px`,
          }}
          src={element?.value?.video_link}
          title="url"
        />
      );
    case "Web URL":
      return (
        <IFrame
          style={{
            height: `${resizeDimensions[element.id]?.height}px`,
            width: `${resizeDimensions[element.id]?.width}px`,
          }}
          scrolling={element?.value?.no_scroll ? 'no' : 'yes'}
          src={element?.value?.video_link}
          title="url"
        />
      );
    case "HTML Text":
      return <HtmlText htmlContent={element?.value?.html_text} />;
    default:
      return <></>;
  }
};

export default Element;
