import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "../components/Authentication/Login";
import ErrorPage from "./Pages/ErrorPage";
import MainLayout from "./Layout/MainLayout";
import Customizations from "./Pages/Customization/Customizations";
import CreateNewKdf from "./Pages/Kdf/CreateNewKdf";
import KdfSettings from "./Pages/Kdf/EditKdf/KdfSettings";
import KdfListPage from "./Pages/Kdf/KdfListPage";
import EditKdfPage from "./Pages/Kdf/EditKdf/EditKdfPage";
import LinkPage from "./Pages/Links/LinkPage";
import Users from "./Pages/Admin/Users/AdminUsersPage";
import Organizations from "./Pages/Admin/Organisation/AdminOrgPage";
import AdminCustomisationLogPage from "./Pages/Admin/Customisation/AdminCustomisationLogPage";
import RecepientsPage from "./Pages/Recipients/RecepientsPage";
import AddBotPage from "./Pages/Admin/PotentialBots/AddBotsPage";
import AddSubscriptionPage from "./Pages/Admin/Subscription/AddSubscriptionPage";
import AdminSubscriptionsPage from "./Pages/Admin/Subscription/AdminSubscriptionsPage";
import AdminPotentialBotsPage from "./Pages/Admin/PotentialBots/AdminPotentialBotsPage";
import AdminDomainManagePage from "./Pages/Admin/Domains/AdminDomainManagePage";
import ErrorLaserLINK from "./Pages/Links/ErrorLaserLINK";
import DomainManagement from "./Pages/Admin/Domains/DomainManagement";
import StatsView from "./Pages/Stats/StatsView";
import StatsGlance from "./Pages/Stats/StatsGlancePage.js";

const MainBody = (props) => {
  const appRouter = createBrowserRouter([
    {
      path: "/",
      element: <Login status="login" props={props} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/users/login",
      element: <Login status="login" props={props} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/users/confirmation/new",
      element: <Login status="confirmation" props={props} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/users/confirmation",
      element: <Login status="confirmation" props={props} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/users/unlock/new",
      element: <Login status="unlock" props={props} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/users/unlock",
      element: <Login status="unlock" props={props} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "users/password/new",
      element: <Login status="forgotPassword" props={props} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "users/password",
      element: <Login status="forgotPassword" props={props} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/signup",
      element: <Login status="signup" props={props} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/kdfs",
      element: (
        <MainLayout header="KDFs" props={props}>
          <KdfListPage props={props} />
        </MainLayout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/customizations",
      element: (
        <MainLayout header="" props={props}>
          <Customizations {...props} />
        </MainLayout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/kdfs/new",
      element: (
        <MainLayout header="Create New" props={props}>
          <CreateNewKdf props={props} />
        </MainLayout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/kdfs/:id",
      element: (
        <MainLayout header="Create New" props={props}>
          <KdfSettings {...props} />
        </MainLayout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/editor/:id",
      element: <EditKdfPage {...props} editortype="editor" />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/links",
      element: (
        <MainLayout header="Your LaserLINX" props={props}>
          <LinkPage {...props} />
        </MainLayout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/admin/users",
      element: (
        <MainLayout header="" props={props}>
          <Users {...props} />
        </MainLayout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/admin/orgs",
      element: (
        <MainLayout header="" props={props}>
          <Organizations {...props} />
        </MainLayout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/admin/subscriptions",
      element: (
        <MainLayout header="" props={props}>
          <AdminSubscriptionsPage {...props} />
        </MainLayout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/admin/potential_bots",
      element: (
        <MainLayout header="" props={props}>
          <AdminPotentialBotsPage {...props} />
        </MainLayout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/admin/potential_bots/new",
      element: (
        <MainLayout header="" props={props}>
          <AddBotPage {...props} />
        </MainLayout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/admin/subscriptions/new",
      element: (
        <MainLayout header="" props={props}>
          <AddSubscriptionPage {...props} type='add' />
        </MainLayout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/admin/subscriptions/:id/edit",
      element: (
        <MainLayout header="" props={props}>
          <AddSubscriptionPage {...props} type="edit" />
        </MainLayout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/admin/customization_log",
      element: (
        <MainLayout header="" props={props}>
          <AdminCustomisationLogPage {...props} />
        </MainLayout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/admin/get_domain_users",
      element: (
        <MainLayout header="" props={props}>
          <AdminDomainManagePage {...props} />
        </MainLayout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/admin/get_domains/:id",
      element: (
        <MainLayout header="" props={props}>
          <DomainManagement {...props} />
        </MainLayout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/recipients",
      element: (
        <MainLayout header="Recipients" props={props}>
          <RecepientsPage {...props} />
        </MainLayout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/viewer/:id/:lang/dev",
      element: <EditKdfPage {...props} editortype="preview" />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/viewer/:id/:lang",
      element: <EditKdfPage {...props} editortype="publish" />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/:id/:lang",
      element: props?.page == 'error' ? <ErrorLaserLINK /> : <EditKdfPage {...props} editortype="publish" />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/tl-stats/:id",
      element: (
        <MainLayout header="LaserLINK Statistics" props={props}>
          <StatsView {...props} />
        </MainLayout>
      ),
      errorElement: <ErrorPage />,
    },
  ]);
  return <RouterProvider router={appRouter} />;
};
export default MainBody;
