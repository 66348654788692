import React from "react";
export const utilsUpdateLocalStorage = (key, value) => {
    try{
        window.localStorage.setItem(key, value);
        return true;
    }catch (error) {
        console.error('Error: '+error)
    }
}
export const utilsGetLocalStorage = (key) => {
    return window.localStorage.getItem(key);
}