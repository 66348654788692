import React from 'react';

const Breadcrumb = ({ string }) => {
    let crumbs = string?.split('/')
    const lastIndex = crumbs?.length - 1;

    return (
        <div className="Customization">
            <div className="Customization-logs">
                <h6>
                    {crumbs?.length > 0 && crumbs?.map((crumb, index) => {
                        return (
                            <React.Fragment key={index}>
                                {index > 0 && <span className="logs ms-2">/</span>}
                                {
                                    index == lastIndex ? <span className="logs ms-2">{crumb}</span> : <span className="logs ms-2"> {crumb}</span>
                                }
                            </React.Fragment>
                        )
                    }
                    )}
                </h6>
            </div>
        </div>
    );
};

export default Breadcrumb;
