import React, { useEffect, useState } from 'react';
import { Form, Row, Col, InputGroup } from 'react-bootstrap';
import styles from './UpdateRecipientModal.module.css';
import CommonModal from '../../Modals/CommonModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import Button from '../../Elements/Button';
import CustomTooltip from '../../Elements/Tooltip';

const UpdateRecipientModal = ({ setEditRecipientModal = () => {}, editRecipientModal = null, submitRecipientForm }) => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    telephone_number: ''
  });

  const [errors, setErrors] = useState({
    first_name: '',
    last_name: '',
    email: ''
  });

  const validateForm = () => {
    const newErrors = {};

    if (!formData?.first_name?.trim()) {
      newErrors.first_name = 'First name is required.';
    }

    if (!formData?.last_name?.trim()) {
      newErrors.last_name = 'Last name is required.';
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData?.email?.trim()) {
      newErrors.email = 'Email is required.';
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Invalid email format.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Clear error for the field being edited
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const handleCopy = () => {
    if (formData.telephone_number) {
      navigator.clipboard.writeText(formData.telephone_number);
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      submitRecipientForm(formData);
      setEditRecipientModal(null); // Close modal on successful submit
    }
  };

  useEffect(() => {
    setFormData({ ...editRecipientModal?.receiver || {} });
  }, [editRecipientModal]);

  return (
    <CommonModal
      title="Update Recipient's Info"
      modalStatus={editRecipientModal != null}
      changeActiveStatus={() => {}}
      centered
      classList="hideCross"
      footer={
        <div className={`d-flex text-end`}>
          <Button
            text={'Cancel'}
            classList="alert"
            addonClass="my-2 mx-2"
            onClick={() => setEditRecipientModal(null)}
          />
          <Button
            text={'Save'}
            classList="success"
            addonClass="my-2 mx-2"
            onClick={handleSubmit}
          />
        </div>
      }
    >
      <div className="">
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formFirstName" className={styles.formGroup}>
            <Form.Label className={styles.label}>First name:</Form.Label>
            <Form.Control
              type="text"
              name="first_name"
              placeholder="First name"
              value={formData.first_name}
              onChange={handleChange}
              className={styles.inputField}
              isInvalid={!!errors.first_name}
            />
            {errors.first_name && <Form.Text className="text-danger">{errors.first_name}</Form.Text>}
          </Form.Group>
          <Form.Group as={Col} controlId="formLastName" className={styles.formGroup}>
            <Form.Label className={styles.label}>Last name:</Form.Label>
            <Form.Control
              type="text"
              name="last_name"
              placeholder="Last name"
              value={formData.last_name}
              onChange={handleChange}
              className={styles.inputField}
              isInvalid={!!errors.last_name}
            />
            {errors.last_name && <Form.Text className="text-danger">{errors.last_name}</Form.Text>}
          </Form.Group>
        </Row>

        <Form.Group controlId="formEmail" className={`${styles.formGroup} mb-3`}>
          <Form.Label className={styles.label}>Email:</Form.Label>
          <Form.Control
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className={styles.inputField}
            isInvalid={!!errors.email}
          />
          {errors.email && <Form.Text className="text-danger">{errors.email}</Form.Text>}
        </Form.Group>

        <Form.Group controlId="formPhone" className={`${styles.formGroup} mb-3`}>
          <Form.Label className={styles.label}>
            Phone:
            <CustomTooltip title={'Copy Phone Number'}>
              <FontAwesomeIcon icon={faClipboard} onClick={handleCopy} />
            </CustomTooltip>
          </Form.Label>
          <Form.Control
            type="text"
            name="telephone_number"
            placeholder="Telephone Number"
            value={formData.telephone_number}
            className={styles.inputField}
            disabled={true}
          />
        </Form.Group>
      </div>
    </CommonModal>
  );
};

export default UpdateRecipientModal;
