import Tooltip from "../../../CommonComponents/Tooltip/Tooltip";
import React, { useState } from "react";
import noImage from "../../../../../assets/images/icon_upload.svg";
import Button from "../../../Elements/Button";
import CustomTooltip from "../../../Elements/Tooltip";
import { Col, Row } from "react-bootstrap";
import styles from "./KdfListCard.module.css";
import CustomTags from "../../../Elements/CustomTags";
import LaserLinkModal from "../../../Elements/LaserLink/LaserLinkModal";

const KdfListCard = ({
  data,
  triggerUdateRecord,
  triggerUpdateRecordData,
  time_zone_list,
  forKey,
  triggerDeleteKDF,
  triggerCloneKDF,
  triggerMakePrivateEvent,
}) => {
  const [deletePDFActiveState, setDeletePDFActiveState] = useState(null);
  const [privatePDFActiveState, setPrivatePDFActiveState] = useState(null);
  const [tagsEditStatus, setTagsEditStatus] = useState(false);
  const [laserLINKEditStatus, setLaserLINKEditStatus] = useState(null);
  const [laserLINKBatchStatus, setLaserLINKBatchStatus] = useState(false);

  const handlePDFStatusEventTrigger = async () => {
    let recordActionData;
    if (deletePDFActiveState != null) {
      recordActionData = await customAPIRequest(
        `docs/${data.id}/files/${deletePDFActiveState}.json`,
        "DELETE",
        {}
      );
      if (recordActionData?.data != undefined) {
        triggerUdateRecord();
      }
      setDeletePDFActiveState(null);
    } else if (privatePDFActiveState != null) {
      // handleUpdateDocData({"doc_id": docs.id,"private_flag": docUploadData.docData.private_flag});
      triggerUdateRecord();
    }
  };

  return (
    <div key={forKey}>
      {/* {openDeleteModal && <DeleteModal openDeleteModal={openDeleteModal} closeModal={setOpenDeleteModal} />}
        {OpenLaserModal && <CreateLaserLinksModal OpenLaserModal={OpenLaserModal} closeModal={setOpenLaserModal} />} */}
      <LaserLinkModal
        laserItem={laserLINKEditStatus}
        time_zone_list={time_zone_list}
        docData={data}
        triggerCancelProcess={setLaserLINKEditStatus}
        batchStatus={laserLINKBatchStatus}
      />
      {/* <CommonModal 
            title={deletePDFActiveState != null ? 'Delete File' : ''}
            modalStatus={deletePDFActiveState != null || privatePDFActiveState != null}
            changeActiveStatus={() => {setDeletePDFActiveState(null); setPrivatePDFActiveState(null)}}
            centered
            classList={'upload-kdf-modal-wrapper alert-modal'}
            footer={
                <div className="d-flex m-auto">
                    <Button text={'No'} classList={'alert delete-btn'} addonClass={'my-2 mx-2'} onClick={() => {setDeletePDFActiveState(null); setPrivatePDFActiveState(null)}} />
                    <Button text={'Yes'} classList={'success delete-btn'} addonClass={'my-2 mx-2'} onClick={handlePDFStatusEventTrigger} />
                </div>
            }
        >
            <p style={{fontSize:'14px'}}>
                {   
                    deletePDFActiveState != null ? 
                    'Are you sure you want to delete this file? This action cannot be undone.' 
                    :
                    // ( docUploadData?.docData.private_flag ? 'Make this KDF available to all users of this kinetizine account?' : 'Want to set this KDF to PRIVATE? This setting will hide this KDF from other kinetizine users within your organization but your recipients will still be able to view it via the KDF Link(s) you share with them.')
                    ''
                }
            </p>
        </CommonModal> */}
      {/* !data.published_status */}
      <Row
        className={`${styles.listWrapper} ${
          !data?.count_live_files > 0 ? styles.unPublished : ""
        }`}
      >
        <Col className="text-center h-100" md={2}>
          <img
            src={data.thumb != "" ? data.thumb : noImage}
            alt={data.title}
            className={`${styles.thumbImage} ${
              data.thumb != "" ? "" : styles.noThumbImage
            }`}
          />
          <Button
            text={"Edit KDF"}
            classList={`${
              data?.count_active_files !== 0 ? "success" : "disabled"
            } full-width`}
            addonClass={"my-2"}
            onClick={() => window.open(`/editor/${data?.id}`, "_blank")}
            disabled={data?.count_active_files == 0}
          />
          <div className="kdf-a">
            <a target="_blank" href={`/kdfs/${data?.id}`}>
              KDF Settings
            </a>
          </div>
        </Col>
        <Col md={7} className={styles.docWrapper}>
          <Row>
            <Col md={10}>
              <p className={styles.docTitle}>
                <strong>{data?.title}</strong>
              </p>
              <p className={styles.docText}>
                <span>Created by: {data.current_user?.name || "Name"} </span>
                <span
                  className={`ml-1 ${
                    data.private_permission ? "" : "blur_items"
                  }`}
                >
                  {data.num_pages === 1
                    ? `${data.num_pages} Page `
                    : `${data.num_pages ?? 0} Pages `}
                  {data.count_live_files > 0 && !data.private_flag ? (
                    <CustomTooltip
                      title={`${
                        data.published_status ? "Published" : "Unpublished"
                      } on ${data.doc_date_updated} by ${data.username}`}
                    >
                      {/* <span>{data.published_status ? 'Published' : 'Unpublished'}</span> */}
                      <span>
                        {data.count_live_files > 0
                          ? "Published"
                          : "Unpublished"}
                      </span>
                    </CustomTooltip>
                  ) : // data.published_status ? 'Published' : 'Unpublished'
                  data.count_live_files > 0 ? (
                    "Published"
                  ) : (
                    "Unpublished"
                  )}
                </span>
              </p>
              {/* <p className={styles.docText}><span>Last Modified by: </span>{data.modifier ?? '-'}</p> */}
              <p className={styles.docText}>
                <span>
                  Engagement Threshold: {data.page_viewed_sec} seconds
                </span>
              </p>
              <div
                className="custom-tag-wrapper"
                onClick={() => !tagsEditStatus && setTagsEditStatus(true)}
              >
                <span className={styles.docText}>
                  Tags:
                  {!tagsEditStatus ? (
                    data?.tags?.length > 0 ? (
                      data?.tags?.map((tag, tagInd) => (
                        <span
                          key={tagInd}
                          className="tag-primary text-white mx-1 rounded"
                        >
                          {tag.name}
                        </span>
                      ))
                    ) : (
                      <span
                        className="custom-tag-element"
                        onClick={() => setTagsEditStatus(true)}
                      >
                        {" "}
                        Add KDF Tags here
                      </span>
                    )
                  ) : (
                    <CustomTags
                      updateValue={(value) =>
                        triggerUpdateRecordData(value, data, "tags")
                      }
                      tags={data?.tags}
                      handleCancelEvent={(status) => setTagsEditStatus(!status)}
                    />
                  )}
                </span>
              </div>
              {data.stats.engagement > 0 && (
                <div className="d-flex">
                  <span className={`${styles.statsLink} my-1 text-center`}>
                    <strong>{data.stats.viewers}</strong>Visits
                  </span>
                  <span className={`${styles.statsLink} my-1 text-center`}>
                    <strong>{data.stats.min_viewed}</strong>Avg Duration
                  </span>
                  <span className={`${styles.statsLink} my-1 text-center`}>
                    <strong>{data.stats.percent_viewed}</strong>Viewed
                  </span>
                  <span className={`${styles.statsLink} my-1 text-center`}>
                    <strong>{data.stats.unique_viewers}</strong>Opt-in
                  </span>
                  <span className={`${styles.statsLink} my-1 text-center`}>
                    <strong>{data.stats.engagement}</strong>Score
                  </span>
                  <span className={`${styles.statsLink} my-1 text-center`}>
                    <strong>{data.stats.last_viewed}</strong>Last Viewed
                  </span>
                </div>
              )}
            </Col>
            <Col md={2}>
              {data.doc_files_languages?.map((lanDoc, lanInd) => (
                <p key={lanInd}>
                  <img
                    src={`${assetsBasePath}/${
                      (lanDoc?.language?.locale.includes("-")
                        ? lanDoc?.language?.locale?.toLowerCase().split("-")[1]
                        : lanDoc?.language?.locale) ?? "en"
                    }.png`}
                    alt={lanDoc?.language?.name}
                    width="22px"
                  />
                </p>
              ))}
            </Col>
          </Row>
        </Col>
        <Col md={3} className="LASER-CREATE">
          <Button
            text={"Create Laser LINK"}
            classList={`${data?.count_live_files == 0 ? "outline" : "success"}`}
            onClick={() =>
              data?.count_live_files > 0 && setLaserLINKEditStatus(true)
            }
          />
          <div className="laser-popups">
            {/* onClick={() => { setOpenDeleteModal(true) }} */}

            <span
              className="text-link cursor-pointer d-block"
              onClick={() => triggerDeleteKDF(data.id)}
            >
              Delete this KDF
            </span>
            <CustomTooltip title={"Clone KDF"}>
              <span
                className="text-link cursor-pointer d-block"
                onClick={() => triggerCloneKDF(data.id)}
              >
                Clone this KDF
              </span>
            </CustomTooltip>
            <CustomTooltip
              title={
                "Prevent other Kinetizine users from viewing and editing this KDF"
              }
            >
              <span
                className="text-link cursor-pointer d-block"
                onClick={() =>
                  triggerMakePrivateEvent({
                    id: data.id,
                    status: data?.private_flag,
                  })
                }
              >
                Make {data?.private_flag == true ? "Public" : "Private"}
              </span>
            </CustomTooltip>
            <div>
              <Tooltip text={"Generate Multiple LaserLINX using a spreadsheet"}>
                <a
                  className="link-success"
                  onClick={() =>
                    data?.count_live_files > 0 &&
                    (setLaserLINKEditStatus(true),
                    setLaserLINKBatchStatus(true))
                  }
                >
                  Gen. MultiLINX
                </a>
              </Tooltip>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default KdfListCard;
