import React from "react";
import { networkService } from "../../../../../../config/axios";
import CustomTagsInput from "../../../../../../CommonComponents/Tags/CustomTagsInput";
const TagSection = ({ tool, handleChange, totalPages, setTool }) => {
  const rgbToHex = (r, g, b) => {
    const toHex = (x) => {
      const hex = x?.toString(16);
      return hex?.length === 1 ? "0" + hex : hex;
    };
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };

  // Function to check if a string is in RGB format and convert it
  const rgbStringToHex = (rgb) => {
    const rgbMatch = rgb?.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    if (rgbMatch) {
      const [, r, g, b] = rgbMatch;
      return rgbToHex(parseInt(r), parseInt(g), parseInt(b));
    }
    return rgb;
  };

  const handeAddTags = async (value) => {
    let tagsList = await networkService(`tags.json`, "POST", { name: value });
    if (tagsList?.status === 201) {
      setTool((prevState) => {
        const newTags = prevState.tags
          ? [...prevState.tags, tagsList.data]
          : [tagsList.data];
        return {
          ...prevState,
          tags: newTags,
        };
      });
    }
  };

  const handleRemoveTags = (value) => {
    setTool({
      ...tool,
      tags: value,
    });
  };

  return (
    <>
      <div className="tags-inp row">
        <div className="col-md-6">
          <div className="d-flex  align-items-center">
            <label htmlFor="">Tags:</label>
            <CustomTagsInput
              tags={tool?.tags || []}
              handleAddTags={handeAddTags}
              handleRemoveTags={handleRemoveTags}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="toggle-container high-element">
            <label>High Priority Element: </label>
            <input
              type="checkbox"
              name="priority"
              id="toggle2"
              value={tool?.priority}
              onChange={handleChange}
              className="toggle-input"
            />
            <label htmlFor="toggle2" className="toggle-btn"></label>
          </div>
        </div>
      </div>
      <div className="color-select d-flex align-items-center justify-content-between">
        <div>
          <p>BACKGROUND COLOR:</p>
          <div className="colour-picker-field mt-2">
            <input
              className="colour-picker-field__item--picker"
              name="bg_color"
              type="color"
              value={
                tool?.value?.bg_color
                  ? rgbStringToHex(tool.value.bg_color)
                  : "#ffffff"
              }
              onChange={handleChange}
            />
            <input
              className="colour-picker-field__item colour-picker-field__item--text"
              value={tool?.value?.bg_color}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="show-on">
          <p>SHOW ON PAGE:</p>
          <div className="mt-2">
            <input
              type="number"
              name="page"
              value={tool?.page}
              onChange={handleChange}
            />{" "}
            / {totalPages ?? 1}
          </div>
        </div>
      </div>
    </>
  );
};
export default TagSection;
