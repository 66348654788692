import React, { useEffect, useState } from "react";
import './Paginate.module.css';

const AdminPagination = ({ data, itemsPerPage, currentPage, updateDataCount }) => {
    const [pageData, setPageData] = useState([]);

    const totalPages = Math.ceil(data.length / itemsPerPage);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            updatePageData(page);
        }
    };

    const updatePageData = (page) => {
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const currentPageData = data.slice(startIndex, endIndex);
        setPageData(currentPageData);

        updateDataCount(currentPageData.length);
    };

    useEffect(() => {
        updatePageData(currentPage);
    }, [currentPage, data, itemsPerPage]);

    return (
        <>
            <div className="showing-main d-flex justify-content-between mt-3">
                <div className="showing-para">
                    <p>Showing 1 to 10 of 3,837 entries</p>
                </div>
                <div>
                    <button
                        className="previous-btn me-2 previous-pagenisation"
                        onClick={() => handlePageChange(currentPage - 1)}
                    >Previous
                    </button>
                    {[...Array(totalPages).keys()].map((index) => {
                        const page = index + 1;
                        return (
                            <button
                                key={page}
                                onClick={() => handlePageChange(page)}
                                style={{ fontWeight: currentPage === page ? 'bold' : 'normal' }}
                            >
                                {page}
                            </button>
                        );
                    })}
                    <button
                        className="previous-btn previous-pagenisation"
                        onClick={() => handlePageChange(currentPage + 1)}
                    >Next
                    </button>
                </div>
            </div>
        </>
    )
}
export default AdminPagination;