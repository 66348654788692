import React from "react";
import ElementModal from "../../Pages/Kdf/EditKdf/elementModal/ElementModal";
import Button from "../../Elements/Button";
import FormFieldsSection from "../../Pages/Kdf/EditKdf/elementModal/elementSections/elementFormSection/FormFieldsSection";
import DisplayLanguagesSection from "../../Pages/Kdf/EditKdf/elementModal/elementSections/elementFormSection/DisplayLanguagesSection";
import useEditorHook from "../../Pages/Kdf/EditKdf/editorHook/useEditorHook";
import CustomForm from "../../CommonComponents/CustomForm/CustomForm";

const AddElementModal = ({ openAddElementModal, closeModal, docData, docFiles, totalPages }) => {

    const { tool,
        toolType,
        editElement,
        ToolSection,
        handleCloseModal,
        handleSubmitElement,
        handleChange,
        setTool,
        errorState } = useEditorHook(closeModal, docData)

    const languages = docFiles?.filter((elem) => elem?.id !== docData?.id)

    return (
        <ElementModal
            openModal={openAddElementModal}
            heading={ToolSection()?.header}
            handleCloseModal={handleCloseModal}
        >
            <div className="image-popup">
                <CustomForm className="popup-content">
                    {ToolSection()?.component}
                    {toolType !== 'LightboxesToolbox' &&
                        <>
                            <FormFieldsSection
                                handleChange={handleChange}
                                tool={tool}
                                toolType={toolType}
                                docData={docData}
                                editElement={editElement}
                                setTool={setTool}
                                totalPages={totalPages}
                                errorState={errorState}
                            />
                            {
                                languages?.length > 0 && <DisplayLanguagesSection
                                    languages={languages}
                                />
                            }
                        </>
                    }
                    <div className="text-end">
                        <Button
                            classList={'success'}
                            onClick={(e) => handleSubmitElement(e, null, toolType == 'LightboxesToolbox' ? 'image' : '')}
                            text={'Save'}
                            type='submit'
                        />
                    </div>
                </CustomForm>
            </div>
        </ElementModal>
    )
}
export default AddElementModal;