import React from "react"
import './VideoSection.css'
import CustomInput from "../../../../../../CommonComponents/CustomForm/CustomInputField"

const VideoSection = ({ handleVideoUrlUpload, videoLink, text, type, errorState }) => {
    return (
        <div className="video-input-main">
            <div>
                <CustomInput
                    placeholder={`Enter ${type} embed code`}
                    value={videoLink}
                    name={'videoLink'}
                    onChange={(e) => handleVideoUrlUpload(e, type === 'audio' ? 'audio' : 'video')}
                    required={true}
                    type={'text'}
                    error={errorState?.videoErr}
                />
            </div>
            <div className="video-top-para mt-2">
                <p>
                    <em>
                        {text ?? `Use Vimeo's or Youtube's embed code (not the video url)`}
                    </em>
                </p>
            </div>
        </div>
    )
}
export default VideoSection