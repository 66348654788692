// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".errorLaserLINK-module__wrapper___2OQ5J {\n    background-color: #404040;\n    height: 100vh;\n    overflow: hidden;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.errorLaserLINK-module__errorCard___1uKKh {\n    background-color: #fff;\n    height: 100%;\n    max-height: 350px;\n    width: 100%;\n    max-width: 600px;\n    padding: 25px 40px;\n    text-align: center;\n}\n.errorLaserLINK-module__errorImage___30jLy {\n    max-width: 100%;\n}\n.errorLaserLINK-module__errorSubTitle___g4Evl {\n    color: #414042;\n    font-size: 24px;\n}\n.errorLaserLINK-module__errorTitle___3eT10 {\n    font-size: 36px;\n    color: #AAAAA9;\n}", "",{"version":3,"sources":["errorLaserLINK.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,sBAAsB;IACtB,YAAY;IACZ,iBAAiB;IACjB,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI,eAAe;AACnB;AACA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,eAAe;IACf,cAAc;AAClB","file":"errorLaserLINK.module.css","sourcesContent":[".wrapper {\n    background-color: #404040;\n    height: 100vh;\n    overflow: hidden;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.errorCard {\n    background-color: #fff;\n    height: 100%;\n    max-height: 350px;\n    width: 100%;\n    max-width: 600px;\n    padding: 25px 40px;\n    text-align: center;\n}\n.errorImage {\n    max-width: 100%;\n}\n.errorSubTitle {\n    color: #414042;\n    font-size: 24px;\n}\n.errorTitle {\n    font-size: 36px;\n    color: #AAAAA9;\n}"]}]);
// Exports
exports.locals = {
	"wrapper": "errorLaserLINK-module__wrapper___2OQ5J",
	"errorCard": "errorLaserLINK-module__errorCard___1uKKh",
	"errorImage": "errorLaserLINK-module__errorImage___30jLy",
	"errorSubTitle": "errorLaserLINK-module__errorSubTitle___g4Evl",
	"errorTitle": "errorLaserLINK-module__errorTitle___3eT10"
};
module.exports = exports;
