import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Paginate from "../../CommonComponents/Pagination/Paginate";
import utilsUploadFormData from "../../../utis/upoadFormData";
import LoadingScreen from "../../Elements/LoadingScreen/LoadingScreen";
import LinkCard from "./LinkCard";
import { Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import apiGETRequest from "../../../utis/apiRequest";
// import CommonModal from "../../Modals/CommonModal";
// import Input from "../../Elements/Input";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faClipboard, faQuestion, faShuffle, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
// import CustomTooltip from "../../Elements/Tooltip";
// import Select from "../../Elements/Select";

// import UserInfoContext from "../../../utis/userInfoContext";
// import CustomTags from "../../Elements/CustomTags";

// import styles from './links.module.css';
// import Button from "../../Elements/Button";
// import Switch from "../../Elements/Form/Switch";
// import getRandomString from "../../../utis/getRandomString";
// import AccessGateOptions from "../../Modals/CreateLaserLinx/AccessGateOptions";
// import AccessGateOptionsTab from "./AccessGateOptionsTab";
// import PopupOptionsTab from "../../Elements/LaserLink/PopupOptionsTab";
// import LaserLINKSettingsTab from "./LaserLINKSettingsTab";
// import { networkService } from "../../config/axios";
import LaserLinkModal from "../../Elements/LaserLink/LaserLinkModal";
import { networkService } from "../../config/axios";
import CommonModal from "../../Modals/CommonModal";
import Button from "../../Elements/Button";
import LinksPageFilter from "./LinksPageFilter";
import customAPIRequest from "../../../utis/customAPIRequest";


const LinkPage = ({time_zone_list}) => {
    const [linkListData, setLinkListData] = useState({ loading: true, data: [] });
    const [selectedKDFLink, setSelectedKDFLink] = useState(null);
    const [getDeleteLaserLinkItem, setDeleteLaserLinkItem] = useState(null);
    const itemsPerPage = 10;
    const updateLaserLinksData = async (page = 1) => {
        let getValue = {
            "page": page,
            "per_page": itemsPerPage,
            "is_filter": true
        }
        let {data} = await utilsUploadFormData('links/search.json', getValue, 'POST');
        setLinkListData({...data, loading: false, "page": page, "per_page": itemsPerPage})
        // setLinkListData({ ...getLaserLink, data: dummyData, loading: false, "page": page, "per_page": itemsPerPage });
    }

    const handleCopyLaserLinkEvent = async (listingID) => {
        let { data } = await networkService(`/links/clone/${listingID}.json`, 'GET');
        if(data.msg == 'ok'){
            updateLaserLinksData();
        }
    }

    useEffect(() => {
        updateLaserLinksData();
    }, [])

    // useEffect(() => {
    //     console.log('editLaserLinkItem ', editLaserLinkItem);        
    // },[editLaserLinkItem])

    const handleDeleteLaserLinkItemEvent = async () => {
        let {status} = await networkService(`/links/${getDeleteLaserLinkItem}.json`, 'DELETE');
        if(status == 204){
            updateLaserLinksData();
            setDeleteLaserLinkItem(null)
        }
    }

    const handleUpdateLaserLinkData = async (docData, dataPayload) => {
        let {status} = await networkService(`/links/${docData.id}.json`, 'PATCH', 
            {
            "id": docData?.id,
            "name": docData?.name,
            "doc_id": docData?.doc.id,
            "tl": {},
            "tag_ids": [
               ...dataPayload
            ]
            }
        );
        if(status == 200){
            updateLaserLinksData();
        }
    }

    const handleFiltersApplyEvent = async (payload) => {        
        let formPayload = {
            "page": linkListData?.page ?? 1,
            "per_page": linkListData?.per_page ?? itemsPerPage,
            "is_filter": true,
            ...payload
        }
        let linkListALlData = await customAPIRequest('links/search.json', 'POST', formPayload);
        setLinkListData({"page": linkListData.page, "per_page": linkListData.per_page, ...linkListALlData.data})
    }

    return (
        <>
            <LaserLinkModal
                laserItem={selectedKDFLink}
                time_zone_list={time_zone_list}
                updateSelectedKDFLink={setSelectedKDFLink}
                triggerUpdateList={() => handleFiltersApplyEvent()}
            />
            <CommonModal 
                title={'Delete LaserLINK'}
                modalStatus={getDeleteLaserLinkItem != null }
                changeActiveStatus={() => setDeleteLaserLinkItem(null)}
                centered
                classList={'upload-kdf-modal-wrapper alert-modal'}
                footer={
                    <div className="d-flex m-auto">
                        <Button text={'Cancel'} classList={'alert delete-btn'} addonClass={'my-2 mx-2'} onClick={() => setDeleteLaserLinkItem(null)} />
                        <Button text={'Delete'} classList={'success delete-btn'} addonClass={'my-2 mx-2'} onClick={handleDeleteLaserLinkItemEvent} />
                    </div>
                }
            >
                <p style={{fontSize:'14px'}}>
                    Are you sure you want to delete this LaserLINK? This action cannot be undone.
                </p>
            </CommonModal>
            <div className="header-content-main">
                <div className="doc-filter">
                    <h4 className="filter-title">Filters</h4>
                    <LinksPageFilter 
                        triggerFiltersApplyEvent={handleFiltersApplyEvent}
                    />
                </div>
            </div>
            {
                linkListData.loading ?
                    <LoadingScreen />
                    :
                    (
                        linkListData?.tracking_links?.length > 0 ?
                            <Container style={{ maxWidth: '800px', margin: '30px auto 5rem' }}>
                                <Paginate
                                    currentPage={linkListData?.page}
                                    totalPages={Math.ceil(linkListData?.cnt / linkListData?.per_page)}
                                    onPageChange={updateLaserLinksData}
                                    totalData={linkListData?.cnt}
                                    itemTitle={'LaserLINX'}
                                />
                                {
                                    linkListData?.tracking_links?.map(
                                        (linkData, linkDataIndex) =>
                                            <LinkCard 
                                                data={linkData} 
                                                key={linkDataIndex} 
                                                trigerCopyLaserLinkEvent={handleCopyLaserLinkEvent} 
                                                triggerSettingsLaserLinkEvent={setSelectedKDFLink}
                                                triggerDeleteLaserLinkEvent={setDeleteLaserLinkItem}
                                                updateLinkData={handleUpdateLaserLinkData}
                                            />
                                    )
                                }
                                <Paginate
                                    currentPage={linkListData?.page}
                                    totalPages={Math.ceil(linkListData?.cnt / linkListData?.per_page)}
                                    onPageChange={updateLaserLinksData}
                                    totalData={linkListData?.cnt}
                                    itemTitle={'LaserLINX'}
                                />
                            </Container>
                            :
                            <div className="alert w-100 d-flex p-4">
                                Sorry, We tried our best but could NOT find a LaserLINK matching the filter you used. Please reset the filter and try again.
                            </div>
                    )
            }

        </>
    )
}
export default LinkPage;
