import React, { useEffect, useState } from "react";
import DraggableComponent from "../../../CommonComponents/Draggable/DraggableComponent";
import "../../../../../assets/stylesheets/editorPage.css";
import EditorHeader from "./editorHeader/EditorHeader";
import { EditorInfoContext } from "../../../../utis/userInfoContext";
import AddElementModal from "../../../Modals/EditorModals/AddElementModal";
import ToolBoxHeading from "./editorToolbox/toolBox/ToolBoxHeading";
import EditorToolbox from "./editorToolbox/EditorToolbox";
import Viewer from "./pdfViewer/Viewer";
import PdfSidebar from "./editorSideBar/PdfSidebar";
import DeleteElementModal from "../../../Modals/EditorModals/DeleteElementModal";
import LightBoxModal from "../../../Modals/EditorModals/LightBoxModal";
import CloneElementModal from "../../../Modals/EditorModals/CloneElementModal";
import {
  useAccessGateOptions,
  useRecipientNotification,
  useSettingsEnableOPTIN,
} from "../../../../utis/useLaserLinkSettingsHook";
import SettingsEnableOPTIN from "./editorHeader/viewElements/SettingsEnableOPTIN";
import ToastNotification from "../../../CommonComponents/Toasts/ToastNotification";
import CommonModal from "../../../Modals/CommonModal";
import { networkService } from "../../../config/axios";
import Element from "../../../Elements/KDFElements/Element";
import Button from "../../../Elements/Button";
import { Container } from "react-bootstrap";
import PasswordProtectedKDF from "./PasswordProtectedKDF";
import {
  utilsGetLocalStorage,
  utilsUpdateLocalStorage,
} from "../../../../utis/utilsUpdateLocalStorage";
import { useLocation } from "react-router-dom";

let throttleTimeout;
let timedThrottleTimeout;

const EditKdfPage = ({ editortype, ...props }) => {
  if (props?.type == "password") {
    return <PasswordProtectedKDF alert={props?.alert} />;
  }
  const { pathname } = useLocation();
  const { tlb, sess_id } = window?.objInit;
  const { pdfFile, pdfImages, doc } = props;

  const [openSideSection, setOpenSideSection] = useState(false);
  const [zoomIndex, setZoomIndex] = useState({
    index: 3,
    value: "1",
  });
  const [docFiles, setDocFiles] = useState({});
  const [openCloneModal, setOpenCloneModal] = useState(false);

  // New state variables for controlling the background and alert visibility
  const [viewerBgClass, setViewerBgClass] = useState("");
  const [alertTextVisible, setAlertTextVisible] = useState(false);

  // edit page context states
  const [editEnabled, setEditEnabled] = useState(false);
  const [openAddElement, setOpenAddElement] = useState(false);
  const [toolType, setToolType] = useState("");
  const [rectangles, setRectangles] = useState([]);
  const [activeRectangleIndex, setActiveRectangleIndex] = useState(null);
  const [geo, setGeo] = useState({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    keep_proportions: true,
    keep_within_page: true,
    page: 1,
  });
  const [editElement, setEditElement] = useState({});
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [elements, setElements] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [lightBoxList, setLightBoxList] = useState([]);
  const [openLightBox, setOpenLightBox] = useState(false);
  const [editLightBox, setEditLightBox] = useState({});
  const [imageElement, setImageElement] = useState({});
  const [selectedPdf, setSelectedPdf] = useState("");
  const [showToast, setShowToast] = useState({
    showToast: false,
    toastType: "success",
    toastMessage: "",
  });

  const [showLightBox, setShowLightBox] = useState(null);
  const [lightBoxListData, setLightBoxListData] = useState({});
  const [tagValue, setTagValue] = useState(false)
  const [pageDimensions, setPageDimensions] = useState({
    height: '',
    width: ''
  })

  const handleBlockerEvent = () => {
    if (tlb?.disable_scroll === true) {
      setViewerBgClass("scroll_disable");
      setAlertTextVisible(true);
      setTimeout(() => {
        setViewerBgClass("");
        setAlertTextVisible(false);
      }, tlb?.disable_settings?.disable_for * 1000);
    }
  };

  const handleLightBoxEvent = async () => {
    let { data } = await networkService(
      `docs/all-user-lightboxes/all.json?tl_id=${tlb?.id}&by_trigger=yes&doc_id=${tlb?.doc?.id}&language=${tlb?.language_flag?.locale}`
    );
    setLightBoxListData(data);
  };
  const [enterDeviceLightBox, setEnterDeviceLightBox] = useState(false);
  const [exitDeviceLightBox, setExitDeviceLightBox] = useState(false);
  const [pageDeviceLightBox, setPageDeviceLightBox] = useState(false);

  useEffect(() => {
    if (Object.keys(lightBoxListData)?.length > 0) {
      const isMobile = window.innerWidth <= 768;
      const displayEnterDevice = tlb?.enter_pop_settings?.display_enter_device;
      // console.log('lightBoxListData ', lightBoxListData);

      if (displayEnterDevice === "On mobile" && isMobile) {
        setEnterDeviceLightBox(true);
        let lightBoxValueToUpdate =
          lightBoxListData?.doc[tlb?.enter_pop_settings?.lightbox]?.elements;
        setShowLightBox(lightBoxValueToUpdate);
      } else if (displayEnterDevice === "On Device" && !isMobile) {
        setEnterDeviceLightBox(true);
        let lightBoxValueToUpdate =
          lightBoxListData?.doc[tlb?.enter_pop_settings?.lightbox]?.elements;
        setShowLightBox(lightBoxValueToUpdate);
      } else if (displayEnterDevice === "On all devices") {
        setEnterDeviceLightBox(true);
        let lightBoxValueToUpdate =
          lightBoxListData?.doc[tlb?.enter_pop_settings?.lightbox]?.elements;
        setShowLightBox(lightBoxValueToUpdate);
      }
    }
  }, [lightBoxListData]);

  const handleMouseMove = (e) => {
    const mouseY = e.clientY;
    if (mouseY <= 10) {
      const isMobile = window.innerWidth <= 768;
      const displayEnterDevice = tlb?.exit_pop_settings?.display_device;

      if (displayEnterDevice === "On mobile" && isMobile) {
        setExitDeviceLightBox(true);
        let lightBoxValueToUpdate =
          lightBoxListData?.onexit[tlb?.exit_pop_settings?.lightbox]?.elements;
        setShowLightBox(lightBoxValueToUpdate);
      } else if (displayEnterDevice === "On Device" && !isMobile) {
        setExitDeviceLightBox(true);
        let lightBoxValueToUpdate =
          lightBoxListData?.onexit[tlb?.exit_pop_settings?.lightbox]?.elements;
        setShowLightBox(lightBoxValueToUpdate);
      } else if (displayEnterDevice === "On all devices") {
        setExitDeviceLightBox(true);
        let lightBoxValueToUpdate =
          lightBoxListData?.onexit[tlb?.exit_pop_settings?.lightbox]?.elements;
        setShowLightBox(lightBoxValueToUpdate);
      }
    }
  };

  useEffect(() => {
    if (
      tlb?.exit_pop === true &&
      enterDeviceLightBox === false &&
      exitDeviceLightBox === false &&
      Object.keys(lightBoxListData)?.length > 0
    ) {
      window.addEventListener("mousemove", handleMouseMove);
    }
    return () => {
      window?.removeEventListener("mousemove", handleMouseMove);
    };
  }, [lightBoxListData]);

  // useEffect(() => {
  //     if (throttleTimeout) clearTimeout(throttleTimeout);
  //     throttleTimeout = setTimeout(() => {
  //         if (
  //             tlb?.exit_pop === true &&
  //             enterDeviceLightBox === false &&
  //             exitDeviceLightBox === false &&
  //             Object.keys(lightBoxListData)?.length > 0
  //         ) {
  //             const isMobile = window.innerWidth <= 768;
  //             const displayEnterDevice = tlb?.exit_pop_settings?.display_device;

  //             if (displayEnterDevice === 'On mobile' && isMobile) {
  //                 setExitDeviceLightBox(true);
  //                 let lightBoxValueToUpdate = lightBoxListData?.onexit[tlb?.exit_pop_settings?.lightbox]?.elements;
  //                 setShowLightBox(lightBoxValueToUpdate);
  //             } else if (displayEnterDevice === 'On Device' && !isMobile) {
  //                 setExitDeviceLightBox(true);
  //                 let lightBoxValueToUpdate = lightBoxListData?.onexit[tlb?.exit_pop_settings?.lightbox]?.elements;
  //                 setShowLightBox(lightBoxValueToUpdate);
  //             } else if (displayEnterDevice === 'On all devices') {
  //                 setExitDeviceLightBox(true);
  //                 let lightBoxValueToUpdate = lightBoxListData?.onexit[tlb?.exit_pop_settings?.lightbox]?.elements;
  //                 setShowLightBox(lightBoxValueToUpdate);
  //             }
  //         }
  //     }, 100);
  //     return () => clearTimeout(throttleTimeout);
  // }, [pageNumber]);

  useEffect(() => {
    if (timedThrottleTimeout) clearTimeout(timedThrottleTimeout);
    timedThrottleTimeout = setTimeout(() => {
      if (
        tlb?.flash_pop === true &&
        enterDeviceLightBox === false &&
        exitDeviceLightBox === false &&
        pageDeviceLightBox == false &&
        Object.keys(lightBoxListData)?.length > 0
      ) {
        const isMobile = window.innerWidth <= 768;
        const displayEnterDevice =
          tlb?.flash_pop_settings?.display_flash_device;
        const enteryPage = tlb?.flash_pop_settings?.on_page;

        if (displayEnterDevice === "On mobile" && isMobile) {
          setPageDeviceLightBox(true);
          let lightBoxValueToUpdate =
            lightBoxListData?.page[tlb?.flash_pop_settings?.on_page][
              tlb?.flash_pop_settings?.lightbox
            ]?.elements;
          setShowLightBox(lightBoxValueToUpdate);
        } else if (displayEnterDevice === "On Device" && !isMobile) {
          setPageDeviceLightBox(true);
          let lightBoxValueToUpdate =
            lightBoxListData?.page[tlb?.flash_pop_settings?.on_page][
              tlb?.flash_pop_settings?.lightbox
            ]?.elements;
          setShowLightBox(lightBoxValueToUpdate);
        } else if (displayEnterDevice === "On all devices") {
          setPageDeviceLightBox(true);
          let lightBoxValueToUpdate =
            lightBoxListData?.page[tlb?.flash_pop_settings?.on_page][
              tlb?.flash_pop_settings?.lightbox
            ]?.elements;
          setShowLightBox(lightBoxValueToUpdate);
        }
      }
    }, tlb?.flash_pop_settings?.seen_seconds * 1000);
    return () => clearTimeout(timedThrottleTimeout);
  }, [pageNumber]);

  const updateDocHeaderData = () => {
    document.title = doc?.title ?? "Kinetizine";
    document.head.innerHTML += `<title></title><meta name='description' content='${tlb?.seo_description}'/>`;
    let descMeta = document.querySelector("meta[name='description']");
    descMeta.setAttribute("content", tlb?.seo_description);
  };

  useEffect(() => {
    setDocFiles(window?.objInit?.doc_files);
    setSelectedPdf(pdfFile?.file);
    updateDocHeaderData();
    handleBlockerEvent();
    handleLightBoxEvent();
  }, []);

  useRecipientNotification(
    editEnabled,
    pageNumber,
    tlb,
    `notify-engagement/${tlb?.token}/${sess_id}.json`
  );

  const [concentModalStatus, setConcentModalStatus] = useState(
    editEnabled == false &&
    tlb?.privacy_policy_consent == true &&
    utilsGetLocalStorage("cookiesPrivacy") != "acceptAll"
  );

  return (
    <EditorInfoContext.Provider
      value={{
        editEnabled: editEnabled,
        openAddElement: openAddElement,
        rectangles: rectangles,
        activeRectangleIndex: activeRectangleIndex,
        geo: geo,
        toolType,
        elements: elements,
        editElement: editElement,
        pageNumber: pageNumber,
        openRemoveModal,
        editLightBox,
        lightBoxList,
        imageElement,
        openLightBox,
        selectedPdf,
        showToast,
        tagValue,
        pageDimensions, 
        setPageDimensions,
        setTagValue,
        setShowToast,
        setSelectedPdf,
        setOpenLightBox,
        setImageElement,
        setLightBoxList,
        setEditElement,
        setRectangles,
        setOpenAddElement,
        setEditEnabled,
        setActiveRectangleIndex,
        setGeo,
        setToolType,
        setElements,
        setOpenRemoveModal,
        setPageNumber,
        setEditLightBox,
      }}
    >
      {(editEnabled == false & concentModalStatus == false & (tlb?.tracking_link_option?.show_on_page != null || tlb?.has_optin == true)) ? (
        <SettingsEnableOPTIN
          pageNumber={pageNumber}
          tlb={tlb}
          sess_id={sess_id}
        />
      ) : ''}

      {concentModalStatus ? (
        <CommonModal
          className="req-concent"
          modalStatus={concentModalStatus}
          title={
            <img
              src="/assets/privacy@0,50x.jpg"
              style={{ height: "20vh", width: "100%", minWidth: '600px' }}
            />
          }
          classList={"concent-modal"}
        >
          {tlb?.company_name ?? ""}
          <p>
            bears the sole responsibility for the content distributed by this
            service.
          </p>
          <div className="d-flex text-center justify-content-center mt-4 Act-btn">
            <Button
              text={"Accept only essential cookies"}
              addonClass={"my-2 me-3"}
              onClick={() => {
                setConcentModalStatus(false);
                utilsUpdateLocalStorage("cookiesPrivacy", "essential");
              }}
            />
            <Button
              text={"Accept all cookies"}
              addonClass={"my-2"}
              onClick={() => {
                setConcentModalStatus(false);
                utilsUpdateLocalStorage("cookiesPrivacy", "acceptAll");
              }}
            />
          </div>
        </CommonModal>
      ) : (
        <>
          <CommonModal
            modalStatus={showLightBox != null}
            changeActiveStatus={(status) => {
              setShowLightBox(null);
              setExitDeviceLightBox(false);
              setEnterDeviceLightBox(false);
            }}
            lightBox={true}
          >
            {!!showLightBox ? <Element elements={showLightBox ?? {}} /> : ""}
          </CommonModal>
          <ToastNotification
            showToast={showToast}
            setShowToast={setShowToast}
          />

          {openRemoveModal && (
            <DeleteElementModal
              openDeleteModal={openRemoveModal}
              closeModal={setOpenRemoveModal}
              modalType={
                toolType == "LightboxesToolbox" &&
                  !editElement?.id &&
                  editLightBox?.id
                  ? "lightbox"
                  : "elemement"
              }
              elemId={
                toolType == "LightboxesToolbox" &&
                  !editElement?.id &&
                  editLightBox?.id
                  ? editLightBox?.id
                  : editElement?.id
              }
            />
          )}
          {/* addElementModal */}
          {openAddElement && (
            <AddElementModal
              openAddElementModal={openAddElement}
              closeModal={setOpenAddElement}
              docData={pdfFile}
              docFiles={docFiles}
              totalPages={pdfFile?.num_pages}
            />
          )}
          {/* lightBoxSection */}
          {openLightBox && (
            <LightBoxModal
              openLightboxModal={openLightBox}
              docFileId={pdfFile?.id}
              docId={doc?.id}
              setOpenLightBox={setOpenLightBox}
            />
          )}

          {/* clone elements in other language modal */}
          {openCloneModal && (
            <CloneElementModal
              openCloneModal={openCloneModal}
              closeModal={setOpenCloneModal}
              docFiles={docFiles}
              sourceId={selectedPdf?.id ?? pdfFile?.id}
            />
          )}

          <div
            className={`viewer-bg ${viewerBgClass} ${tlb?.cyoa_enabled == true ? "scroll_disable" : ""
              }`}
          >
            {/* editor page header */}
            {tlb?.show_toolbar !== false && (
              <EditorHeader
                editEnabled={editEnabled}
                pdfFile={pdfFile}
                setOpenSideSection={setOpenSideSection}
                openSideSection={openSideSection}
                setZoomIndex={setZoomIndex}
                zoomIndex={zoomIndex}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                docFiles={docFiles}
                setSelectedPdf={setSelectedPdf}
                title={doc?.title}
                downloadStatus={tlb?.enable_download ?? false}
                selectedPdf={selectedPdf}
                tlb={tlb ?? {}}
                editorStatus={
                  editortype == "editor" || pathname?.includes("viewer")
                }
              />
            )}
            <div className={`editor-content ${tlb?.enable_shadow_box ? 'withShadow' : ''}`}>
              {/* sidebar section */}
              {(openSideSection ||
                (editEnabled === false && tlb?.show_thumbnail === true)) && (
                  <PdfSidebar
                    pdfImages={pdfImages}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                  />
                )}
              {/* handle the pdf view component */}
              {pdfFile && (
                <Viewer
                  zoom={zoomIndex}
                  url={`${window.location.origin.toString()}${selectedPdf ? selectedPdf?.url : pdfFile?.file?.url
                    }`}
                  docFileId={pdfFile?.id}
                  docId={doc?.id}
                  editortype={editortype}
                  docFiles={docFiles}
                  timerOnDoc={doc?.timer_on_doc}
                />
              )}

              {/* handled the edit toolbox */}
              {editortype === "editor" && (
                <DraggableComponent header={<ToolBoxHeading />}>
                  <EditorToolbox
                    doc={doc}
                    pdfFile={pdfFile}
                    otherLanguageAvailable={docFiles?.length > 1}
                    setOpenCloneModal={setOpenCloneModal}
                  />
                </DraggableComponent>
              )}
            </div>
            {alertTextVisible && (
              <p className="pdf-alert-text">
                {tlb?.disable_settings?.disable_message}{" "}
                {tlb?.disable_settings?.disable_for}
              </p>
            )}
          </div>
        </>
      )}
    </EditorInfoContext.Provider>
  );
};

export default EditKdfPage;
