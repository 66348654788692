import React from "react"
import { getLanguageCode } from "../../../hooks/utils"
import { Dropdown } from "react-bootstrap"

const LanguageDropdown = ({ arrayOfElements, name, handleSelect, selectedLanguage }) => {
    return (
        <Dropdown className="flags-drop">
            <Dropdown.Toggle className="header-select-flag custom-toggle" id="dropdown-basic">
                {
                    name?.name ?
                        <div className="d-flex flag-language">
                            <img
                                height='18px'
                                width='25px'
                                src={`${assetsBasePath}/${getLanguageCode(name?.img)}.png`}
                                alt={name?.name} />
                            <p className="fs-6 ps-1">{name?.name}</p>
                        </div> :
                        selectedLanguage ? <div className="d-flex flag-language">
                            <img
                                height='18px'
                                width='25px'
                                src={`${assetsBasePath}/${getLanguageCode(selectedLanguage?.locale)}.png`}
                                alt={selectedLanguage?.name} />
                            <p className="fs-6 ps-1">{selectedLanguage?.name}</p>
                        </div> :
                            <p className="select-lng"> Select language</p>
                }
            </Dropdown.Toggle>

            <Dropdown.Menu className="custom-menu">
                {arrayOfElements?.map((elem) => {
                    return (
                        <Dropdown.Item key={elem?.id} onClick={() => handleSelect(elem)}>
                            <div className="d-flex flag-language">
                                <img
                                    height='18px'
                                    width='25px'
                                    src={`${assetsBasePath}/${getLanguageCode(elem?.language?.locale)}.png`}
                                    alt={elem?.language?.name} />
                                <span className="fs-6 ps-1">{elem?.language?.name}</span>
                            </div>
                        </Dropdown.Item>
                    )
                })}
            </Dropdown.Menu>
        </Dropdown>
    )
}
export default LanguageDropdown