import React, { useEffect, useState } from "react";
import { networkService } from "../../config/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import CustomTable from "../../CommonComponents/Table/CustomTable";
const RecepientsPage = () => {
    const [recepients, setRecepients] = useState([]);
    // const [openModals, setOpenModals] = useState(null)
    // const [orgData, setOrgData] = useState({})

    useEffect(() => {
        const fetchOrgsRecepients = async () => {
            const response = await networkService("templates/list-recipients.json", "GET");
            setRecepients([...response.data]);
        };
        fetchOrgsRecepients();
    }, []);
    console.log('>', recepients);

    const columns = React.useMemo(() => {
        return [
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'KDFs',
                accessor: 'kdfs'
            },
            {
                Header: 'Visits',
                accessor: 'visits',
            },
            {
                Header: 'Pages',
                accessor: 'pages',
            },
            {
                Header: 'Top Viewed KDFs',
                accessor: 'topViewed',
            },
            {
                Header: 'Most Viewed KDF',
                accessor: 'MoseViewed',
            },
            {
                Header: 'Actions',
                accessor: 'action',
                Cell: ({ row }) => (
                    <>
                        <FontAwesomeIcon
                            icon={faPen}
                            className="cursor-pointer"
                        // onClick={() => {
                        //     setEditTextMessageData(item);
                        //     setAccordinActiveKey(0);
                        // }}
                        />
                        <FontAwesomeIcon
                            icon={faTrashCan}
                            className='cancel cursor-pointer'
                        />
                    </>
                )
            },
        ];
    }, [recepients]);

    return (
        <>
            <div className="header-content-main">
                <div className="doc-filter">
                    <h4 className="filter-recipients mb-0">Filters</h4>
                    <form className="d-flex">
                        <div className="d-flex form-group">
                            <input className="kdf-Tags1 ms-0" type="email" placeholder="Email" />
                        </div>
                        <div className="d-flex form-group">
                            <input className="kdf-Tags1" type="text" placeholder="Name" />
                        </div>
                        <div className="d-flex form-group">
                            <input className="kdf-Tags1" type="text" placeholder="KDF Title" />
                        </div>
                        <button className="btn btn-info  reset">
                            Apply Filters
                        </button>
                        <button className="reset-btn">
                            Reset Filters
                        </button>
                    </form>
                </div>
                <div className=" doc-filter">
                    {/* <table className="Recipients-table">
                        <tr>
                            <th>Email</th>
                            <th>Name</th>
                            <th>KDFs</th>
                            <th>Visits</th>
                            <th>Pages</th>
                            <th>Top Viewed KDFs</th>
                            <th>Most Visited KDF</th>
                            <th></th>
                        </tr>
                        <tr>
                            <td>
                                nitinsaini@97200.com
                            </td>
                            <td>
                                nitin
                            </td>
                            <td>
                                2
                            </td>
                            <td>
                                2
                            </td>
                            <td>
                                43
                            </td>
                            <td>
                                Test visits stats
                            </td>
                            <td>
                                Test visits stats
                            </td>
                            <td>
                                <i className="fa-solid fa-pen me-3 rec-edit"></i>
                                <i className="fa-solid fa-trash-can rec-del"></i>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                nitinsaini@97200.com
                            </td>
                            <td>
                                nitin
                            </td>
                            <td>
                                2
                            </td>
                            <td>
                                2
                            </td>
                            <td>
                                43
                            </td>
                            <td>
                                Test visits stats
                            </td>
                            <td>
                                Test visits stats
                            </td>
                            <td>
                                <i className="fa-solid fa-pen me-3 rec-edit"></i>
                                <i className="fa-solid fa-trash-can rec-del"></i>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                nitinsaini@97200.com
                            </td>
                            <td>
                                nitin
                            </td>
                            <td>
                                2
                            </td>
                            <td>
                                2
                            </td>
                            <td>
                                43
                            </td>
                            <td>
                                Test visits stats
                            </td>
                            <td>
                                Test visits stats
                            </td>
                            <td>
                                <i className="fa-solid fa-pen me-3 rec-edit"></i>
                                <i className="fa-solid fa-trash-can rec-del"></i>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                nitinsaini@97200.com
                            </td>
                            <td>
                                nitin
                            </td>
                            <td>
                                2
                            </td>
                            <td>
                                2
                            </td>
                            <td>
                                43
                            </td>
                            <td>
                                Test visits stats
                            </td>
                            <td>
                                Test visits stats
                            </td>
                            <td>
                                <i className="fa-solid fa-pen me-3 rec-edit"></i>
                                <i className="fa-solid fa-trash-can rec-del"></i>
                            </td>
                        </tr>
                    </table> */}
                    <CustomTable data={recepients} columnData={columns} showFilters={false} />

                </div>
            </div>
        </>
    )
}
export default RecepientsPage;
