import React, { useContext, useState } from 'react';
import 'datatables.net';
import DataPerPage from '../../CommonComponents/Pagination/DataPerPage';
import SearchComponent from '../../CommonComponents/SearchComponent/SearchComponent';
import CustomTable from '../../CommonComponents/Table/CustomTable';
import AdminPagination from '../../CommonComponents/Pagination/AdminPagination';
import Button from '../../Elements/Button';
import AddCustomizationModal from './AddCustomizationModal';
import HandleUiModal from './HandleUiModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import UserInfoContext from '../../../utis/userInfoContext';

const Customizations = ({ customization }) => {
    const { profileData } = useContext(UserInfoContext);
    const [openModal, setOpenModal] = useState(false)
    const [openClickModel, setOpenClickModel] = useState(false)
    const [customizationData, setCustomizationData] = useState({})
    const [customizationId, setCustomizationId] = useState(null);


    const columns = React.useMemo(() => {
        return [
            {
                Header: 'User Name',
                accessor: 'user_name',
                Cell: () => (<>{profileData?.name}</>),
            },
            {
                Header: 'Message',
                accessor: 'message',
            },
            {
                Header: 'status',
                accessor: 'approved',
                Cell: ({ row: { original } }) => (<>{original?.approved ? 'Approved' : 'Rejected'}</>),
            },
            {
                Header: 'Action',
                accessor: 'action',
                Cell: ({ row }) => <div className='d-flex'>
                    <FontAwesomeIcon
                        className="me-2 cursor-pointer success success-green"
                        icon={faPen}
                        onClick={() => {
                            setCustomizationData(row?.original)
                            setOpenModal(true)
                        }}
                    />
                </div>,
            },

        ];
    }, [customization, profileData]);

    return (
        <>
            {openModal &&
                <AddCustomizationModal
                    openModal={openModal}
                    closeModal={setOpenModal}
                    customizationData={customizationData}
                    setCustomizationId={setCustomizationId}
                    setOpenClickModel={setOpenClickModel}
                />}
            {openClickModel &&
                <HandleUiModal
                    openModal={openClickModel}
                    closeModal={setOpenClickModel}
                    customizationId={customizationId}
                />}
            <div className="d-flex justify-content-between margin-left-10 margin-right-10 align-items-center px-3">
                <span style={{ fontWeight: 800, fontSize: '30px' }}>Consent Modal Change Requests</span>
                <Button
                    classList={'success'}
                    onClick={() => {
                        setOpenModal(true)
                    }}
                    type='button'
                    text='Request a Change'
                />
            </div>
            <div className="show-select1 d-flex mb-2">
                <div className="mb-2">
                    <DataPerPage />
                    <SearchComponent />
                </div>
            </div>
            <CustomTable data={customization} showFilters={false} columnData={columns} />
            {/* <AdminPagination /> */}
        </>
    );
}
export default Customizations;