import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const DonutChart = () => {
    const donutOptions = {
        chart: {
            type: 'pie',
        },
        title: {
            text: 'Donut Chart Example',
        },
        plotOptions: {
            pie: {
                innerSize: '50%', // Makes it a donut
                depth: 45, // Optional 3D effect
            },
        },
        series: [
            {
                name: 'Sales',
                data: [
                    { name: 'Product A', y: 25 },
                    { name: 'Product B', y: 50 },
                    { name: 'Product C', y: 25 },
                ],
            },
        ],
    };

    return (
        <>
            <HighchartsReact highcharts={Highcharts} options={donutOptions} />
        </>
    )
}
export default DonutChart