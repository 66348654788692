import React from "react";
import Breadcrumb from "../../../CommonComponents/BreadCrumb/BreadCrumb";
import Button from "../../../Elements/Button";
import { csrfToken } from "../../../../utis/csrfTokenUtils";

const AddBotPage = () => {

    return (
        <>
            <Breadcrumb string={'Admin/Potential Bots/Add'} />
            <div className="logs-form">
                <form action="/admin/potential_bots" method="post">
                    <input type="hidden" value={csrfToken()} name="authenticity_token" />
                    <input type="hidden" value={'Create Potential bot'} name="commit" />
                    <div className="form-group">
                        <label className="mb-1" htmlFor="ip">IP Address</label>
                        <div>
                            <textarea
                                id="ip"
                                name='potential_bot[ip_data]'
                                className={'ip'}
                                rows="3"
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group mb-5">
                        <div className="back-sub d-flex gap-2 justify-content-end">
                            <Button
                                classList="success"
                                text="Back"
                                onClick={() => (window.location.href = "/admin/potential_bots")}
                            />
                            <Button
                                classList="success"
                                type="submit"
                                text="Create Potential Bot"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
export default AddBotPage
