import React, { useEffect } from 'react';
import { Resizable } from 're-resizable';
import resize_right from '../.././../../../../assets/images/resize_right.svg'

function ResizableComponent({ element, setResizeDimensions, dimensions, children, resizableDimensions, keepProportions, setShowExtendModal }) {
    const updateDimensions = (width, height) => {
        setResizeDimensions({
            ...resizableDimensions,
            [element.id]: {
                ...resizableDimensions[element.id],
                width,
                height
            }
        })
    }

    useEffect(() => {
        updateDimensions(dimensions.width, dimensions.height);
    }, [dimensions.width, dimensions.height, element.id]);

    return (
        <Resizable
            size={{ width: resizableDimensions?.width, height: resizableDimensions?.height }}
            onResizeStart={(e) => {
                e.stopPropagation()
                // addEditElement()
            }}
            onResizeStop={(e, direction, ref, d) => {
                e.stopPropagation();
                setShowExtendModal(true)
                updateDimensions(resizableDimensions.width + d.width, resizableDimensions.height + d.height)
            }}
            enable={{
                right: !keepProportions,
                bottom: !keepProportions,
                bottomRight: keepProportions,
            }}
            style={{
                border: '1px solid red',
            }}
            handleComponent={{
                bottomRight: (
                    <img
                        src={resize_right}
                        alt="resize icon"
                        draggable={false}
                        onDrag={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        style={{
                            width: 20,
                            height: 20,
                            zIndex: 9999,
                            position: 'absolute',
                            top: '-8px',
                            left: '-9px'
                        }}
                    />
                ),
            }}
        >
            <div style={{
                width: `${resizableDimensions?.width - 2}px`,
                height: `${resizableDimensions?.height - 2}px`,
                overflow: 'hidden'
            }}>
                {children}
            </div>
        </Resizable >
    );
}

export default ResizableComponent;
