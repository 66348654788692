import translations from "../common/Translations";

export const getTranslation = (path) => {
    const keys = path.split('.');
    let value = translations;
    keys.forEach(key => {
        value = value[key];
    });
    return value || '';
};

export function quality_data() {
    return [
        { name: "FASTEST LOADING", value: 'better2' },
        { name: "GOOD IMAGE QUALITY", value: 'good' },
        { name: "BEST IMAGE QUALITY", value: 'best1' },
        { name: "BYPASS IMAGE OPTIMIZATION", value: 'bypass' }
    ]
}

export function animations() {
    return {
        animations: {
            attention_seekers: ["bounce", "flash", "pulse", "rubberBand", "shake", "swing", "tada", , "wobble", "jello"],
            bouncing_entrances: ["bounceIn", "bounceInDown", "bounceInLeft", "bounceInRight", "bounceInUp"],
            fading_entrances: ["fadeIn", "fadeInDown", "fadeInDownBig", "fadeInLeft", "fadeInLeftBig", "fadeInRight", "fadeInRightBig", "fadeInUp", "fadeInUpBig"],
            flippers: ["flip", "flipInX", "flipInY"],
            lightspeed: ["lightSpeedIn"],
            rotating_entrances: ["rotateIn", "rotateInDownLeft", "rotateInDownRight", "rotateInUpLeft", "rotateInUpRight",],
            sliding_entrances: ["slideInUp", "slideInDown", "slideInLeft", "slideInRight",],
            zoom_entrances: ["zoomIn", "zoomInDown", "zoomInLeft", "zoomInRight", "zoomInUp",],
            specials: ["hinge", "rollIn",]
        }
    }
}
export function disappear() {
    return {
        disappear: {
            bouncing_exits: ["bounceOut", "bounceOutDown", "bounceOutLeft", "bounceOutRight", "bounceOutUp"],
            fading_exits: ["fadeOut", "fadeOutDown", "fadeOutDownBig", "fadeOutLeft", "fadeOutLeftBig", "fadeOutRight", "fadeOutRightBig", "fadeOutUp", "fadeOutUpBig"],
            flippers: ["flipOutX", "flipOutY"],
            lightspeed: ["lightSpeedOut"],
            rotating_exits: ["rotateOut", "rotateOutDownLeft", "rotateOutDownRight", "rotateOutUpLeft", "rotateOutUpRight"],
            sliding_exits: ["slideOutUp", "slideOutDown", "slideOutLeft", "slideOutRight"],
            zoom_exits: ["zoomOut", "zoomOutDown", "zoomOutLeft", "zoomOutRight", "zoomOutUp"],
            specials: ["rollOut"]
        }
    }
}

export function entranceLoop() {
    return {
        entrance_loop: ["loop5Seconds", "loop10Seconds", "loopAnimation"]
    }
}

export const getLanguageCode = (locale) => {
    return locale.includes('-') ? locale.split('-')[0] : locale;
}

export const getObjectById = (targetId) => {
    let lightBoxes = window?.objInit?.lightBoxes
    if (lightBoxes.click) {
        for (let key in lightBoxes.click) {
            if (lightBoxes.click[key].id == targetId) {
                return lightBoxes.click[key];
            }
        }
    }

    if (lightBoxes.doc) {
        for (let key in lightBoxes.doc) {
            if (lightBoxes.doc[key].id == targetId) {
                return lightBoxes.doc[key];
            }
        }
    }

    return null;
}

export const keepProportions = (d1, d2, d3) => {
    if (0 == d3) {
        d3 = d1;
    }
    return Math.floor(d1 * d2 / d3);
};

export const calculateIntersection = (canvasRef, resizableDimensions, elemId) => {
    const canvasWidth = canvasRef?.width || 0;
    const canvasHeight = canvasRef?.height || 0;

    const intersectingLeft = Math.max(resizableDimensions?.left, 0);
    const intersectingTop = Math.max(resizableDimensions?.top, 0);
    const intersectingRight = Math.min(
        resizableDimensions?.left + resizableDimensions?.width,
        canvasWidth
    );
    const intersectingBottom = Math.min(
        resizableDimensions?.top + resizableDimensions?.height,
        canvasHeight
    );

    const isIntersecting =
        intersectingLeft < intersectingRight &&
        intersectingTop < intersectingBottom;

    if (isIntersecting) {
        const intersectingWidth = intersectingRight - intersectingLeft;
        const intersectingHeight = intersectingBottom - intersectingTop;
        const intersectingArea = intersectingWidth * intersectingHeight;

        return {
            isIntersecting: true,
            intersectingWidth,
            intersectingHeight,
            intersectingArea,
            id: elemId,
        };
    }

    return {
        isIntersecting: false,
        intersectingWidth: 0,
        intersectingHeight: 0,
        intersectingArea: 0,
        id: elemId,
    };
}

export function businessTypes() {
    return [
        { name: "Agency B2B", value: '1' },
        { name: "Agency B2C", value: '2' },
        { name: "Real Estate", value: '3' },
        { name: "Consumer Goods", value: '4' },
        { name: "Financial", value: '5' },
        { name: "Health Care", value: '6' },
        { name: "Automotive", value: '7' },
        { name: "Services", value: '8' },
        { name: "Technology", value: '9' },
        { name: "Law firms", value: '10' },
        { name: "Pharma", value: '11' },
        { name: "Alcohol", value: '12' },
        { name: "Direct Mail", value: '13' },
        { name: "Consulting", value: '14' },
    ]
}

export const categories = {
    1: 'Bug Reprt',
    2: 'Enhancement Request',
    3: 'Design / Ease of Use',
    4: 'Efficiency / Workflow Issue',
    5: 'Compatibilty (Software / Hardware )',
    6: 'Connectivity Isse',
    7: 'Domains',
    8: 'Other',
    9: 'Weather Accuracy',
    10: 'User Error',
    11: 'Content Issue',
    12: 'Artwork',
    13: 'Feature Request',
    14: 'User Experience'
}

const SubscriptionPeriod = {
    1: 'Daily',
    2: 'Weekly',
    3: 'Every 2 weeks',
    4: 'Monthly',
    5: 'Quarterly',
    6: 'Every 6 months',
    7: 'Yearly'
}

export const SubscriptionPeriodArr = Object.entries(SubscriptionPeriod).map(([key, name]) => ({
    value: Number(key),
    name,
}));

export const UserTypes = {
    1: 'Admin',
    2: 'Organization Admin',
    3: 'power User',
    4: 'Team Leader',
    5: 'Regular User',
    6: 'Sales',
}

export const userTypeArr = Object.entries(UserTypes).map(([key, name]) => ({
    value: Number(key),
    name,
}))