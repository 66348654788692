import React from "react";
import styles from './errorLaserLINK.module.css';
const ErrorLaserLINK = () => {
    return(
        <div className={styles.wrapper}>
            <div className={styles.errorCard}>
                <h1 className={styles.errorTitle} >Document Unavailable</h1>
                <img src="/assets/doc-unavailable.png" className={styles.errorImage} alt="Document Unavailable" />
                <h3 className={styles.errorSubTitle}>This document is no longer available. Please contact the person who sent you the link.</h3>
            </div>
        </div>
    )
}
export default ErrorLaserLINK;
