import React from "react"
import CommonModal from "../CommonModal";

const DeleteOrgModal = ({ openModal, closeModal, name, handleDelete }) => {
    return (<>
        <CommonModal
            title={"Delete Organisation"}
            modalStatus={openModal}
            changeActiveStatus={(status) => closeModal()}
            classList="header-profile-modal-wrapper alert-modal"
            centered
        >
            <div>
                <p className="mb-2">Are you sure you want to delete {name ?? 'name'} ?</p>
                <div className="kdf-btn mt-3">
                    <button className="continue-btn"
                        onClick={() => handleDelete()}
                    >
                        Yes
                    </button>
                </div>
            </div>
        </CommonModal>
    </>)
}
export default DeleteOrgModal