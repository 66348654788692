import React, { useContext, useEffect, useState } from "react";
import CustomTable from "../../../CommonComponents/Table/CustomTable";
import CommonModal from "../../../Modals/CommonModal";
import apiGETRequest from "../../../../utis/apiRequest";
import { csrfToken } from "../../../../utis/csrfTokenUtils";
import customAPIRequest from "../../../../utis/customAPIRequest";
import Button from "../../../Elements/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleUser, faPen, faTrashCan, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { networkService } from "../../../config/axios";
import { getLanguageCode, UserTypes } from "../../../../hooks/utils";
import CustomTooltip from "../../../Elements/Tooltip";
import UserInfoContext from "../../../../utis/userInfoContext";

function buildQuery(obj, prefix) {
    const queryString = Object.keys(obj).map(key => {
        const value = obj[key];
        const fullKey = prefix ? `${prefix}[${key}]` : key;

        if (value !== null && typeof value === 'object') {
            return buildQuery(value, fullKey);
        } else {
            return `${encodeURIComponent(fullKey)}=${encodeURIComponent(value)}`;
        }
    });

    return queryString.join('&');
}

const UsersListing = ({ showCheckbox = true, setData, companyId, type }) => {
    const { setShowNotifications } = useContext(UserInfoContext)
    const [users, setUsers] = useState({});
    const [refetch, setRefetch] = useState(true);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const getUsers = async (companyId) => {
        const params = {
            start: 0,
            length: 10,
            draw: 1,
            search: { value: '', regex: false },
            _: Date.now()
        };
        if (type == 'userManagement' && companyId) {
            const userLst = await networkService(`admin/company-users/${companyId}.json`, 'GET')
            delete userLst?.data?.users_length;
            setUsers([...Object.values(userLst?.data)])
        } else if (type == 'bannedUser' && companyId) {
            const userList = await networkService(`admin/banned_users/${companyId}.json`, 'GET')
            setUsers(userList?.data?.users)
        }
        else {
            const queryString = buildQuery(params);
            const users = await apiGETRequest(`admin/users_listing?${queryString}`);
            setUsers([...users?.data]);
        }
    }

    const handleDelete = async () => {
        try {
            const response = await networkService(`admin/users/${selectedUser.id}.json`, 'DELETE');
            if (response.status == 204) {
                setSelectedUser(null);
                setDeleteModal(false);
                setRefetch(true);
                setShowNotifications({
                    showToast: true,
                    toastType: "success",
                    toastMessage: "user has been banned",
                })
            }
        } catch (error) {
            setShowNotifications({
                showToast: true,
                toastType: 'danger',
                toastMessage: "Something went wrong"
            })
        }
    }

    const impersonateUser = async (id) => {
        const formData = new FormData();
        formData.append('_method', 'post');
        formData.append('authenticity_token', csrfToken());
        try {
            const response = await fetch(`/admin/impersonate/${id}`, {
                method: 'POST',
                body: formData,
                cookie: {
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                },
            });
            if (response?.ok) {
                window.location.href = '/';
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    const handlePermit = async (user) => {
        const data = {
            authenticity_token: csrfToken(),
            id: user?.id,
            user: {
                company_id: user?.company_id,
                email: user?.email,
                user_type: user?.user_type,
                banned: false,
            }
        }
        try {
            const response = await networkService(`admin/users/${user?.id}.json`, 'PATCH', data);
            if (response.status == 200) {
                setRefetch(true);
                setShowNotifications({
                    showToast: true,
                    toastType: "success",
                    toastMessage: "User account is now active again!",
                })
            }
        } catch (error) {
            setShowNotifications({
                showToast: true,
                toastType: 'danger',
                toastMessage: "Something went wrong"
            })
        }
    }

    useEffect(() => {
        if (refetch) {
            getUsers(companyId);
            setRefetch(false);
        }
    }, [refetch, companyId]);

    const columns = React.useMemo(() => {
        return [
            ...(showCheckbox ? [{
                Header: () => (
                    <input type="checkbox" />
                ),
                id: "selection",
                Cell: ({ row }) => (
                    <input
                        className="cursor-pointer"
                        type="checkbox"
                    // {...row.getToggleRowSelectedProps()}
                    />
                ),
            }] : []),
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            ...(!showCheckbox ? [{
                Header: 'User Type',
                accessor: 'type_name',
                Cell: ({ row: { original } }) => (<>{UserTypes[original?.user_type ?? original?.current_user_type]}{original?.type_user}</>)
            }] : []),
            {
                Header: 'Last Login',
                accessor: 'last_login',
            },
            {
                Header: 'Country',
                accessor: 'country',
                Cell: ({ row: { original } }) => (<>{
                    original?.country?.iso_code ?
                        <img
                            width='22px'
                            src={`${assetsBasePath}/${getLanguageCode(original?.country?.iso_code?.toLowercase())}.png`}
                            alt={original?.country?.iso_code}
                        /> :
                        <></>
                }
                </>)
            },
            ...(type !== 'bannedUser' ? [{
                Header: 'PP Acceptance on',
                accessor: 'privacy_accepted_date',
                Cell: ({ row }) => (
                    <>
                        <CustomTooltip
                            title={row?.original?.privacy_accepted_date_tooltip ?? "rrrrrrrrr"}
                            placement={"top"}
                        >
                            <>{row?.original?.privacy_accepted_date}</>
                        </CustomTooltip>
                    </>
                ),
            }] : []),
            ...(type !== 'bannedUser' ? [{
                Header: 'ToS Acceptance on',
                accessor: 'tos_accepted_date',
                Cell: ({ row }) => (
                    <>
                        <CustomTooltip
                            title={row?.original?.tos_accepted_date_tooltip ?? ""}
                            placement={"top"}
                        >
                            <>{row?.original?.tos_accepted_date}</>
                        </CustomTooltip>
                    </>
                ),
            }] : []),
            {
                Header: 'Action',
                accessor: 'action',
                Cell: ({ row }) => (
                    <>
                        {type == 'bannedUser' ?
                            <Button
                                text={'Permit'}
                                classList={'success'}
                                onClick={() => handlePermit(row?.original)}
                            /> :
                            <>
                                {
                                    !showCheckbox &&
                                    <FontAwesomeIcon
                                        className="me-2 cursor-pointer manage-edit"
                                        icon={faPen}
                                        onClick={() => {
                                            setData(row?.original)
                                        }}
                                    />
                                }
                                <FontAwesomeIcon
                                    onClick={() => impersonateUser(row?.original?.id)}
                                    icon={faUserGroup}
                                    className="me-1 cursor-pointer"
                                />
                                <FontAwesomeIcon
                                    icon={faTrashCan}
                                    className="cursor-pointer cancel"
                                    onClick={() => {
                                        setDeleteModal(true);
                                        setSelectedUser(row?.original);
                                    }}
                                />
                                {
                                    row?.original?.is_online ?
                                        <FontAwesomeIcon icon={faCircle} className="ms-2 user-active" /> :
                                        <></>
                                }
                            </>}
                    </>
                )
            },
        ];
    }, [users]);

    return (
        <>
            {<CustomTable data={users?.length > 0 ? users : []} columnData={columns} showFilters={showCheckbox} />}
            <CommonModal
                title="Delete User"
                modalStatus={deleteModal}
                changeActiveStatus={() => { setDeleteModal(false) }}
                centered
                classList={'upload-kdf-modal-wrapper alert-modal'}
                footer={
                    <div className="d-flex m-auto">
                        <Button text={'No'} classList={'alert delete-btn'} addonClass={'my-2 mx-2'} onClick={() => { setDeleteModal(false) }} />
                        <Button text={'Yes'} classList={'success delete-btn'} addonClass={'my-2 mx-2'} onClick={handleDelete} />
                    </div>
                }
            >
                <p style={{ fontSize: '14px' }}>
                    Are you sure you want to delete {selectedUser?.name || selectedUser?.email}
                </p>
            </CommonModal>
        </>
    )
}
export default UsersListing