import React, { useContext, useState } from "react";
import Breadcrumb from "../../../CommonComponents/BreadCrumb/BreadCrumb";
import CustomTable from "../../../CommonComponents/Table/CustomTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import DataPerPage from "../../../CommonComponents/Pagination/DataPerPage";
import SearchComponent from "../../../CommonComponents/SearchComponent/SearchComponent";
import Button from "../../../Elements/Button";
import { networkService } from "../../../config/axios";
import DeleteOrgModal from "../../../Modals/Admin/DeleteOrgModal";
import UserInfoContext from "../../../../utis/userInfoContext";

const AdminPotentialBotsPage = (props) => {
    const { setShowNotifications } = useContext(UserInfoContext)

    const { potential_bots } = props
    const [potentialId, setPotentialId] = useState(null)

    const handleDeleteBot = (id) => {
        const url = `admin/potential_bots/${id}.json`;
        networkService(url, "DELETE")
            .then((response) => {
                if (response.status = 204) {
                    setPotentialId(null)
                    setShowNotifications({
                        showToast: true,
                        toastType: "success",
                        toastMessage: "Potential bot deleted",
                    })
                }
            })
            .catch((error) => {
                setShowNotifications({
                    showToast: true,
                    toastType: 'danger',
                    toastMessage: "Something went wrong"
                })
            });
    }

    const columns = React.useMemo(() => {
        return [
            {
                Header: 'IP Address',
                accessor: row => row?.ip_data?.ip_address,
                Cell: ({ value }) => (value ? value : 'No IP Address'),
            },
            {
                Header: 'Users',
                accessor: 'users',
                Cell: ({ row: { original } }) => {
                    const userName = original?.ip_data?.user_id[0]?.name;
                    return <>{userName ?? 'User Name'}</>
                },
            },
            {
                Header: 'Counts',
                accessor: row => row?.ip_data?.count
            },
            {
                Header: 'Confirm',
                accessor: 'confirm',
                Cell: () => ('Confirmed'),
            },
            {
                Header: 'Reject',
                accessor: 'reject',
                Cell: ({ row }) => (
                    <div
                        className="cursor-pointer"
                        onClick={() => setPotentialId(row?.original?.id)}>
                        Reject
                    </div>
                )
            },
            {
                Header: 'Actions',
                accessor: 'action',
                Cell: ({ row }) => (
                    <>
                        <FontAwesomeIcon
                            icon={faTrashCan}
                            className='cancel cursor-pointer'
                            onClick={() => setPotentialId(row?.original?.id)}
                        />
                    </>
                )
            },
        ];
    }, [potential_bots]);

    return (
        <>
            <DeleteOrgModal
                openModal={potentialId !== null}
                closeModal={setPotentialId}
                name={'Potential bot'}
                handleDelete={() => handleDeleteBot(potentialId)}
            />
            <Breadcrumb string={'Admin/Potential Bots'} />
            <div className="container">
                <div className="show-select1 d-flex mb-2">
                    <div className="mb-2">
                        <DataPerPage />
                        <SearchComponent />
                    </div>
                    <Button
                        classList='success'
                        text={"Add Bot"}
                        onClick={() => window.location.href = "/admin/potential_bots/new"}
                        type='button'
                    />
                </div>
                <div className="top-parent">
                    <CustomTable data={potential_bots} columnData={columns} />
                </div>
                <div className="showing-main d-flex justify-content-between mt-3 mb-5">
                    <div className="showing-para">
                        <p>Showing 1 to 2 of 2 entries</p>
                    </div>
                    <div>
                        <button className="previous-btn me-2">Previous</button>
                        <span className="count-btn me-2">1</span>
                        <button className="previous-btn">Next</button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminPotentialBotsPage;
