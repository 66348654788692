import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import styles from "./StatsFilter.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";

const StatsFilters = ({ threshold, onFilterChange }) => {
  const [filters, setFilters] = useState({
    email: "",
    location: "",
    seen_pages: "",
    seen_mins: "",
    on_page: "",
    from_date: "",
    to_date: "",
    currentSort: "",
    recipient: ""
  });

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  // Handle Apply Filters
  const handleApplyFilters = () => {
    if (onFilterChange) {
      onFilterChange(filters); // Notify parent with updated filters
    }
  };

  // Handle Reset Filters
  const handleResetFilters = () => {
    const defaultFilters = {
      email: "",
      location: "",
      seen_pages: "",
      seen_mins: "",
      from_date: "",
      to_date: "",
      currentSort: "",
      recipient: ""
    };
    setFilters(defaultFilters); // Reset state
    if (onFilterChange) {
      onFilterChange(defaultFilters, 'reset'); // Notify parent with reset filters
    }
  };

  return (
    <Form className={`${styles.filterForm} p-3`}>
      <Row className={`align-items-center`}>
        <Col md={3} className={styles.borderRight}>
          <Form.Group>
            <Form.Control
              className={styles.customInput}
              type="email"
              name="email"
              placeholder="Email"
              value={filters.email}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Control
              className={styles.customInput}
              type="text"
              name="location"
              placeholder="Location: Country, City, State, Town"
              value={filters.location}
              onChange={handleInputChange}
            />
          </Form.Group>
          <div className="mt-3">
            <Button
              className={`${styles.customBtn} me-2`}
              onClick={handleApplyFilters}
            >
              Apply Filters
            </Button>
            <Button
              className={`${styles.customBtn} ${styles.secondary}`}
              onClick={handleResetFilters}
            >
              Reset Filters
            </Button>
          </div>
        </Col>

        <Col md={3} className={`${styles.borderRight}`}>
            <div className="d-flex">
                <span className={styles.labelText}>At least</span>
                <Form.Control
                    className={`${styles.customInput} me-2`}
                    type="number"
                    name="seen_pages"
                    placeholder=""
                    value={filters.seen_pages}
                    onChange={handleInputChange}
                />
                <span className={styles.labelText}>pages viewed</span>
            </div>
            <div className="d-flex">
                <span className={styles.labelText}>At least</span>
                <Form.Control
                    className={`${styles.customInput} me-2`}
                    type="number"
                    name="seen_mins"
                    placeholder=""
                    value={filters.seen_mins}
                    onChange={handleInputChange}
                />
                <span className={styles.labelText}>Mins</span>
                <Form.Control
                    className={`${styles.customInput} me-2`}
                    type="number"
                    name="on_page"
                    placeholder=""
                    value={filters.on_page}
                    onChange={handleInputChange}
                />
            </div>
        </Col>

        <Col md={2} className={styles.borderRight}>
          <Form.Group className="mb-2">
            <Form.Control
              className={`${styles.customInput} w-100`}
              type="date"
              name="from_date"
              value={filters.from_date}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              className={`${styles.customInput} w-100`}
              type="date"
              name="to_date"
              value={filters.to_date}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>

        <Col md={3}>
          <Form.Group>
            <Form.Control
              className={styles.customInput}
              type="text"
              name="recipient"
              placeholder="Recipient Name"
              value={filters.recipient}
              onChange={handleInputChange}
            />
            {/* <DropdownButton
                id="dropdown-basic-button"
                title={filters.currentSort}
                onSelect={(eKey) => handleInputChange({'target':{'name':currentSort, 'value':eKey}})}
                variant="secondary"
            >
                <Dropdown.Item eventKey="First name, Last name">
                    <FontAwesomeIcon icon={faArrowUp} /> First name, Last name
                </Dropdown.Item>
                <Dropdown.Item eventKey="First name, Last name Desc">
                    <FontAwesomeIcon icon={faArrowDown} /> First name, Last name
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="Last name, First name">
                    <FontAwesomeIcon icon={faArrowUp} /> Last name, First name
                </Dropdown.Item>
                <Dropdown.Item eventKey="Last name, First name Desc">
                    <FontAwesomeIcon icon={faArrowDown} /> Last name, First name
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="Email">
                    <FontAwesomeIcon icon={faArrowUp} /> Email
                </Dropdown.Item>
                <Dropdown.Item eventKey="Email Desc">
                    <FontAwesomeIcon icon={faArrowDown} /> Email
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="Percent viewed">
                    <FontAwesomeIcon icon={faArrowUp} /> Percent viewed
                </Dropdown.Item>
                <Dropdown.Item eventKey="Percent viewed Desc">
                    <FontAwesomeIcon icon={faArrowDown} /> Percent viewed
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="Time Viewed">
                    <FontAwesomeIcon icon={faArrowUp} /> Time Viewed
                </Dropdown.Item>
                <Dropdown.Item eventKey="Time Viewed Desc">
                    <FontAwesomeIcon icon={faArrowDown} /> Time Viewed
                </Dropdown.Item>
            </DropdownButton> */}
          </Form.Group>
          <div className="mt-3">
            <span className={styles.labelText}>Engagement Threshold:</span>{" "}
            <strong>{threshold || '-'} seconds </strong>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default StatsFilters;
