import React, { useContext, useState } from "react";
import CommonModal from "../CommonModal";
import { networkService } from "../../config/axios";
import Button from "../../Elements/Button";
import { csrfToken } from "../../../utis/csrfTokenUtils";
import { EditorInfoContext } from "../../../utis/userInfoContext";

const ExtendBeyondModal = ({ openModal, closeModal, id, resizableDimensions, previousDimensions, setResizeDimensions }) => {
    const { setShowToast, elements, setElements } = useContext(EditorInfoContext)

    const handleSave = async () => {
        let data = {
            ...elements[id],
            authenticity_token: csrfToken(),
            geometry: {
                ...resizableDimensions,
                hide_beyond_page_edges: elements[id].geometry.hide_beyond_page_edges,
                keep_proportions: elements[id].geometry.keep_proportions,
                keep_within_page: false,
            },
        }
        const url = `/docs/elements/${id}.json`;
        await networkService(url, "PATCH", data)
            .then((response) => {
                if (response.status == 200) {
                    setElements({ ...elements, [response?.data?.id]: response?.data })
                    setShowToast({
                        showToast: true,
                        toastType: 'success',
                        toastMessage: "Changes saved"
                    })
                }
            })
            .catch((error) => {
                setShowToast({
                    showToast: true,
                    toastType: 'danger',
                    toastMessage: "Something went wrong"
                })
            });
        closeModal(false)
    }

    const handleCancel = () => {
        closeModal(false)
        setResizeDimensions(prevDimensions => {
            return {
                ...prevDimensions,
                [id]: {
                    ...prevDimensions[id],
                    ...previousDimensions.current
                }
            }
        })
    }

    return (
        <CommonModal
            title={""}
            modalStatus={openModal}
            changeActiveStatus={closeModal}
            classList="header-profile-modal-wrapper alert-modal"
            centered
        >
            <div className="clone-modal">
                <div className="mb-2">Click Yes to allow this element to extend beyond the document borders</div>
                <div className="kdf-btn mt-3">
                    <Button
                        text={'No'}
                        classList={"alert"}
                        addonClass={'me-3'}
                        onClick={() => handleCancel()}
                    />
                    <Button
                        text={'Yes'}
                        classList={"success"}
                        onClick={() => handleSave()}
                    />
                </div>
            </div>
        </CommonModal>
    )
}
export default ExtendBeyondModal