import React from 'react';
import Table from 'react-bootstrap/Table';
import styles from './StatsTableView.module.css';

const StatsTableView = ({ columns, data, renderActions }) => {
  return (
    <div className={styles.tableContainer}>
      <Table striped bordered hover responsive className={styles.customTable}>
        <thead>
          <tr>
            {columns.map((col) => (
              <th key={col.key}>{col.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              {columns.map((col) => (
                <td key={`${col.key}-${index}`}>
                  {col.render ? col.render(row[col.key], row) : row[col.key]}
                </td>
              ))}
              {renderActions && <td>{renderActions(row)}</td>}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default StatsTableView;
