import React, { useContext, useState } from "react";
import CommonModal from "../CommonModal";
import { networkService } from "../../config/axios";
import Button from "../../Elements/Button";
import { csrfToken } from "../../../utis/csrfTokenUtils";
import LanguageDropdown from "../../CommonComponents/LanguageDropdown/LanguageDropdown";
import { EditorInfoContext } from "../../../utis/userInfoContext";

const CloneElementModal = ({ openCloneModal, closeModal, sourceId, docFiles }) => {
    const { setShowToast } = useContext(EditorInfoContext)
    const [selectedId, setSelectedId] = useState('')
    const [LangName, setLangName] = useState({
        img: '',
        name: ''
    })

    const handleSelect = (value) => {
        setLangName({
            img: value?.language?.locale,
            name: value?.language?.name
        })
        setSelectedId(value?.id)
    }

    const handleCloneElements = async () => {
        let payload = {
            authenticity_token: csrfToken(),
            source_id: sourceId,
            target_id: Number(selectedId)
        }
        const res = await networkService(`editor/copy-to-lang.json`, 'POST', payload);
        if (res.status == 204) {
            setShowToast({
                showToast: true,
                toastType: 'success',
                toastMessage: "Cloned element"
            })
        } else {
            setShowToast({
                showToast: true,
                toastType: 'danger',
                toastMessage: "Something went wrong"
            })
        }
        closeModal(false)
    }

    return (
        <CommonModal
            title={"Copy elements"}
            modalStatus={openCloneModal}
            changeActiveStatus={closeModal}
            classList="header-profile-modal-wrapper alert-modal"
            centered
        >
            <div className="clone-modal">
                <div className="mb-2">Copy elements from {docFiles?.find((elem) => elem.id === sourceId)?.language?.name} to </div>
                <LanguageDropdown
                    arrayOfElements={docFiles}
                    name={LangName}
                    handleSelect={handleSelect}
                />
                <div className="kdf-btn mt-3">
                    <Button
                        text={'Cancel'}
                        classList={"alert"}
                        addonClass={'me-3'}
                        onClick={() => closeModal(false)}
                    />
                    <Button
                        text={'Clone'}
                        classList={"success"}
                        onClick={() => handleCloneElements()}
                    />
                </div>
            </div>
        </CommonModal>
    )
}
export default CloneElementModal