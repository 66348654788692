import React from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import './tooltip.css';

const CustomTooltip = ({children, title, placement = null, light = null, ...rest}) => {
    return(
        <OverlayTrigger
            placement={placement == null ? 'top' : placement}
            delay={{ show: 250, hide: 400 }}
            overlay={
                <Tooltip id="custom-tooltip" className={`custom-tooltip ${light != null ? 'lightBackground' : ''}`}>
                    {title}
                </Tooltip>
            }
            {...rest}
            >
            {children}
        </OverlayTrigger>
    );
}
export default CustomTooltip;