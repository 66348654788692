import React, { useContext, useEffect, useRef, useState } from "react"
import { EditorInfoContext } from "../../../../../../../utis/userInfoContext"
import KdfActionModal from "../../../../../../Modals/EditorModals/KdfActionModal"
import { networkService } from "../../../../../../config/axios"
const ClickActionSection = ({ tool, handleChange, totalPages, currentDocId, setTool }) => {
    const [openKdfModal, setOpenKdfModal] = useState(false)
    const [kdfsOptions, setKdfsOptions] = useState([])
    const [clickOption, setClickOption] = useState([])
    const { elements } = useContext(EditorInfoContext)
    const arrayOfElements = Object.values(Object.entries(elements).length !== 0 && elements);
    const hasOpenedModalRef = useRef(false);

    useEffect(() => {
        if (tool?.action === 'kdf' && !hasOpenedModalRef.current) {
            setOpenKdfModal(true)
            hasOpenedModalRef.current = true;
        }
    }, [tool])

    useEffect(() => {
        if (window?.objInit?.lightBoxes?.click && Object.keys(window?.objInit?.lightBoxes?.click).length > 0) {
            setClickOption(Object.values(window?.objInit?.lightBoxes?.click));
        }
    }, [])

    useEffect(() => {
        const fetchKdfs = async () => {
            const response = await networkService(`kdfs.json?title=${tool?.kdf}`, 'GET');
            setKdfsOptions(response?.data?.filter((val) => val?.id !== currentDocId &&
                val.tracking_link !== null &&
                val.tracking_link.kdf_action_link))
        }
        if (tool?.value?.kdf) {
            fetchKdfs();
        }
    }, [tool])

    const linkToKdf = (kdf) => {
        setTool({
            ...tool,
            value: {
                kdf: kdf?.id,
                link_title: kdf?.tracking_link?.name,
                link: kdf?.tracking_link?.id,
                url: `${kdf?.tracking_link?.domain}/${kdf?.group_id}/${kdf?.tracking_link?.language}`,
                timerField: "ViewPort"
            }
        })
    }

    return (
        <>
            {
                <KdfActionModal
                    openKdfModal={openKdfModal}
                    closeModal={setOpenKdfModal}
                />
            }
            <div className="animation-select  viewport-main">
                <h5>
                    ACTIONS (WHEN CLICKING ON ELEMENT )
                </h5>
                <div className="d-flex viewport-main mt-2">
                    <div className="element-actions__option mt-2 d-flex align-items-center">
                        <input
                            type="radio"
                            name='action'
                            value='none'
                            checked={tool?.action === 'none'}
                            onChange={handleChange}
                        />
                        <label htmlFor="no-action">No action</label>
                    </div>

                    <div className="element-actions__option mt-2 d-flex align-items-center">
                        <input
                            type="checkbox"
                            name="scrolling"
                        />
                        <label htmlFor="reactivate-scrolling">Reactivate Scrolling</label>
                    </div>
                </div>
                <div>
                </div>
                <div className="d-flex justify-content-between">
                    <div className="element-actions__option mt-3 ">
                        <div className="d-flex mb-3">
                            <input
                                type="radio"
                                name='action'
                                value='desiredaction'
                                checked={tool?.action === 'desiredaction' || !!tool?.dropdown_data}
                                onChange={handleChange}
                            />
                            <label htmlFor="hide-reveal">Hide / Reveal<span title="Tooltip content">?</span></label>
                        </div>
                        <select className="animation-selector"
                            name="desiredaction"
                            onChange={handleChange}
                            defaultValue={"hide"}
                        >
                            <option value=""></option>
                            <option value="hide" defaultValue={tool?.dropdown_data?.desiredaction == 'hide'}>Hide</option>
                            <option value="reveal" defaultValue={tool?.dropdown_data?.desiredaction == 'reveal'}>Reveal</option>
                            <option value="toggleOpacity" defaultValue={tool?.dropdown_data?.desiredaction == 'toggleOpacity'}>Toggle Opacity</option>
                        </select>
                    </div>
                    <div className="element-actions__option mt-3 " >
                        <div className="d-flex mb-3">
                            <label>Target Element</label>
                        </div>
                        <select
                            className="animation-selector"
                            name='thisElement'
                            onChange={handleChange}>
                            <option value=""></option>
                            <option value="thisElement">{"P" + tool?.page} This Element</option>
                            {
                                arrayOfElements?.map((element, index) => (
                                    <option value={element?.id} key={index} defaultValue={element?.id == tool?.dropdown_data?.thisElement}>
                                        {"P" + (element.page) + " " + (element.element_type_name) + " " + (element.id)}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="element-actions__option mt-3 ">
                        <div className="d-flex mb-3">
                            <label htmlFor="hide-reveal">Frequency <span title="Tooltip content">?</span></label>
                        </div>
                        <select
                            className="animation-selector"
                            name='frequency_animation'
                            onChange={handleChange}
                        >
                            <option value=""></option>
                            <option value="oncePerKDF" defaultValue={tool?.dropdown_data?.frequency == 'oncePerKDF'}>Once per KDF</option>
                            <option value="oncePerLaserLINK" defaultValue={tool?.dropdown_data?.frequency == 'oncePerLaserLINK'}>Once per LaserLINK</option>
                            <option value="oncePerVisit" defaultValue={tool?.dropdown_data?.frequency == 'oncePerVisit'}>Once per Visit</option>
                            <option value="OnEveryClick" defaultValue={tool?.dropdown_data?.frequency == 'OnEveryClick'}>On Every click</option>
                        </select>
                    </div>
                    {/* desiredActionLightBoxDropdown */}
                </div>
                <div className="element-actions__option d-flex align-items-center mt-2">
                    <input
                        type="radio"
                        name='action'
                        value='page'
                        checked={tool?.action === 'page'}
                        onChange={handleChange}
                    />
                    <label>Jump to page:</label>
                    <input
                        type="number"
                        name='jump_to_page'
                        value={tool?.jump_to_page ?? tool?.value?.page_link}
                        onChange={handleChange}
                        min="1"
                    />
                    <span> / {totalPages}</span>
                </div>
                <div className="element-actions__option d-flex align-items-center mt-2">
                    <input
                        type="radio"
                        onChange={handleChange}
                        name='action'
                        value='href'
                        checked={tool?.action === 'href'}
                    />
                    <label>Link to:</label>
                    <input
                        className="url-inp"
                        value={tool?.href ?? tool?.value?.url}
                        onChange={handleChange}
                        name="href"
                        placeholder="Enter URL,E-Mail or Phone"
                    />
                </div>
                <div className="element-actions__option d-flex align-items-center mt-2">
                    <input
                        type="radio"
                        name='action'
                        onChange={handleChange}
                    />
                    <label>Reveal ClickPop:</label>
                    <select className="element-actions-select"
                        name="light_box"
                        disabled={clickOption.length < 1}
                        value={tool?.value?.light_box}
                        onChange={handleChange}
                    >
                        <option value="">Select a ClickPop lightbox</option>
                        {clickOption?.map((click) => (
                            <option key={click?.id} value={click?.id}>
                                {click?.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="element-actions__option d-flex align-items-center mt-2">
                    <input
                        type="radio"
                        name='action'
                        value='kdf'
                        checked={tool?.action === 'kdf'}
                        onChange={handleChange}
                    />
                    <label>Link to KDF:</label>
                    <input
                        className="url-inp"
                        placeholder="Enter URL, E-Mail or Phone"
                        name="kdf"
                        value={tool?.kdf}
                        onChange={handleChange}
                    />
                </div>
                {
                    kdfsOptions?.map((elem) => {
                        return (
                            <div>
                                <div className='border border-priamry p-2' onClick={() => linkToKdf(elem)}>
                                    {elem?.title}
                                </div>
                                <div className='border border-priamry p-2' onClick={() => linkToKdf(elem)}>
                                    {elem?.title}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}
export default ClickActionSection