import React, { useEffect, useState } from "react";
import {
  faCaretLeft,
  faCaretRight,
  faCircleInfo,
  faCircleMinus,
  faCirclePlus,
  faDownload,
  faEllipsisVertical,
  faImagePortrait,
  faImages,
  faLessThan,
  faShareFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LanguageDropdown from "../../../../CommonComponents/LanguageDropdown/LanguageDropdown";
import CustomTooltip from "../../../../Elements/Tooltip";
import portrait from "../../../../../../assets/images/portrait.png";
import landscape from "../../../../../../assets/images/landscape.png";

const EditorHeader = ({
  pdfFile,
  setOpenSideSection,
  openSideSection,
  setZoomIndex,
  zoomIndex,
  pageNumber,
  setPageNumber,
  docFiles,
  setSelectedPdf,
  title,
  editEnabled,
  downloadStatus,
  selectedPdf,
  tlb,
  editorStatus,
}) => {
  const [LangName, setLangName] = useState({
    img: "",
    name: "",
  });
  const [searchValue, setSearchValue] = useState('')
  const zoomingOptions = window.objInit?.zoomOptions;
  const arrayOfElements = Object.values(
    Object.entries(docFiles).length !== 0 && docFiles
  );
  const selectedLanguage = arrayOfElements?.find(
    (elem) => elem.id == pdfFile?.id
  );

  const handleZoom = (option) => {
    if (option === "in" && zoomIndex.index < 8) {
      setZoomIndex({
        ...zoomIndex,
        index: zoomIndex?.index + 1,
        value:
          zoomingOptions[Object.keys(zoomingOptions)[zoomIndex?.index + 1]]
            .value,
      });
    }
    if (option === "out" && zoomIndex.index > 0 && zoomIndex.index < 9) {
      setZoomIndex({
        ...zoomIndex,
        index: zoomIndex?.index - 1,
        value:
          zoomingOptions[Object.keys(zoomingOptions)[zoomIndex?.index - 1]]
            .value,
      });
    }
    if (option == "landscape") {
      setZoomIndex({
        ...zoomIndex,
        index: 6,
        value: "2",
      });
    }
    if (option == "portrait") {
      setZoomIndex({
        ...zoomIndex,
        index: 1,
        value: "0.5",
      });
    }
  };

  const handleSelect = (value) => {
    setLangName({
      img: value?.language?.locale,
      name: value?.language?.name,
    });
    setSelectedPdf(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const num = parseInt(e.target.value, 10);
      setPageNumber(num);
      e.preventDefault();
    }
  };

  const handlesearchKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      window?.find(searchValue)
    }
  }

  const handleIconClick = (e) => {
    if (e?._reactName == "onClick") {
      e.preventDefault()
      window.find(searchValue);
    }
  };

  return (
    <header className="header-editor">
      <div className="h-first">
        <div className="d-flex align-items-center">
          {editorStatus && (
            <div className="">
              <button
                className="back-button"
                onClick={() => (window.location = "/kdfs")}
              >
                <FontAwesomeIcon className="me-1" icon={faLessThan} />
                KDFs
              </button>
            </div>
          )}
          {tlb?.cyoa_enabled != true && (
            <>
              <CustomTooltip title={"Toggle Sidebar"} placement={"bottom"}>
                <div
                  className="ms-2 three-dots"
                  onClick={() => setOpenSideSection(!openSideSection)}
                >
                  <FontAwesomeIcon icon={faEllipsisVertical} className="fs-4" />
                </div>
              </CustomTooltip>
              <div className="d-flex align-items-center ms-3 editor-inp me-3">
                <input
                  className="edit-value-inp ms-1 me-1"
                  id="pageNumber"
                  defaultValue={1}
                  min={1}
                  max={pdfFile?.num_pages}
                  onKeyDown={handleKeyDown}
                />
                <p>/ {pdfFile?.num_pages}</p>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="title d-flex align-items-center justify-content-between">
        {tlb?.cyoa_enabled != true && (
          <div className="h-first-search align-items-center">
            <i className="fa-solid fa-magnifying-glass ms-2"></i>
            <i className="fa-solid fa-circle-check ms-2"></i>
            <input
              className="h-first-inp ms-2 me-2 text-light"
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target?.value)}
              onKeyDown={handlesearchKeyDown}
            />
            <button
              onClick={() => window.find(searchValue, false, true)}
              className="icon-button"
            >
              <FontAwesomeIcon
                icon={faCaretLeft}
                className="fs-4 cursor-pointer text-light"
              />
            </button>
            <button
              className="icon-button"
              onClick={() => window.find(searchValue)}>
              <FontAwesomeIcon
                icon={faCaretRight}
                className="fs-4 cursor-pointer text-light"
              />
            </button>
          </div>
        )}
        <p className="fs-4 text-ellipsis text-light">
          {title?.substring(0, 40)} {title.length > 40 ? "..." : ""}
        </p>
        <div>
          <CustomTooltip title={"Share"} placement={"bottom"}>
            <FontAwesomeIcon className="fs-5" icon={faShareFromSquare} />
          </CustomTooltip>
        </div>
      </div>
      <div className="icons-editor ms-2">
        <CustomTooltip title={"Zoom In"} placement={"bottom"}>
          <FontAwesomeIcon
            icon={faCirclePlus}
            className="fs-4 me-2 cursor-pointer"
            onClick={() => handleZoom("in")}
            id="zoomIn"
          />
        </CustomTooltip>
        <CustomTooltip title={"Zoom Out"} placement={"bottom"}>
          <FontAwesomeIcon
            icon={faCircleMinus}
            onClick={() => handleZoom("out")}
            className="fs-4 me-2 cursor-pointer"
            id="zoomOut"
          />
        </CustomTooltip>
        <CustomTooltip title={"Full height"} placement={"bottom"}>
          <img
            src={portrait}
            alt="portrait"
            height="35px"
            width="30px"
            onClick={() => handleZoom("portrait")}
            className="me-2 cursor-pointer"
          />
        </CustomTooltip>
        <CustomTooltip title={"Full width"} placement={"bottom"}>
          <img
            src={landscape}
            alt="landscape"
            height="30px"
            width="35px"
            onClick={() => handleZoom("landscape")}
            className="me-2 cursor-pointer"
          />
        </CustomTooltip>
        <CustomTooltip
          title={
            <div className="p-2">
              <div className="d-flex">
                <p className="text-dark">KDF: </p> {title}
              </div>
              <div className="d-flex">
                <p className="text-dark">PDF Source: </p>{" "}
                {selectedLanguage?.title}
              </div>
            </div>
          }
          placement={"bottom"}
          light={true}
        >
          <FontAwesomeIcon
            className="fs-4 cursor-pointer"
            icon={faCircleInfo}
          />
        </CustomTooltip>
      </div>
      <div className="ms-2 icons-editor">
        {/* {editEnabled != false && */}
        <LanguageDropdown
          arrayOfElements={arrayOfElements}
          name={LangName}
          handleSelect={handleSelect}
          selectedLanguage={selectedLanguage?.language}
        />
        {/* } */}
        {downloadStatus && (
          <a
            href={selectedPdf?.url}
            download={docFiles?.title ?? title}
            id="download-pdf"
          >
            <FontAwesomeIcon icon={faDownload} />
          </a>
        )}
      </div>
    </header>
  );
};

export default EditorHeader;
