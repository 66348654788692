import React from "react"
import { faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useSortBy, useTable } from "react-table";

const CustomTable = ({ data, columnData, showFilters = true }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns: columnData, data: data }, useSortBy);
    return (
        <table className="table-main mb-5" {...getTableProps()}>
            <thead>
                {
                    headerGroups?.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup?.headers?.map((column) => (
                                <th
                                    {...column?.getHeaderProps(column.getSortByToggleProps())}
                                    style={{
                                        cursor: 'pointer',
                                        padding: '10px',
                                    }}
                                >
                                    {column?.render('Header')}
                                    {
                                        showFilters && column?.id !== 'action' && (
                                            <span style={{ marginLeft: '10px' }}>
                                                {column?.isSorted ? (
                                                    column?.isSortedDesc ? (
                                                        <FontAwesomeIcon icon={faSortDown} /> // Icon for descending sort
                                                    ) : (
                                                        <FontAwesomeIcon icon={faSortUp} /> // Icon for ascending sort
                                                    )
                                                ) : (
                                                    <FontAwesomeIcon icon={faSort} /> // Default icon (unsorted state)
                                                )}
                                            </span>)
                                    }
                                </th>
                            ))}
                        </tr>
                    ))
                }
            </thead>
            <tbody {...getTableBodyProps()}>
                {data?.length === 0 ? (
                    <tr>
                        <td colSpan={columnData?.length} style={{ textAlign: 'center', padding: '10px' }}>
                            No data available in table
                        </td>
                    </tr>
                ) : rows?.map((row) => {
                    prepareRow(row);
                    return (
                        // style={{ backgroundColor: 'lightblue' }}
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                                <td
                                    {...cell.getCellProps()}
                                    style={{
                                        padding: '10px',
                                    }}
                                >
                                    {cell.render('Cell')}
                                </td>
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    )
}
export default CustomTable