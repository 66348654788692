import React from "react"

const SearchComponent = () => {
    return (
        <>
            <div className="Search-inp mt-2">
                <div>
                    <input placeholder="Search..." />
                </div>
            </div>
        </>
    )
}
export default SearchComponent