import React from "react";

const StatsGlance = () => {
    return (
        <div>
            <p>
                At a glance page
            </p>
        </div>
    )
}

export default StatsGlance;