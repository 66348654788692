import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// import Highcharts3d from 'highcharts/highcharts-3d';
import * as Highcharts3d from 'highcharts/highcharts-3d'
import { Form } from "react-bootstrap";
import { SubscriptionPeriodArr } from "../../../../hooks/utils";

// Enable 3D module
Highcharts3d(Highcharts)

const PieThreeDChart = () => {
  const options = {
    chart: {
      type: 'pie',
      options3d: {
        enabled: true,
        alpha: 45, // Angle of rotation
        beta: 0,   // Tilt angle
        depth: 100  // Depth of 3D effect
      },
    },
    title: {
      text: '3D Pie Chart'
    },
    series: [{
      name: 'Share',
      data: [
        ['A', 45],
        ['B', 25],
        ['C', 15],
        ['D', 10],
        ['E', 5]
      ]
    }]
  };

  return (
    <>
      <div className="w-25">
        <Form.Select
          name='type'
        //   value={subscription?.period}
        //   onChange={handleChange}
        >
          {SubscriptionPeriodArr?.map((option, idx) => (
            <option key={idx} value={option?.value}>{option?.name}</option>
          ))}
        </Form.Select>
      </div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  )
}
export default PieThreeDChart