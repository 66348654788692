import React, { useEffect, useState } from "react";
import { redirect, useLocation } from "react-router-dom";
import PageByPage from "./PageByPage";
import AtAGlance from "./AtAGlance";
import Views from "./Views";
import Viewers from "./Viewers";
import './stats.css';
import { Container, Row, Col } from "react-bootstrap";
import CustomTooltip from "../../Elements/Tooltip";
import LaserLinkModal from "../../Elements/LaserLink/LaserLinkModal";
const RenderComponent = () => {
    const { hash } = useLocation();
    if(hash.includes('/page-by-page')){
        return(<PageByPage />);
    }else if(hash.includes('/at-a-glance')){
        return(<AtAGlance />);
    }else if(hash.includes('/views')){
        return(<Views />);
    }else if(hash.includes('/viewers')){
        return(<Viewers />);
    }else {
        return(<>Nothing Found</>)
    }
}
const StatsView = ({time_zone_list}) => {
    const [selectedKDFLink, setSelectedKDFLink] = useState(null);
    const { tl, pdfFile } = window?.objInit;
    const { hash } = useLocation();
    return(
        <>
        <LaserLinkModal 
            laserItem={selectedKDFLink}
            time_zone_list={time_zone_list}
            updateSelectedKDFLink={setSelectedKDFLink}
            // triggerUpdateList={() => handleFiltersApplyEvent()}
        />
        <Container>
            <Row>
                <Col md={10}>
                    <Row>
                        <Col md={6}>
                            <p>
                                <CustomTooltip title={'LaserLINK Insights'}>
                                    <span className="custom-link-success cursor-pointer">LaserLINK: </span>
                                </CustomTooltip>
                                <CustomTooltip title={'LaserLINK Settings'}>
                                    <span className="custom-link-success cursor-pointer" onClick={() => setSelectedKDFLink(tl)}>
                                        <img  
                                            src={`${assetsBasePath}/${(tl?.doc?.language_flag?.locale?.includes('-') ? tl?.doc?.language_flag?.locale?.toLowerCase()?.split('-')[1] : tl?.doc?.language_flag?.locale)?? 'en'}.png`} 
                                            alt={tl?.doc?.language_flag?.name} 
                                            width='22px' 
                                            className="mr-1"
                                        />
                                        {tl?.name ?? '-'}
                                    </span>
                                </CustomTooltip>
                            </p>
                            <p>
                                <span>
                                    {tl.landing_page && pdfFile.num_pages > 1 && (
                                        <a style={{ color: '#787979' }} href="javascript:void(0)">
                                        LP={tl.landing_page}
                                        </a>
                                    )}
                                    {tl.disable_settings.disable_for && (
                                        <a style={{ color: '#787979' }} href="javascript:void(0)">
                                        DS={tl.disable_settings.disable_for}
                                        </a>
                                    )}
                                </span>
                                <span>Domain: </span>
                                {tl?.domain ?? '-'}
                            </p>
                           { (hash.includes('/page-by-page') || hash.includes('/at-a-glance')) ?
                                <p>
                                    <CustomTooltip title={'Display recipients who actively viewed this KDF for at least this amount of time per page.'}>
                                        <span>Engagement Threshold: </span>
                                    </CustomTooltip>
                                    <CustomTooltip title={'Display recipients who actively viewed this KDF for at least this amount of time per page.'}>
                                        <span>{tl?.page_viewed_sec} Seconds</span>
                                    </CustomTooltip>
                                </p>
                                :''
                            }
                        </Col>
                        <Col md={6}>
                        <p>
                            <CustomTooltip title={'Display all LaserLINK for this KDF'}>
                                <span className="custom-link-success cursor-pointer">KDF: </span>
                            </CustomTooltip>
                            <CustomTooltip title={'LaserLINK Settings'}>
                                <a href={`/kdfs/${tl?.doc?.id}`} target="_blank">{tl?.doc?.title ?? '-'}</a>
                            </CustomTooltip>
                        </p>
                        <p>
                            <span>Total Pages: </span>
                            {pdfFile?.num_pages ?? '-'}
                        </p>
                        </Col>
                    </Row>
                    <RenderComponent />
                </Col>
            </Row>
        </Container>
        </>
    );  
}
export default StatsView;