import React, { createContext } from "react";

export const UserInfoContext = createContext({
  userData: null,
  userProfileData: null,
  userCompanyData: null,
  showNotifications: {
    showToast: false,
    toastType: "success",
    toastMessage: ""
  },
  setUserData: () => { },
  setUserProfileData: () => { },
  setUserCompanyData: () => { },
  setShowNotifications: () => { }
});
export const EditorInfoContext = createContext({
  editEnabled: false,
  openAddElement: false,
  rectangle: {},
  activeRectangleIndex: null,
  geo: {},
  elements: {},
  editElement: {},
  openRemoveModal: false,
  pageNumber: 1,
  editLightBox: {},
  lightBoxList: [],
  imageElement: {},
  openLightBox: false,
  selectedPdf: '',
  showToast: {},
  tagValue: false,
  pageDimensions: {}
});
export default UserInfoContext;
