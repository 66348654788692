import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGreaterThan, faLessThan } from '@fortawesome/free-solid-svg-icons';

import styles from './Paginate.module.css';

const Paginate = ({ currentPage, totalPages, onPageChange, totalData, itemTitle }) => {
    const handlePageClickEvent = (page) => {             
        if(page.type == 'number') {
            if (page.value >= 1 && page.value <= totalPages) {                
                onPageChange(page.value);
            }
        }else if(page.type == 'text' && page.value.toLowerCase() == 'first') {
            onPageChange(1);
        }else if(page.type == 'text' && page.value.toLowerCase() == 'last') {
            onPageChange(totalPages);
        }
    };

    const generatePageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push({type:'number', value: i});
        }
        return [
            {type:'text', value:'First'},
            {type:'icon', value:'faLessThan'}, 
            ...pageNumbers, 
            {type:'icon', value:'faGreaterThan'},
            {type:'text', value:'Last'}
        ]
    };

    const pages = generatePageNumbers();
    const paginationClassContainer = (page) => {
        let result = [];
        result.push(styles.paginationLink);
        if(page.value === currentPage){
            result.push(styles.active)
        };
        if(page.type == 'icon'){
            if((page.value == 'faLessThan' && currentPage == 1) || (page.value == 'faGreaterThan' && currentPage == totalPages)){
                result.push(styles.disable) 
            }
        }else if(page.type == 'text'){
            if((page.value == 'First' && currentPage == 1) || (page.value == 'Last' && currentPage == totalPages)){
                result.push(styles.disable) 
            }
        }
        return result.join(' ');
    }
    return (
        <Container className={styles.paginationContainer + ' p-0 my-3'} >
            <Row className='mb-2'> 
                <Col md={6} className='p-0'>
                    <ul className='d-flex'>
                        {
                            pages?.map(
                                (page, index) => (
                                    <li
                                        key={index}
                                        className={paginationClassContainer(page)}
                                        onClick={() => handlePageClickEvent(page)}
                                    >
                                        {
                                            page.type == 'icon' ? 
                                                page.value == 'faLessThan' ? 
                                                    <FontAwesomeIcon icon={faLessThan} /> :
                                                    <FontAwesomeIcon icon={faGreaterThan} />
                                            :
                                                page.value
                                        }
                                    </li>
                                )
                            )
                        }
                    </ul>
                </Col>
                <Col md={6} className={`${styles.dataResponse} p-0`}>
                    <p className="text-end">{totalData} {itemTitle} match your current criteria:</p>
                </Col>
            </Row>
        </Container>
    );
};
export default Paginate;
