import React, { useEffect, useState } from "react";
import { Form, Badge, CloseButton } from "react-bootstrap";
import './CustomTagsInput.css'

const CustomTagsInput = ({ tags, handleAddTags = () => {}, handleRemoveTags = () => {}, triggerKeyDownEvent = () => {}, autoSubmit = false, placeholderText = null, limit = null }) => {
  const [tag, setTags] = useState(tags);
  const [inputValue, setInputValue] = useState("");
  const [inputError, setInputError] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(null);

  const addTag = () => {
    const trimmedValue = inputValue.trim();
    if (trimmedValue && !tags.includes(trimmedValue)) {
      setTags([...tag, trimmedValue]);
      handleAddTags(trimmedValue)
    }
    setInputValue("");
    setHighlightedIndex(null);
  };

  const handleKeyDown = (e) => {
    if ((e.key === "Enter" || e.key === "Tab") && autoSubmit == false) {
      e.preventDefault();
      if(limit != null){
        if(limit >= tag?.length){
          setInputError(true);
          return '';
        }
      }
      addTag();
    }

    if (e.key === "Backspace" && inputValue === "") {
      e.preventDefault();
      if (highlightedIndex === null) {
        setHighlightedIndex(tag.length - 1);
      } else if (highlightedIndex !== null) {
        const tagToRemove = tag[highlightedIndex];
        removeTag(tagToRemove.id ?? tagToRemove);
        setHighlightedIndex(null);
      }
    }
  };

  // will return the array of objects
  const removeTag = (tagToRemove) => {
    setTags(tag.filter((tag) => tag?.id !== tagToRemove))
    handleRemoveTags(tag.filter((tag) => tag?.id !== tagToRemove))
  };

  const handleBlur = () => {
    addTag();
  };

  useEffect(() => {
    setTags(tags);
    if (JSON.stringify(tags) != JSON.stringify(tag)) {
      setInputValue('');
    }
  }, [tags])

  return (
    <div className="tags-input-container">
      <div className="tags-display">
        {tag?.map((elem, index) => (
          <Badge
            key={index}
            className={`me-1 ${highlightedIndex === index ? "highlight" : ""}`}
          >
            {elem?.name ?? elem}
            <CloseButton
              variant="white"
              className="ms-2"
              onClick={() => removeTag(elem?.id ?? elem)}
            />
          </Badge>
        ))}
      </div>
      <Form.Control
        type="text"
        placeholder={placeholderText ?? 'Add a tag'}
        value={inputValue}
        onChange={(e) => (setInputValue(e.target.value), triggerKeyDownEvent(e.target.value))}
        onKeyDown={handleKeyDown}
        onBlur={() => autoSubmit == false && handleBlur()}
        className={`tags-input ${inputError == true ? 'tag-error' : ''}`}
        // style={{
        //   width: '300px'
        // }}
      />
    </div>
  );
};

export default CustomTagsInput;
